import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import ClipLoader from "react-spinners/ClipLoader";
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';

// redux
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import ModalDelete from '../../components/Modals/ModalDelete';
import Paginate from '../../components/Paginate/Paginate';
import { APIClient } from '../../helpers/api_helper';
import { setBreadcrumb } from "../../store/actions";
import ModalBrandManagementCreate from './Components/ModalBrandManagementCreate';

const BrandPage = () => {
    const [data, setData] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [identifier, setIdentifier] = useState('');

    const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
    const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
    
    const [isOpenModalBrandManagement, setIsOpenModalBrandManagement] = useState(false);
    
    const [action, setAction] = useState(1);
    const [meta, setMeta] = useState({
        current_page: 1,
    });

    const dispatch = useDispatch();
    const api = new APIClient();

    function params() {
        return `?page=${meta.current_page > 0 ? meta.current_page : 1}`;
    };

    const loadData = () => {
        setIsLoadingData(true);

        api
            .get(`/adm/brand/list${params()}`, {
                headers: {
                    Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                },
            })
            .then((response) => {
                setData(response.data);
                setMeta(response.meta);
                setIsLoadingData(false);
            })
            .catch((error) => {
                setIsLoadingData(false);
            })
        ;
    };

    const deleteAction = (identifier = '') => {
        setIsLoadingDeleteData(true);

        api
            .delete(`/adm/brand/${identifier}/remove`, {
                headers: {
                    Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                },
            })
            .then((response) => {
                toast.success("Marca removida com sucesso")
                setIsLoadingDeleteData(false);
                loadData();
                actionModalDelete();
            })
            .catch((error) => {
                toast.error("Problemas ao remover marca, tente novamente")
                setIsLoadingDeleteData(false);
            })
        ;
    }

    function actionModalDelete(identifier = '') {
        setIsOpenModalDelete(!isOpenModalDelete);
        setIdentifier(identifier);
    }

    function actionModalBrandManagement(identifier = '') {
        setIsOpenModalBrandManagement(!isOpenModalBrandManagement);
        setIdentifier(identifier);
    }

    useEffect(() => {
        const breadCrumbItems = {
            title: "Marcas",
        };

        dispatch(setBreadcrumb(breadCrumbItems));
        loadData();
    }, [dispatch, action]);

    return (
        <React.Fragment>
            <ModalBrandManagementCreate
                identifier={identifier}
                isOpen={isOpenModalBrandManagement}
                onCloseClick={() => actionModalBrandManagement()}
                centered={true}
                loadData={loadData}
            />
            <ModalDelete
                show={isOpenModalDelete}
                onDeleteClick={() => deleteAction(identifier)}
                onCloseClick={() => actionModalDelete()}
                centered={true}
            />
             <MetaTags>
                <title>Marcas | Painel Gomus</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xxl={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Listagem de marcas</h4>
                                </CardHeader>
                                <div className="text-center p-3">
                                    <button
                                        style={{ float: 'right' }}
                                        type="button"
                                        onClick={actionModalBrandManagement}
                                        className="btn btn-success"
                                        data-bs-toggle="modal"
                                        data-bs-target="#success-btn"
                                        id="btn-save-event"
                                    >
                                        <i className="bx bx-add me-1"></i>
                                        Nova marca
                                    </button>
                                </div>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Nome</th>
                                                    <th>Descrição</th>
                                                    <th>Data de cadastro</th>
                                                    <th>Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoadingData ? (
                                                    <td colSpan={4} className="text-center">
                                                        <ClipLoader size={13} loading={isLoadingData} />
                                                    </td>
                                                ): (
                                                    <>
                                                        {data.map((entity, key) => (
                                                            <tr key={key}>
                                                                <td>{entity.name ?? '-'}</td>
                                                                <td>{entity.description ?? '-'}</td>
                                                                <td>{entity.createdAt ?? '-'}</td>
                                                                <td>
                                                                    <div className="d-flex gap-3">
                                                                        <button
                                                                            onClick={() => actionModalBrandManagement(entity?.identifier)}
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title="Editar usuário"
                                                                            className="text-success"
                                                                            data-bs-original-title="Edit"
                                                                            aria-label="Edit"
                                                                            style={{ border: 'none', background: 'none'}}
                                                                        >
                                                                            <i className="mdi mdi-pencil font-size-18"></i>
                                                                        </button>
                                                                        <button
                                                                            onClick={() => actionModalDelete(entity?.identifier)}
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title="Excluir usuário"
                                                                            className="text-danger"
                                                                            data-bs-original-title="Delete"
                                                                            aria-label="Delete"
                                                                            style={{ border: 'none', background: 'none'}}
                                                                        >
                                                                            <i className="mdi mdi-delete font-size-18"></i>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                        {meta.total_pages > 1 && (
                                            <Paginate
                                                meta={meta}
                                                setMeta={setMeta}
                                                action={action}
                                                setAction={setAction}
                                                showDetails={true}
                                            />
                                        )}
                                        {data.length === 0 && <div className="text-center mt-4">Nenhum dado encontrado</div>}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default BrandPage;
