import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import ClipLoader from 'react-spinners/ClipLoader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from "reactstrap";
import { APIClient } from '../../../helpers/api_helper';
import CompanyData from './CompanyData';
import PasswordData from './PasswordData';
import PermissionData from './PermissionData';
import RegistrationData from './RegistrationData';

const ModalUserManagement = ({
    identifier = null,
    isOpen = false,
    onCloseClick,
    loadData,
    centered
}) => {
    const [entity, setEntity] = useState({});
    const [tabModal, setTabModal] = useState("Dados cadastrais");
    const [isLoadingEntity, setIsLoadingEntity] = useState(false);

    const api = new APIClient();

    const loadEntity = () => {
        setIsLoadingEntity(true);

        api
            .get(`/adm/user/${identifier}/show`, {
                headers: {
                    Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                },
            })
            .then((response) => {
                setEntity(response);
                setIsLoadingEntity(false);
            })
            .catch((error) => {
                setIsLoadingEntity(false);
            })
        ;
    };

    useEffect(() => {
        if (identifier?.length > 0) {
            loadEntity();
            return;
        }

        setEntity({});
    }, [identifier]);

    return (
        <>
            <ToastContainer />
            <Modal
                size="xl"
                isOpen={isOpen}
                className="new-customer"
                centered={centered}
            >
                <div className="modal-header">
                    <div className="d-flex justify-content-between w-50">
                        {!entity?.identifier ? (
                            <h5
                                className="modal-title text-primary"
                                style={{ cursor: "pointer" }}
                                onClick={() => setTabModal("Dados cadastrais")}
                            >
                                Dados cadastrais
                            </h5>
                        ) : (
                            <>
                                <h5
                                    className={`modal-title" ${tabModal !== "Dados cadastrais" ? "" : "text-primary"}`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setTabModal("Dados cadastrais")}
                                >
                                    Dados cadastrais
                                </h5>
                                <h5
                                    className={`modal-title" ${tabModal !== "Edição de senha" ? "" : "text-primary"}`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setTabModal("Edição de senha")}
                                >
                                    Edição de senha
                                </h5>
                                <h5
                                    className={`modal-title" ${tabModal !== "Permissões" ? "" : "text-primary"}`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setTabModal("Permissões")}
                                >
                                    Permissões
                                </h5>
                                <h5
                                    className={`modal-title" ${tabModal !== "Empresas" ? "" : "text-primary"}`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setTabModal("Empresas")}
                                >
                                    Empresas
                                </h5>
                            </>
                        )}
                    </div>
                    <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => {
                            onCloseClick();
                        }}
                    />
                </div>
                {isLoadingEntity ? (
                    <ClipLoader size={13} loading={isLoadingEntity} />
                ) : (
                    <>
                        <div className="modal-body">
                            {tabModal === "Dados cadastrais" && (
                                <RegistrationData
                                    entity={entity}
                                    loadData={loadData}
                                    onCloseClick={onCloseClick}
                                />
                            )}
                            {tabModal === "Edição de senha" && (
                                <PasswordData
                                    entity={entity}
                                    loadData={loadData}
                                    onCloseClick={onCloseClick}
                                />
                            )}
                            {tabModal === "Permissões" && (
                                <PermissionData
                                    entity={entity}
                                    loadData={loadData}
                                    onCloseClick={onCloseClick}
                                />
                            )}
                            {tabModal === "Empresas" && (
                                <CompanyData
                                    entity={entity}
                                    loadData={loadData}
                                    onCloseClick={onCloseClick}
                                />
                            )}
                        </div>
                    </>
                )}
            </Modal>
        </>
    )
}

ModalUserManagement.propTypes = {
    onCloseClick: PropTypes.func,
    isOpen: PropTypes.any,
    identifier: PropTypes.any,
    centered: PropTypes.any
};

export default ModalUserManagement;