import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";


//Import images
import { default as logoDarkSm, default as logoLight, default as logoLightSm } from "../../assets/images/logo.png";

//import language flag images

//import webApps images

//user(avatar) Image
import { Link } from 'react-router-dom';
import avatarImage1 from '../../assets/images/users/empty.png';
import BreadCrumb from '../../components/Common/BreadCrumb';

import { connect } from "react-redux";

//redux
import { useDispatch } from "react-redux";

// Redux Store
import { APIClient } from '../../helpers/api_helper';
import {
    changeSidebarType,
    showRightSidebarAction,
    toggleLeftmenu,
} from "../../store/actions";
import ModalProfile from './Components/ModalProfile';

const Header = ({ breadCrumbItems, showRightSidebar }) => {
    const [isOpenModalProfile, setIsOpenModalProfile] = useState(false);

    const api = new APIClient();

    const dispatch = useDispatch();
    //Seacrh dropdown
    const [search, setSearch] = useState(false);
    const toggleSearch = () => {
        setSearch(!search);
    };

    //Language Dropdown
    const [languages, setLanguage] = useState(false);
    const toggleLanguage = () => {
        setLanguage(!languages);
    };

    //Webapps Dropdown
    const [webapps, setWebapps] = useState(false);
    const toggleWebapps = () => {
        setWebapps(!webapps);
    };

    //Notification Dropdown
    const [notification, setNotification] = useState(false);
    const toggleNotification = () => {
        setNotification(!notification);
    };

    //Profile Dropdown
    const [profile, setProfile] = useState(false);
    const toggleProfile = () => {
        setProfile(!profile);
    };

    //scroll navbar
    const [navClass, setnavClass] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });

    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setnavClass("sticky");
        } else {
            setnavClass("");
        }
    }

    function tToggle() {
        var body = document.body;
        if (window.screen.width <= 998) {
            body.classList.toggle("sidebar-enable");
        } else {
            body.classList.toggle("sidebar-enable");
            body.setAttribute("data-sidebar-size","lg");
        }
    }

    const logoutAction = () => {
        api
            .delete('/auth/logout', {
                headers: {
                    Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                },
            })
            .then(() => {
                setProfile({});
                window.localStorage.clear();
                window.location.href = '/login';
            })
            .catch(() => {
                //toast.error
                setProfile({});
                window.localStorage.clear();
                window.location.href = '/login';
            })
        ;
    };

    const actionModalProfile = () => {
        setIsOpenModalProfile(!isOpenModalProfile);
    }

    const loadData = () => {
        api
            .get('/profile/show', {
                headers: {
                    Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                },
            })
            .then((profile) => {
                localStorage.setItem('profile-role', profile.role);
                localStorage.setItem('profile-identifier', profile.identifier);
                localStorage.setItem('profile-name', profile.name);
                localStorage.setItem('profile-email', profile.username);                        
                return;
            })
        ;
    }

    return (
        <React.Fragment>
            <ModalProfile
                isOpen={isOpenModalProfile}
                onCloseClick={() => actionModalProfile()}
                loadData={loadData}
                centered={true}
            />
            <header id="page-topbar" className={"isvertical-topbar " + navClass}>
                <div className="navbar-header">
                    <div className="d-flex">

                        <div className="navbar-brand-box">
                            <Link to="/" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logoDarkSm} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoDarkSm} alt="" height="22" />
                                </span>
                            </Link>

                            <Link to="/" className="logo logo-light">
                                <span className="logo-lg">
                                    <img src={logoLight} alt="" height="22" />
                                </span>
                                <span className="logo-sm">
                                    <img src={logoLightSm} alt="" height="22" />
                                </span>
                            </Link>
                        </div>

                        <button type="button" className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn topnav-hamburger" onClick={() => tToggle() }>
                            <div className="hamburger-icon open">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>

                        <BreadCrumb breadCrumbItems={breadCrumbItems} />
                    </div>
                    <div className="d-flex">
                        <Dropdown className="d-inline-block" isOpen={profile} toggle={toggleProfile}>
                            <DropdownToggle tag="a" type="button"
                                className="btn header-item user text-start d-flex align-items-center"
                                id="page-header-user-dropdown"
                                to="#"
                            >
                                <img className="rounded-circle header-profile-user" src={avatarImage1}
                                    alt="Header Avatar" />
                            </DropdownToggle>
                             <DropdownMenu className="dropdown-menu-end pt-0">
                                <div className="p-3 border-bottom">
                                    <h6 className="mb-0">{window.localStorage.getItem("profile-name")}</h6>
                                    <p className="mb-0 font-size-11 text-muted">{window.localStorage.getItem("profile-email")}</p>
                                </div>
                                <DropdownItem onClick={() => actionModalProfile()}>
                                    <i className="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i>
                                    <span className="align-middle">Perfil</span>
                                </DropdownItem>
                                <div className="dropdown-divider"/>
                                <DropdownItem onClick={() => logoutAction()}>
                                    <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"/>
                                    <span className="align-middle">Sair</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

Header.propTypes = {
    changeSidebarType: PropTypes.func,
    leftMenu: PropTypes.any,
    leftSideBarType: PropTypes.any,
    showRightSidebar: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    t: PropTypes.any,
    toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
    const {
        layoutType,
        showRightSidebar,
        leftMenu,
        leftSideBarType,
    } = state.Layout;
    return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
})((Header));
