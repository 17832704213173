import React from 'react';

const BreadCrumb = ({breadCrumbItems}) => {
    return (
        <React.Fragment>
            <div className="d-none d-sm-block ms-3 align-self-center">
                <h4 className="page-title">{breadCrumbItems.title}</h4>
            </div>
        </React.Fragment>
    );
};

export default BreadCrumb;