const layoutTypes = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

const layoutWidthTypes = {
  FLUID: "fluid",
  BOXED: "boxed",
};

const layoutModeTypes = {
  LIGHTMODE: "light",
  DARKMODE: "dark",
};

const topBarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
};

const leftSidebarTypes = {
  DEFAULT: "default",
  COMPACT: "compact",
  ICON: "icon",
};

const leftSideBarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
  COLORED : "brand",
};

export {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
  layoutModeTypes
};
