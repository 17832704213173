import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { OverviewChart} from "./CryptoCharts";

const MarketOverview = () => {
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="d-flex flex-wrap align-items-center">
                        <h5 className="card-title me-2">Market Overview</h5>
                        <div className="ms-auto">
                            <div>
                                <button type="button" className="btn btn-primary bg-gradient btn-sm">
                                    ALL
                                </button>
                                <button type="button" className="btn btn-light bg-gradient btn-sm">
                                    1M
                                </button>
                                <button type="button" className="btn btn-light bg-gradient btn-sm">
                                    6M
                                </button>
                                <button type="button" className="btn btn-light bg-gradient btn-sm">
                                    1Y
                                </button>
                            </div>
                        </div>
                    </div>

                    <div id="basic_candlestick" dir="ltr">
                        <OverviewChart />
                    </div>

                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default MarketOverview;