import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";

import {
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    Row,
    TabContent,
    TabPane,
    Tooltip,
    UncontrolledDropdown,
} from "reactstrap";


//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import { Link } from "react-router-dom";

const InvoiceList = () => {
    const dispatch = useDispatch();

    /*
      set breadcrumbs
      */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Invoice List",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [modal_invoice, setmodal_invoice] = useState(false);

    function tog_invoice() {
        setmodal_invoice(!modal_invoice);
    }

    // Wizard Modal

    const [tuser, settuser] = useState(false);
    const [tfile, settfile] = useState(false);
    const [tedit, settedit] = useState(false);

    const [activeTab, setactiveTab] = useState(1);

    const [passedSteps, setPassedSteps] = useState([1]);

    function toggleTab(tab) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab];
            if (tab >= 1 && tab <= 3) {
                setactiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>Invoice List | Painel Gomus</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="position-relative">
                                        <div className="modal-button mt-2">
                                            <div className="row align-items-start">
                                                <div className="col-sm-auto">
                                                    <div className="d-flex gap-1">
                                                        <div className="input-group">
                                                            <Flatpickr
                                                                className="form-control"
                                                                placeholder="dd M, yyyy"
                                                                options={{
                                                                    mode: "range",
                                                                    dateFormat: "Y-m-d",
                                                                }}
                                                                id="datepicker-range"
                                                            />

                                                            <span className="input-group-text">
                                                                <i className="bx bx-calendar-event"></i>
                                                            </span>
                                                        </div>
                                                        <UncontrolledDropdown className="dropdown">
                                                            <DropdownToggle tag="a" className="btn btn-link text-body shadow-none dropdown-toggle">
                                                                <i className="bx bx-dots-horizontal-rounded"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-end">
                                                                <DropdownItem>Action</DropdownItem>
                                                                <DropdownItem>Another action</DropdownItem>
                                                                <DropdownItem>Something else here</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="mt-3 mt-md-0 mb-3">
                                                        <button
                                                            type="button"
                                                            className="btn btn-success"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#addInvoiceModal"
                                                            onClick={() => {
                                                                tog_invoice();
                                                            }}
                                                        >
                                                            <i className="mdi mdi-plus me-1"></i> Add Invoice
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="table-invoices-list">
                                        <div
                                            role="complementary"
                                            className="gridjs gridjs-container"
                                            style={{ width: "100%" }}
                                        >
                                            <div className="gridjs-head">
                                                <div className="gridjs-search">
                                                    <input
                                                        type="search"
                                                        placeholder="Type a keyword..."
                                                        aria-label="Type a keyword..."
                                                        className="gridjs-input gridjs-search-input"
                                                        style={{
                                                            backgroundColor: "#fff",
                                                            fontSize: "13px",
                                                            borderRadius: "30px",
                                                            padding: "10px 13px",
                                                            width: "250px",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="gridjs-wrapper"
                                                style={{ height: "auto" }}
                                            >
                                                <table
                                                    role="grid"
                                                    className="gridjs-table"
                                                    style={{ height: "auto" }}
                                                >
                                                    <thead className="gridjs-thead">
                                                        <tr className="gridjs-tr">
                                                            <th
                                                                data-column-id="#"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "78px", width: "102px" }}
                                                            >
                                                                <div className="gridjs-th-content">#</div>
                                                            </th>
                                                            <th
                                                                data-column-id="orderId"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "144px", width: "188px" }}
                                                            >
                                                                <div className="gridjs-th-content">Order ID</div>
                                                            </th>
                                                            <th
                                                                data-column-id="date"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "206px", width: "269px" }}
                                                            >
                                                                <div className="gridjs-th-content">Date</div>
                                                            </th>
                                                            <th
                                                                data-column-id="billingName"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "98px", width: "127px" }}
                                                            >
                                                                <div className="gridjs-th-content">Billing Name</div>
                                                            </th>
                                                            <th
                                                                data-column-id="amount"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "91px", width: "119px" }}
                                                            >
                                                                <div className="gridjs-th-content">Amount</div>
                                                            </th>
                                                            <th
                                                                data-column-id="status"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "148px", width: "193px" }}
                                                            >
                                                                <div className="gridjs-th-content">
                                                                    Status
                                                                </div>
                                                            </th>
                                                            <th
                                                                data-column-id="action"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "81px", width: "106px" }}
                                                            >
                                                                <div className="gridjs-th-content">Action</div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="gridjs-tbody">
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td"><span><div className="form-check font-size-16"><input className="form-check-input" type="checkbox" id="orderidcheck01" /><label className="form-check-label" htmlFor="orderidcheck01" ></label></div></span></td>
                                                            <td data-column-id="orderId" className="gridjs-td"><span><span className="fw-semibold">#DS0215</span></span></td>
                                                            <td data-column-id="date" className="gridjs-td">07 Oct, 2021</td>
                                                            <td data-column-id="billingName" className="gridjs-td">Connie Franco</td>
                                                            <td data-column-id="amount" className="gridjs-td">$26.30</td>
                                                            <td data-column-id="status" className="gridjs-td">
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-success font-size-12">Paid</span>
                                                                </span></td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-muted dropdown-toggle font-size-20"
                                                                        >
                                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Action</DropdownItem>
                                                                            <DropdownItem>Remove</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td"><span><div className="form-check font-size-16"><input className="form-check-input" type="checkbox" id="orderidcheck01" /><label className="form-check-label" htmlFor="orderidcheck01"></label></div></span></td>
                                                            <td data-column-id="orderId" className="gridjs-td"><span><span className="fw-semibold">#DS0214</span></span></td>
                                                            <td data-column-id="date" className="gridjs-td">05 Oct, 2021</td>
                                                            <td data-column-id="billingName" className="gridjs-td">Paul Reynolds</td>
                                                            <td data-column-id="amount" className="gridjs-td">$24.20</td>
                                                            <td data-column-id="status" className="gridjs-td">
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-success font-size-12">Paid</span>
                                                                </span></td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-muted dropdown-toggle font-size-20"
                                                                        >
                                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Action</DropdownItem>
                                                                            <DropdownItem>Remove</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td"><span><div className="form-check font-size-16"><input className="form-check-input" type="checkbox" id="orderidcheck01" /><label className="form-check-label" htmlFor="orderidcheck01"></label></div></span></td>
                                                            <td data-column-id="orderId" className="gridjs-td"><span><span className="fw-semibold">#DS0214</span></span></td>
                                                            <td data-column-id="date" className="gridjs-td">06 Oct, 2021</td>
                                                            <td data-column-id="billingName" className="gridjs-td">Ronald Patterson</td>
                                                            <td data-column-id="amount" className="gridjs-td">$65.32</td>
                                                            <td data-column-id="status" className="gridjs-td">
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-warning font-size-12">Pending</span>
                                                                </span></td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-muted dropdown-toggle font-size-20"
                                                                        >
                                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Action</DropdownItem>
                                                                            <DropdownItem>Remove</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td"><span><div className="form-check font-size-16"><input className="form-check-input" type="checkbox" id="orderidcheck01" /><label className="form-check-label" htmlFor="orderidcheck01"></label></div></span></td>
                                                            <td data-column-id="orderId" className="gridjs-td"><span><span className="fw-semibold">#DS0212</span></span></td>
                                                            <td data-column-id="date" className="gridjs-td">07 Oct, 2021</td>
                                                            <td data-column-id="billingName" className="gridjs-td">Adella Perez</td>
                                                            <td data-column-id="amount" className="gridjs-td">$53.32</td>
                                                            <td data-column-id="status" className="gridjs-td">
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-success font-size-12">Paid</span>
                                                                </span></td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-muted dropdown-toggle font-size-20"
                                                                        >
                                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Action</DropdownItem>
                                                                            <DropdownItem>Remove</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td"><span><div className="form-check font-size-16"><input className="form-check-input" type="checkbox" id="orderidcheck01" /><label className="form-check-label" htmlFor="orderidcheck01"></label></div></span></td>
                                                            <td data-column-id="orderId" className="gridjs-td"><span><span className="fw-semibold">#DS0211</span></span></td>
                                                            <td data-column-id="date" className="gridjs-td">07 Oct, 2021</td>
                                                            <td data-column-id="billingName" className="gridjs-td">Theresa Mayers</td>
                                                            <td data-column-id="amount" className="gridjs-td">$13.21</td>
                                                            <td data-column-id="status" className="gridjs-td">
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-success font-size-12">Paid</span>
                                                                </span></td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-muted dropdown-toggle font-size-20"
                                                                        >
                                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Action</DropdownItem>
                                                                            <DropdownItem>Remove</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td"><span><div className="form-check font-size-16"><input className="form-check-input" type="checkbox" id="orderidcheck01" /><label className="form-check-label" htmlFor="orderidcheck01"></label></div></span></td>
                                                            <td data-column-id="orderId" className="gridjs-td"><span><span className="fw-semibold">#DS0210</span></span></td>
                                                            <td data-column-id="date" className="gridjs-td">06 Oct, 2021</td>
                                                            <td data-column-id="billingName" className="gridjs-td">Michael Wallace</td>
                                                            <td data-column-id="amount" className="gridjs-td">$23.41</td>
                                                            <td data-column-id="status" className="gridjs-td">
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-warning font-size-12">Pending</span>
                                                                </span></td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-muted dropdown-toggle font-size-20"
                                                                        >
                                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Action</DropdownItem>
                                                                            <DropdownItem>Remove</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td"><span><div className="form-check font-size-16"><input className="form-check-input" type="checkbox" id="orderidcheck01" /><label className="form-check-label" htmlFor="orderidcheck01"></label></div></span></td>
                                                            <td data-column-id="orderId" className="gridjs-td"><span><span className="fw-semibold">#DS0209</span></span></td>
                                                            <td data-column-id="date" className="gridjs-td">06 Oct, 2021</td>
                                                            <td data-column-id="billingName" className="gridjs-td">Oliver Gonzales</td>
                                                            <td data-column-id="amount" className="gridjs-td">$41.23</td>
                                                            <td data-column-id="status" className="gridjs-td">
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-warning font-size-12">Pending</span>
                                                                </span></td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-muted dropdown-toggle font-size-20"
                                                                        >
                                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Action</DropdownItem>
                                                                            <DropdownItem>Remove</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td"><span><div className="form-check font-size-16"><input className="form-check-input" type="checkbox" id="orderidcheck01" /><label className="form-check-label" htmlFor="orderidcheck01"></label></div></span></td>
                                                            <td data-column-id="orderId" className="gridjs-td"><span><span className="fw-semibold">#DS0208</span></span></td>
                                                            <td data-column-id="date" className="gridjs-td">08 Oct, 2021</td>
                                                            <td data-column-id="billingName" className="gridjs-td">David Burke</td>
                                                            <td data-column-id="amount" className="gridjs-td">$32.25</td>
                                                            <td data-column-id="status" className="gridjs-td">
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-success font-size-12">Paid</span>
                                                                </span></td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-muted dropdown-toggle font-size-20"
                                                                        >
                                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Action</DropdownItem>
                                                                            <DropdownItem>Remove</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td"><span><div className="form-check font-size-16"><input className="form-check-input" type="checkbox" id="orderidcheck01" /><label className="form-check-label" htmlFor="orderidcheck01"></label></div></span></td>
                                                            <td data-column-id="orderId" className="gridjs-td"><span><span className="fw-semibold">#DS0207</span></span></td>
                                                            <td data-column-id="date" className="gridjs-td">09 Oct, 2021</td>
                                                            <td data-column-id="billingName" className="gridjs-td">Willie Verner</td>
                                                            <td data-column-id="amount" className="gridjs-td">$53.21</td>
                                                            <td data-column-id="status" className="gridjs-td">
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-warning font-size-12">Pending</span>
                                                                </span></td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-muted dropdown-toggle font-size-20"
                                                                        >
                                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Action</DropdownItem>
                                                                            <DropdownItem>Remove</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td"><span><div className="form-check font-size-16"><input className="form-check-input" type="checkbox" id="orderidcheck01" /><label className="form-check-label" htmlFor="orderidcheck01"></label></div></span></td>
                                                            <td data-column-id="orderId" className="gridjs-td"><span><span className="fw-semibold">#DS0206</span></span></td>
                                                            <td data-column-id="date" className="gridjs-td">07 Oct, 2021</td>
                                                            <td data-column-id="billingName" className="gridjs-td">Felix Perry</td>
                                                            <td data-column-id="amount" className="gridjs-td">$63.21</td>
                                                            <td data-column-id="status" className="gridjs-td">
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-success font-size-12">Paid</span>
                                                                </span></td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-muted dropdown-toggle font-size-20"
                                                                        >
                                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Action</DropdownItem>
                                                                            <DropdownItem>Remove</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="gridjs-footer mt-2">
                                                <div className="gridjs-pagination">
                                                    <div
                                                        role="status"
                                                        aria-live="polite"
                                                        className="gridjs-summary"
                                                        title="Page 1 of 3"
                                                    >
                                                        Showing <b>1</b> to <b>10</b> of <b>18</b> results
                                                    </div>
                                                    <div
                                                        className="gridjs-pages text-muted"
                                                        style={{ textAlign: "right" }}
                                                    >
                                                        <button
                                                            tabIndex="0"

                                                            disabled=""
                                                            title="Previous"
                                                            aria-label="Previous"
                                                            className=""
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            tabIndex="0"

                                                            className="gridjs-currentPage"
                                                            title="Page 1"
                                                            aria-label="Page 1"
                                                        >
                                                            1
                                                        </button>
                                                        <button
                                                            tabIndex="0"

                                                            className=""
                                                            title="Page 2"
                                                            aria-label="Page 2"
                                                        >
                                                            2
                                                        </button>

                                                        <button
                                                            tabIndex="0"

                                                            title="Next"
                                                            aria-label="Next"
                                                            className=""
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="gridjs-temp" className="gridjs-temp"></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Container>
            </div>

            {/* Invoice Modal */}
            <Modal
                size="xl"
                isOpen={modal_invoice}
                toggle={() => {
                    tog_invoice();
                }}
                className="addInvoiceModal"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="addInvoiceModalLabel">
                        {" "}
                        Add Invoice{" "}
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_invoice(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body p-4">
                    <form>
                        <div>
                            <ul className="wizard-nav mb-5">
                                <li className="wizard-list-item">
                                    <div
                                        className={
                                            activeTab === 1 ? "list-item active" : "list-item"
                                        }
                                    >
                                        <div
                                            className="step-icon"
                                            id="customer"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Customer Info"
                                        >
                                            <i className="bx bx-user-circle"></i>
                                        </div>
                                    </div>
                                </li>
                                <li className="wizard-list-item">
                                    <div
                                        className={
                                            activeTab === 2 ? "list-item active" : "list-item"
                                        }
                                    >
                                        <div
                                            className="step-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            id="invoice"
                                            title="Invoice Details"
                                        >
                                            <i className="bx bx-file"></i>
                                        </div>
                                    </div>
                                </li>

                                <li className="wizard-list-item">
                                    <div
                                        className={
                                            activeTab === 3 ? "list-item active" : "list-item"
                                        }
                                    >
                                        <div
                                            className="step-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            id="order"
                                            title="Order Summery"
                                        >
                                            <i className="bx bx-edit"></i>
                                        </div>
                                    </div>
                                </li>
                                <Tooltip
                                    placement="top"
                                    isOpen={tuser}
                                    target="customer"
                                    toggle={() => {
                                        settuser(!tuser);
                                    }}
                                >
                                    Customer Info
                                </Tooltip>

                                <Tooltip
                                    placement="top"
                                    isOpen={tfile}
                                    target="invoice"
                                    toggle={() => {
                                        settfile(!tfile);
                                    }}
                                >
                                    Invoice Details
                                </Tooltip>
                                <Tooltip
                                    placement="top"
                                    isOpen={tedit}
                                    target="order"
                                    toggle={() => {
                                        settedit(!tedit);
                                    }}
                                >
                                    Order Summery
                                </Tooltip>
                            </ul>

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId={1} className="wizard-tab">
                                    <div className="text-center mb-4">
                                        <h5>Customer Information</h5>
                                        <p className="card-title-desc">
                                            Fill all information below
                                        </p>
                                    </div>
                                    <div>
                                        <Row>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="customerinfo-name-input"
                                                        className="form-label"
                                                    >
                                                        Customer Name :
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Name"
                                                        id="customerinfo-name-input"
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="customerinfo-email-input"
                                                        className="form-label"
                                                    >
                                                        Email :
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        id="customerinfo-email-input"
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="customerinfo-phone-input"
                                                        className="form-label"
                                                    >
                                                        Phone :
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Phone"
                                                        id="customerinfo-phone-input"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="mb-3">
                                            <label
                                                htmlFor="customerinfo-address-input"
                                                className="form-label"
                                            >
                                                Address :
                                            </label>
                                            <textarea
                                                className="form-control"
                                                placeholder="Enter Address"
                                                id="customerinfo-address-input"
                                                rows="2"
                                            ></textarea>
                                        </div>
                                    </div>
                                </TabPane>

                                <TabPane tabId={2} className="wizard-tab">
                                    <div className="text-center mb-4">
                                        <h5>Invoice Details</h5>
                                        <p className="card-title-desc">Fill Invoice Details.</p>
                                    </div>
                                    <div>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="invoicenumberinput"
                                                        className="form-label"
                                                    >
                                                        Invoice #
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Invoice"
                                                        id="invoicenumberinput"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Invoice Date</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Date"
                                                        id="datepicker-invoice"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="invoicedescriptioninput"
                                                        className="form-label"
                                                    >
                                                        Description (Optional)
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Description"
                                                        id="invoicedescriptioninput"
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <label className="form-label">Payment method :</label>
                                                    <select className="form-select">
                                                        <option selected>Select Payment method</option>
                                                        <option value="CR">Credit / Debit Card</option>
                                                        <option value="PY">Paypal</option>
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </TabPane>

                                <TabPane tabId={3} className="wizard-tab">
                                    <div className="text-center mb-4">
                                        <h5>Order Summery</h5>
                                        <p className="card-title-desc">
                                            Fill Order Summery Details.
                                        </p>
                                    </div>
                                    <div>
                                        <div className="table-responsive">
                                            <table className="table table-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Item name</th>
                                                        <th scope="col">Description</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col">Quantity</th>
                                                        <th scope="col">Total</th>
                                                        <th scope="col" className="text-center">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">1</th>
                                                        <td>
                                                            <div>
                                                                <input
                                                                    className="form-control"
                                                                    placeholder="Enter Name"
                                                                    type="text"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <textarea
                                                                    className="form-control"
                                                                    placeholder="Enter Description"
                                                                    rows="2"
                                                                ></textarea>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <input
                                                                    className="form-control"
                                                                    placeholder="Enter Price"
                                                                    type="text"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <input
                                                                    className="form-control"
                                                                    placeholder="Enter Quantity"
                                                                    type="text"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value="0.00"
                                                                    readonly
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="text-center">
                                                                <Link
                                                                    to="#"
                                                                    className="text-danger p-2 d-inline-block"
                                                                    data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    title="Delete"
                                                                >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th scope="row">2</th>
                                                        <td>
                                                            <div>
                                                                <input
                                                                    className="form-control"
                                                                    placeholder="Enter Name"
                                                                    type="text"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <textarea
                                                                    className="form-control"
                                                                    placeholder="Enter Description"
                                                                    rows="2"
                                                                ></textarea>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <input
                                                                    className="form-control"
                                                                    placeholder="Enter Price"
                                                                    type="text"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <input
                                                                    className="form-control"
                                                                    placeholder="Enter Quantity"
                                                                    type="text"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value="0.00"
                                                                    readonly
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="text-center">
                                                                <Link
                                                                    to="#"
                                                                    className="text-danger p-2 d-inline-block"
                                                                    data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    title="Delete"
                                                                >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <Row className="justify-content-end">
                                            <Col xl={3} md={4}>
                                                <div>
                                                    <div>
                                                        <h5 className="font-size-14 py-2">
                                                            Sub Total :{" "}
                                                            <span className="float-end fw-normal text-body">
                                                                0.00
                                                            </span>
                                                        </h5>
                                                    </div>
                                                    <div>
                                                        <h5 className="font-size-14 py-2">
                                                            Discount :{" "}
                                                            <span className="float-end fw-normal text-body">
                                                                {" "}
                                                                - 0.00
                                                            </span>
                                                        </h5>
                                                    </div>
                                                    <div>
                                                        <h5 className="font-size-14 py-2">
                                                            Shipping Charge :{" "}
                                                            <span className="float-end fw-normal text-body">
                                                                {" "}
                                                                0.00
                                                            </span>
                                                        </h5>
                                                    </div>
                                                    <div>
                                                        <h5 className="font-size-14 py-2">
                                                            Tax :{" "}
                                                            <span className="float-end fw-normal text-body">
                                                                {" "}
                                                                0.00
                                                            </span>
                                                        </h5>
                                                    </div>
                                                    <div>
                                                        <h5 className="font-size-14 py-2">
                                                            Total : <span className="float-end"> 0.00</span>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </TabPane>
                            </TabContent>

                            <div className="d-flex align-items-start gap-3 mt-4">
                                <button
                                    type="button"
                                    id="prevBtn"
                                    onclick="nextPrev(-1)"
                                    className={
                                        activeTab === 1
                                            ? "btn btn-primary w-sm d-none"
                                            : "btn btn-primary w-sm"
                                    }
                                    onClick={() => {
                                        toggleTab(activeTab - 1);
                                    }}
                                >
                                    Previous
                                </button>
                                <button
                                    type="button"
                                    id="nextBtn"
                                    onclick="nextPrev(1)"
                                    className="btn btn-primary w-sm ms-auto"
                                    onClick={() => {
                                        toggleTab(activeTab + 1);
                                    }}
                                >
                                    {activeTab === 3 ? "Add" : "Next"}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default InvoiceList;
