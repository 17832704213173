import { APIClient } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();
// get Events
export const getEvents = () => api.get(url.GET_EVENTS);

// add Events
export const addNewEvent = event => api.create(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = event => api.update(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = event =>
api.delete(url.DELETE_EVENT, { headers: { event } });

// get Categories
export const getCategories = () => api.get(url.GET_CATEGORIES);

// get chats
export const getChats = () => api.get(url.GET_CHATS);

// get groups
export const getGroups = () => api.get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => api.get(url.GET_CONTACTS);

// get messages
export const getMessages = (roomId = "") =>
  api.get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// post messages
export const addMessage = message => api.create(url.ADD_MESSAGE, message);

