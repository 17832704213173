import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-eva-icons';
import { connect } from 'react-redux';

import {
    Input,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row,
    Col,
} from "reactstrap";

import SimpleBar from 'simplebar-react';

//import images 
import logoDark from '../../assets/images/logo.png';
import logoLight from '../../assets/images/logo.png';
import logoLightSm from '../../assets/images/logo.png';

//import language flag images
import usFlag from '../../assets/images/flags/us.jpg';
import spainFlag from '../../assets/images/flags/spain.jpg';
import germanyFlag from '../../assets/images/flags/germany.jpg';
import italyFlag from '../../assets/images/flags/italy.jpg';
import russiaFlag from '../../assets/images/flags/russia.jpg';

//import webApps images
import gitHub from '../../assets/images/brands/github.png';
import bitbucket from '../../assets/images/brands/bitbucket.png';
import dribbble from '../../assets/images/brands/dribbble.png';
import dropbox from '../../assets/images/brands/dropbox.png';
import mail_chimp from '../../assets/images/brands/mail_chimp.png';
import slack from '../../assets/images/brands/slack.png';

//user(avatar) Image
import avatarImage1 from '../../assets/images/users/avatar-1.jpg';
import avatarImage3 from '../../assets/images/users/avatar-3.jpg';
import avatarImage6 from '../../assets/images/users/avatar-6.jpg';

import { Link } from 'react-router-dom';

//redux
import { useDispatch } from "react-redux";

// Redux Store
import {
    showRightSidebarAction,
    toggleLeftmenu,
} from "../../store/actions";
import BreadCrumb from '../../components/Common/BreadCrumb';

const Header = ({ breadCrumbItems, showRightSidebar, leftMenu }) => {
    const dispatch = useDispatch();

    //Seacrh dropdown
    const [search, setSearch] = useState(false);
    const toggleSearch = () => {
        setSearch(!search);
    };

    //Language Dropdown
    const [languages, setLanguage] = useState(false);
    const toggleLanguage = () => {
        setLanguage(!languages);
    };

    //Webapps Dropdown
    const [webapps, setWebapps] = useState(false);
    const toggleWebapps = () => {
        setWebapps(!webapps);
    };

    //Notification Dropdown
    const [notification, setNotification] = useState(false);
    const toggleNotification = () => {
        setNotification(!notification);
    };

    //Profile Dropdown
    const [profile, setProfile] = useState(false);
    const toggleProfile = () => {
        setProfile(!profile);
    };

    return (
        <React.Fragment>
            <div className="navbar-header">
                <div className="d-flex">
                    <div className="navbar-brand-box">
                        <Link to="/" className="logo logo-dark">
                            <span className="logo-sm">
                                <img src={logoDark} alt="" height="22" />
                            </span>
                            <span className="logo-lg">
                                <img src={logoDark} alt="" height="22" />
                            </span>
                        </Link>

                        <Link to="/" className="logo logo-light">
                            <span className="logo-lg">
                                <img src={logoLight} alt="" height="22" />
                            </span>
                            <span className="logo-sm">
                                <img src={logoLightSm} alt="" height="22" />
                            </span>
                        </Link>
                    </div>

                    <button type="button" className="btn btn-sm px-3 font-size-16 d-lg-none header-item" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content"
                        onClick={() => {
                            dispatch(toggleLeftmenu(!leftMenu));
                        }} >
                        <i className="fa fa-fw fa-bars"></i>
                    </button>

                    <BreadCrumb breadCrumbItems={breadCrumbItems} />

                </div>

                <div className="d-flex">
                    <div className="dropdown d-inline-block">
                        <button
                            onClick={() => {
                                dispatch(showRightSidebarAction(!showRightSidebar));
                            }}
                            type="button"
                            className="btn header-item noti-icon right-bar-toggle"
                            id="right-bar-toggle">
                            <Icon name="settings-outline" fill="#555b6d" size="large" />
                        </button>
                    </div>
                    <Dropdown className="d-inline-block" isOpen={profile} toggle={toggleProfile}>
                        <DropdownToggle tag="a" type="button"
                            className="btn header-item user text-start d-flex align-items-center"
                            id="page-header-user-dropdown"
                            to="#"
                        >
                            <img className="rounded-circle header-profile-user" src={avatarImage1}
                                alt="Header Avatar" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end pt-0">
                            <div className="p-3 border-bottom">
                                <h6 className="mb-0">Pedro Henrique Candido</h6>
                                <p className="mb-0 font-size-11 text-muted">ph@ubicode.com.br</p>
                            </div>
                            <DropdownItem href="/dashboard"><i className="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Perfil</span></DropdownItem>
                            <DropdownItem href="/dashboard"><i className="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Senha</span></DropdownItem>
                            <div className="dropdown-divider"></div>
                            <DropdownItem href="/login"><i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Sair</span></DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
        </React.Fragment>
    );
};

Header.propTypes = {
    leftMenu: PropTypes.any,
    showRightSidebar: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
    const { layoutType, showRightSidebar, leftMenu } = state.Layout;
    return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
})((Header));
