import { Collapse, Col, Container, Row } from 'reactstrap';

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";
import { connect } from 'react-redux';

const NavBar = (props) => {

    const [dashboard, setdashboard] = useState(false);
    const [ui, setui] = useState(false);
    const [app, setapp] = useState(false);
    const [email, setemail] = useState(false);
    const [ecommerce, setecommerce] = useState(false);
    const [project, setproject] = useState(false);
    const [contact, setcontact] = useState(false);
    const [component, setcomponent] = useState(false);
    const [form, setform] = useState(false);
    const [table, settable] = useState(false);
    const [chart, setchart] = useState(false);
    const [icon, seticon] = useState(false);
    const [map, setmap] = useState(false);
    const [pages, setpages] = useState(false);
    const [invoice, setinvoice] = useState(false);
    const [auth, setauth] = useState(false);
    const [utility, setutility] = useState(false);


    useEffect(() => {
        var matchingMenuItem = null;
        var ul = document.getElementById("navigation");
        var items = ul.getElementsByTagName("a");
        removeActivation(items);
        for (var i = 0; i < items.length; ++i) {
            if (props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    });

    const removeActivation = items => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;
            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                if (parent.classList.contains("active")) {
                    parent.classList.remove("active");
                }
            }
        }
    };

    function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("active"); // li
            const parent2 = parent.parentElement;
            parent2.classList.add("active"); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active"); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("active"); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add("active"); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add("active"); // li
                        }
                    }
                }
            }
        }
        return false;
    }

    return (
        <React.Fragment>
            <div className="topnav">
                <Container fluid>
                    <nav className="navbar navbar-light navbar-expand-lg topnav-menu active" id='navigation'>

                        <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content">
                            <ul className="navbar-nav">
                                <li className="nav-item dropdown">
                                    <Link
                                        className="nav-link dropdown-toggle arrow-none"
                                        onClick={e => {
                                            e.preventDefault();
                                            setdashboard(!dashboard);
                                        }}
                                        to="/dashboard"
                                    >
                                        <i className="bx bx-home-circle me-2"></i>
                                        Dashboard {props.menuOpen}
                                        <div className="arrow-down"></div>
                                    </Link>
                                    <div
                                        className={classname("dropdown-menu", { show: dashboard })}
                                    >
                                        <Link to="/index" className="dropdown-item" data-key="t-ecommerce">Ecommerce</Link>
                                        <Link to="/dashboard-saas" className="dropdown-item" data-key="t-saas">Saas</Link>
                                        <Link to="/dashboard-crypto" className="dropdown-item" data-key="t-crypto">Crypto</Link>
                                    </div>
                                </li>

                                <li className="nav-item dropdown">
                                    <Link
                                        to="/#"
                                        onClick={e => {
                                            e.preventDefault();
                                            setui(!ui);
                                        }}
                                        className="nav-link dropdown-toggle arrow-none"
                                    >
                                        <i className="bx bx-tone me-2"></i>
                                        UI Elements <div className="arrow-down"></div>
                                    </Link>
                                    <div
                                        className={classname(
                                            "dropdown-menu mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl",
                                            { show: ui }
                                        )}
                                    >
                                        <Row>
                                            <Col lg={4}>
                                                <div>
                                                    <Link to="/ui-alerts" className="dropdown-item" data-key="t-alerts">Alerts</Link>
                                                    <Link to="/ui-buttons" className="dropdown-item" data-key="t-buttons">Buttons</Link>
                                                    <Link to="/ui-cards" className="dropdown-item" data-key="t-cards">Cards</Link>
                                                    <Link to="/ui-carousel" className="dropdown-item" data-key="t-carousel">Carousel</Link>
                                                    <Link to="/ui-dropdowns" className="dropdown-item" data-key="t-dropdowns">Dropdowns</Link>
                                                    <Link to="/ui-grid" className="dropdown-item" data-key="t-grid">Grid</Link>
                                                    <Link to="/ui-images" className="dropdown-item" data-key="t-images">Images</Link>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div>
                                                    <Link to="/ui-lightbox" className="dropdown-item" data-key="t-lightbox">Lightbox</Link>
                                                    <Link to="/ui-modals" className="dropdown-item" data-key="t-modals">Modals</Link>
                                                    <Link to="/ui-offcanvas" className="dropdown-item" data-key="t-offcanvas">Offcanvas</Link>
                                                    <Link to="/ui-rangeslider" className="dropdown-item" data-key="t-range-slider">Range Slider</Link>
                                                    <Link to="/ui-progressbars" className="dropdown-item" data-key="t-progress-bars">Progress Bars</Link>
                                                    <Link to="/ui-tabs-accordions" className="dropdown-item" data-key="t-tabs-accordions">Tabs & Accordions</Link>
                                                    <Link to="/ui-typography" className="dropdown-item" data-key="t-typography">Typography</Link>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div>
                                                    <Link to="/ui-video" className="dropdown-item" data-key="t-video">Video</Link>
                                                    <Link to="/ui-general" className="dropdown-item" data-key="t-general">General</Link>
                                                    <Link to="/ui-colors" className="dropdown-item" data-key="t-colors">Colors</Link>
                                                    <Link to="/ui-rating" className="dropdown-item" data-key="t-rating">Rating</Link>
                                                    <Link to="/ui-notifications" className="dropdown-item" data-key="t-notifications">Notifications</Link>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </li>
                                {/* </NavItem> */}

                                <li className="nav-item dropdown">
                                    <Link
                                        to="/#"
                                        onClick={e => {
                                            e.preventDefault();
                                            setapp(!app);
                                        }}
                                        className="nav-link dropdown-togglez arrow-none"
                                    >
                                        <i className="bx bx-customize me-2"></i>
                                        Apps <div className="arrow-down"></div>
                                    </Link>
                                    <div className={classname("dropdown-menu", { show: app })}>
                                        <Link to="/calendar" className="dropdown-item">
                                            Calendar
                                        </Link>
                                        <Link to="/chat" className="dropdown-item">
                                            Chat
                                        </Link>
                                        <Link to="/apps-filemanager" className="dropdown-item">
                                            File Manager
                                        </Link>
                                        <div className="dropdown">
                                            <Link
                                                to="/#"
                                                className="dropdown-item dropdown-toggle arrow-none"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setecommerce(!ecommerce);
                                                }}
                                            >
                                                AAAAAAA
                                                <div className="arrow-down"></div>
                                            </Link>
                                            <div
                                                className={classname("dropdown-menu", {
                                                    show: ecommerce,
                                                })}
                                            >
                                                <Link to="/ecommerce-products" className="dropdown-item" data-key="t-products">Products</Link>
                                                <Link to="/ecommerce-product-detail" className="dropdown-item" data-key="t-product-detail">Product Detail</Link>
                                                <Link to="/ecommerce-orders" className="dropdown-item" data-key="t-orders">Orders</Link>
                                                <Link to="/ecommerce-customers" className="dropdown-item" data-key="t-customers">Customers</Link>
                                                <Link to="/ecommerce-cart" className="dropdown-item" data-key="t-cart">Cart</Link>
                                                <Link to="/ecommerce-checkout" className="dropdown-item" data-key="t-checkout">Checkout</Link>
                                                <Link to="/ecommerce-shops" className="dropdown-item" data-key="t-shops">Shops</Link>
                                                <Link to="/ecommerce-add-product" className="dropdown-item" data-key="t-add-product">Add Product</Link>
                                            </div>
                                        </div>

                                        <div className="dropdown">
                                            <Link
                                                to="/#"
                                                className="dropdown-item dropdown-toggle arrow-none"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setemail(!email);
                                                }}
                                            >
                                                Email <div className="arrow-down"></div>
                                            </Link>
                                            <div
                                                className={classname("dropdown-menu", { show: email })}
                                            >
                                                <Link to="/email-inbox" className="dropdown-item">
                                                    Inbox
                                                </Link>
                                                <Link to="/email-read" className="dropdown-item">
                                                    Read Email
                                                </Link>
                                                <div className="dropdown">
                                                    <Link
                                                        className="dropdown-item dropdown-toggle arrow-none"
                                                        to="/#"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            setemail(!email);
                                                        }}
                                                    >
                                                        <span key="t-email-templates">Templates</span>{" "}
                                                        <div className="arrow-down"></div>
                                                    </Link>
                                                    <div
                                                        className={classname("dropdown-menu", {
                                                            show: email,
                                                        })}
                                                    >
                                                        <Link
                                                            to="/email-template-basic"
                                                            className="dropdown-item"
                                                        >
                                                            Basic Action
                                                        </Link>
                                                        <Link
                                                            to="/email-template-alert"
                                                            className="dropdown-item"
                                                        >
                                                            Alert Email
                                                        </Link>
                                                        <Link
                                                            to="/email-template-billing"
                                                            className="dropdown-item"
                                                        >
                                                            Billing Email
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="dropdown">
                                            <Link
                                                to="/#"
                                                className="dropdown-item dropdown-toggle arrow-none"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setinvoice(!invoice);
                                                }}
                                            >
                                                Tasks <div className="arrow-down"></div>
                                            </Link>
                                            <div
                                                className={classname("dropdown-menu", { show: invoice })}
                                            >
                                                <Link to="/invoices-list" className="dropdown-item" data-key="t-invoice-list">Invoice List</Link>
                                                <Link to="/invoices-detail" className="dropdown-item" data-key="t-invoice-detail">Invoice Detail</Link>
                                            </div>
                                        </div>

                                        <div className="dropdown">
                                            <Link
                                                to="/#"
                                                className="dropdown-item dropdown-toggle arrow-none"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setproject(!project);
                                                }}
                                            >
                                                Projects <div className="arrow-down"></div>
                                            </Link>
                                            <div
                                                className={classname("dropdown-menu", {
                                                    show: project,
                                                })}
                                            >
                                                <Link to="/projects-grid" className="dropdown-item" data-key="t-p-grid">Projects Grid</Link>
                                                <Link to="/projects-list" className="dropdown-item" data-key="t-p-list">Projects List</Link>
                                                <Link to="/projects-create" className="dropdown-item" data-key="t-create-new">Create New</Link>
                                            </div>
                                        </div>

                                        <div className="dropdown">
                                            <Link
                                                to="/#"
                                                className="dropdown-item dropdown-toggle arrow-none"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setcontact(!contact);
                                                }}
                                            >
                                                Contacts <div className="arrow-down"></div>
                                            </Link>
                                            <div
                                                className={classname("dropdown-menu", {
                                                    show: contact,
                                                })}
                                            >
                                                <Link to="/contacts-grid" className="dropdown-item">
                                                    User Grid
                                                </Link>
                                                <Link to="/contacts-list" className="dropdown-item">
                                                    User List
                                                </Link>
                                                <Link to="/contacts-profile" className="dropdown-item">
                                                    Profile
                                                </Link>
                                            </div>
                                        </div>

                                    </div>
                                </li>

                                <li className="nav-item dropdown">
                                    <Link
                                        to="/#"
                                        className="nav-link dropdown-toggle arrow-none"
                                        onClick={e => {
                                            e.preventDefault();
                                            setcomponent(!component);
                                        }}
                                    >
                                        <i className="bx bx-collection me-2"></i>
                                        Components <div className="arrow-down"></div>
                                    </Link>
                                    <div
                                        className={classname("dropdown-menu", { show: component })}
                                    >
                                        <div className="dropdown">
                                            <Link
                                                to="/#"
                                                className="dropdown-item dropdown-toggle arrow-none"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setform(!form);
                                                }}
                                            >
                                                Forms <div className="arrow-down"></div>
                                            </Link>
                                            <div
                                                className={classname("dropdown-menu", { show: form })}
                                            >
                                                <Link to="/form-elements" className="dropdown-item">
                                                    Form Elements
                                                </Link>
                                                <Link to="/form-layouts" className="dropdown-item">
                                                    Form Layouts
                                                </Link>
                                                <Link to="/form-validation" className="dropdown-item">
                                                    Form Validation
                                                </Link>
                                                <Link to="/form-advanced" className="dropdown-item">
                                                    Form Advanced
                                                </Link>
                                                <Link to="/form-editors" className="dropdown-item">
                                                    Form Editors
                                                </Link>
                                                <Link to="/form-uploads" className="dropdown-item">
                                                    Form File Upload{" "}
                                                </Link>
                                                <Link to="/form-xeditable" className="dropdown-item">
                                                    Form Xeditable
                                                </Link>
                                                <Link to="/form-repeater" className="dropdown-item">
                                                    Form Repeater
                                                </Link>
                                                <Link to="/form-wizard" className="dropdown-item">
                                                    Form Wizard
                                                </Link>
                                                <Link to="/form-mask" className="dropdown-item">
                                                    Form Mask
                                                </Link>
                                                <Link to="/dual-listbox" className="dropdown-item">
                                                    Transfer List
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            <Link
                                                to="/#"
                                                className="dropdown-item dropdown-toggle arrow-none"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    settable(!table);
                                                }}
                                            >
                                                Tables <div className="arrow-down"></div>
                                            </Link>
                                            <div
                                                className={classname("dropdown-menu", { show: table })}
                                            >
                                                <Link to="/tables-basic" className="dropdown-item">
                                                    Basic Tables
                                                </Link>
                                                <Link to="/tables-advanced" className="dropdown-item" data-key="t-advanced-tables">Advance Tables</Link>
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            <Link
                                                to="/#"
                                                className="dropdown-item dropdown-toggle arrow-none"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setchart(!chart);
                                                }}
                                            >
                                                Charts <div className="arrow-down"></div>
                                            </Link>
                                            <div
                                                className={classname("dropdown-menu", { show: chart })}
                                            >
                                                <Link to="/apex-charts" className="dropdown-item">
                                                    {" "}
                                                    Apex charts
                                                </Link>
                                                <Link to="/e-charts" className="dropdown-item">
                                                    {" "}
                                                    E Chart
                                                </Link>
                                                <Link to="/chartist-charts" className="dropdown-item">
                                                    {" "}
                                                    Chartjs Chart
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            <Link
                                                to="/#"
                                                className="dropdown-item dropdown-toggle arrow-none"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    seticon(!icon);
                                                }}
                                            >
                                                Icons <div className="arrow-down"></div>
                                            </Link>
                                            <div
                                                className={classname("dropdown-menu", { show: icon })}
                                            >
                                                <Link to="/icons-evaicons" className="dropdown-item" data-key="t-evaicons">Eva Icons</Link>
                                                <Link to="/icons-boxicons" className="dropdown-item" data-key="t-boxicons">Boxicons</Link>
                                                <Link to="/icons-materialdesign" className="dropdown-item" data-key="t-material-design">Material Design</Link>
                                                <Link to="/icons-fontawesome" className="dropdown-item" data-key="t-font-awesome">Font Awesome 5</Link>
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            <Link
                                                to="/#"
                                                className="dropdown-item dropdown-toggle arrow-none"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setmap(!map);
                                                }}
                                            >
                                                Maps <div className="arrow-down"></div>
                                            </Link>
                                            <div
                                                className={classname("dropdown-menu", { show: map })}
                                            >
                                                <Link to="/maps-google" className="dropdown-item">
                                                    Google Maps{" "}
                                                </Link>
                                                <Link to="/maps-vector" className="dropdown-item">
                                                    Vector Maps{" "}
                                                </Link>
                                                <Link to="/maps-leaflet" className="dropdown-item">
                                                    Leaflet Maps{" "}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li className="nav-item dropdown">
                                    <Link
                                        className="nav-link dropdown-toggle arrow-none"
                                        to="/#"
                                        onClick={e => {
                                            e.preventDefault();
                                            setpages(!pages);
                                        }}
                                    >
                                        <i className="bx bx-file me-2"></i>
                                        Extra pages <div className="arrow-down"></div>
                                    </Link>
                                    <div className={classname("dropdown-menu", { show: pages })}>
                                        <div className="dropdown">
                                            <Link
                                                to="/#"
                                                className="dropdown-item dropdown-toggle arrow-none"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setinvoice(!invoice);
                                                }}
                                            >
                                                Invoices <div className="arrow-down"></div>
                                            </Link>
                                            <div
                                                className={classname("dropdown-menu", {
                                                    show: invoice,
                                                })}
                                            >
                                                <Link to="/invoices-list" className="dropdown-item">
                                                    Invoice List
                                                </Link>
                                                <Link to="/invoices-detail" className="dropdown-item">
                                                    Invoice Detail
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="dropdown">
                                            <Link
                                                to="/#"
                                                className="dropdown-item dropdown-toggle arrow-none"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setauth(!auth);
                                                }}
                                            >
                                                Authentication{" "}
                                                <div className="arrow-down"></div>
                                            </Link>
                                            <div
                                                className={classname("dropdown-menu", { show: auth })}
                                            >
                                                <Link to="/login" className="dropdown-item" data-key="t-login">Login</Link>
                                                <Link to="/register" className="dropdown-item" data-key="t-register">Register</Link>
                                                <Link to="/auth-recoverpw" className="dropdown-item" data-key="t-recover-password">Recover Password</Link>
                                                <Link to="/auth-lock-screen" className="dropdown-item" data-key="t-lock-screen">Lock Screen</Link>
                                                <Link to="/login" className="dropdown-item" data-key="t-logout">Logout</Link>
                                                <Link to="/auth-confirm-mail" className="dropdown-item" data-key="t-confirm-mail">Confirm Mail</Link>
                                                <Link to="/auth-email-verification" className="dropdown-item" data-key="t-email-verification">Email Verification</Link>
                                                <Link to="/auth-two-step-verification" className="dropdown-item" data-key="t-two-step-verification">Two Step Verification</Link>
                                            </div>
                                        </div>

                                        <div className="dropdown">
                                            <Link
                                                className="dropdown-item dropdown-toggle arrow-none"
                                                to="/#"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setutility(!utility);
                                                }}
                                            >
                                                Utility <div className="arrow-down"></div>
                                            </Link>
                                            <div
                                                className={classname("dropdown-menu", {
                                                    show: utility,
                                                })}
                                            >
                                                <Link to="/pages-starter" className="dropdown-item" data-key="t-starter-page">Starter Page</Link>
                                                <Link to="/pages-maintenance" className="dropdown-item" data-key="t-maintenance">Maintenance</Link>
                                                <Link to="/pages-comingsoon" className="dropdown-item" data-key="t-coming-soon">Coming Soon</Link>
                                                <Link to="/pages-timeline" className="dropdown-item" data-key="t-timeline">Timeline</Link>
                                                <Link to="/pages-faqs" className="dropdown-item" data-key="t-faqs">FAQs</Link>
                                                <Link to="/pages-pricing" className="dropdown-item" data-key="t-pricing">Pricing</Link>
                                                <Link to="/pages-404" className="dropdown-item" data-key="t-error-404">Error 404</Link>
                                                <Link to="/pages-500" className="dropdown-item" data-key="t-error-500">Error 500</Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Collapse>
                    </nav>
                </Container>
            </div>
        </React.Fragment>
    );
};


NavBar.propTypes = {
    leftMenu: PropTypes.any,
    location: PropTypes.any,
    menuOpen: PropTypes.any,
    t: PropTypes.any,
};

const mapStatetoProps = state => {
    const { leftMenu } = state.Layout;
    return { leftMenu };
};

export default withRouter(
    connect(mapStatetoProps, {})((NavBar))
);
