import React, { useEffect } from 'react';
import Icon from 'react-eva-icons';
import MetaTags from "react-meta-tags";

// redux
import { useSelector } from "react-redux";

// Import Images
import logodark from "../../assets/images/logo.png";
import logolight from "../../assets/images/logo.png";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import AuthSlider from './authCarousel';

const LockScreen = () => {
    const { layoutMode } = useSelector((state) => ({
        layoutMode: state.Layout.layoutMode,
    }));

    useEffect(() => {
        if (layoutMode === "dark") {
            document.body.classList.remove("bg-transparent");
        } else {

            document.body.className = "bg-transparent";
        }
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });
    return (
        <React.Fragment>
            <MetaTags>
                <title>Lock Screen | Painel Gomus</title>
            </MetaTags>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0 align-items-center">
                        <Col className="col-xxl-4 col-lg-4 col-md-6">
                            <Row className="justify-content-center g-0">
                                <Col xl={9}>
                                    <div className="p-4">
                                        <Card className="mb-0">
                                            <CardBody>
                                                <div className="auth-full-page-content rounded d-flex p-3 my-2">
                                                    <div className="w-100">
                                                        <div className="d-flex flex-column h-100">
                                                            <div className="mb-4 mb-md-5">
                                                                <Link to="/index" className="d-block auth-logo">
                                                                    <img src={logodark} alt="" height="22" className="auth-logo-dark me-start" />
                                                                    <img src={logolight} alt="" height="22" className="auth-logo-light me-start" />
                                                                </Link>
                                                            </div>

                                                            <div className="auth-content my-auto">
                                                                <div className="text-center">
                                                                    <h5 className="mb-0">Lock screen</h5>
                                                                    <p className="text-muted mt-2">Enter your password to unlock the screen!</p>
                                                                </div>
                                                                <div className="user-thumb text-center mb-4 mt-4 pt-2">
                                                                    <img src={avatar1} className="rounded-circle img-thumbnail avatar-lg" alt="thumbnail" />
                                                                    <h5 className="font-size-15 mt-3">Jennifer Bennett</h5>
                                                                </div>
                                                                <form className="mt-4 pt-2" action="/">
                                                                    <div className="form-floating form-floating-custom mb-4 auth-pass-inputgroup">
                                                                        <input type="password" className="form-control pe-5" id="password-input" placeholder="Enter Password" />
                                                                        <label htmlFor="input-password">Password</label>
                                                                        <div className="form-floating-icon">
                                                                        <Icon name="lock-outline" fill="#555b6d" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <button className="btn btn-primary w-100 waves-effect waves-light" type="submit">Unlock</button>
                                                                    </div>
                                                                </form>

                                                                <div className="mt-4 pt-3 text-center">
                                                                    <p className="text-muted mb-0">Not you ? return <Link to="/login"
                                                                        className="text-primary fw-semibold"> Sign In </Link> </p>
                                                                </div>
                                                            </div>


                                                            <div className="mt-4 text-center">
                                                                <p className="mb-0">© {new Date().getFullYear()} Borex   . Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <AuthSlider />
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default LockScreen;
