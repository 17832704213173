import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

// Import Images
import Img1 from "../../assets/images/product/img-1.jpg";
import Img2 from "../../assets/images/product/img-2.jpg";
import Img3 from "../../assets/images/product/img-3.jpg";
import { Link } from 'react-router-dom';


const Cart = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Cart",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);
    return (
        <React.Fragment>
            <MetaTags>
                <title>Cart | Painel Gomus</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={8}>
                            <Card className="border shadow-none">
                                <CardBody>

                                    <div className="d-flex align-items-start border-bottom pb-3">
                                        <div className="me-4">
                                            <img src={Img1} alt="" className="avatar-lg rounded" />
                                        </div>
                                        <div className="flex-grow-1 align-self-center overflow-hidden">
                                            <div>
                                                <h5 className="text-truncate font-size-18"><Link to="/ecommerce-product-detail" className="text-dark">Waterproof Mobile Phone </Link></h5>
                                                <p className="text-muted mb-0">
                                                    <i className="bx bxs-star text-warning"></i>
                                                    <i className="bx bxs-star text-warning"></i>
                                                    <i className="bx bxs-star text-warning"></i>
                                                    <i className="bx bxs-star text-warning"></i>
                                                    <i className="bx bxs-star-half text-warning"></i>
                                                </p>
                                                <p className="mb-0 mt-1">Color : <span className="fw-medium">Gray</span></p>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 ms-2">
                                            <ul className="list-inline mb-0 font-size-16">
                                                <li className="list-inline-item">
                                                    <Link to="#" className="text-muted px-1">
                                                        <i className="mdi mdi-trash-can-outline"></i>
                                                    </Link>
                                                </li>
                                                <li className="list-inline-item">
                                                    <Link to="#" className="text-muted px-1">
                                                        <i className="mdi mdi-heart-outline"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div>
                                        <Row>
                                            <Col md={4}>
                                                <div className="mt-3">
                                                    <p className="text-muted mb-2">Price</p>
                                                    <h5 className="mb-0 mt-2"><span className="text-muted me-2"><del className="font-size-16 fw-normal">$500</del></span>$450</h5>
                                                </div>
                                            </Col>
                                            <Col md={5}>
                                                <div className="mt-3">
                                                    <p className="text-muted mb-2">Quantity</p>
                                                    <div className="d-inline-flex">
                                                        <select className="form-select form-select-sm w-xl">
                                                            <option value="1">1</option>
                                                            <option value="2" defaultValue>2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className="mt-3">
                                                    <p className="text-muted mb-2">Total</p>
                                                    <h5>$900</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                </CardBody>
                            </Card>

                            <Card className="border shadow-none">
                                <CardBody>

                                    <div className="d-flex align-items-start border-bottom pb-3">
                                        <div className="me-4">
                                            <img src={Img2} alt="" className="avatar-lg rounded" />
                                        </div>
                                        <div className="flex-grow-1 align-self-center overflow-hidden">
                                            <div>
                                                <h5 className="text-truncate font-size-18"><Link to="/ecommerce-product-detail" className="text-dark">Smartphone Dual Camera </Link></h5>
                                                <p className="text-muted mb-0">
                                                    <i className="bx bxs-star text-warning"></i>
                                                    <i className="bx bxs-star text-warning"></i>
                                                    <i className="bx bxs-star text-warning"></i>
                                                    <i className="bx bxs-star text-warning"></i>
                                                </p>
                                                <p className="mb-0 mt-1">Color : <span className="fw-medium">Green</span></p>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 ms-2">
                                            <ul className="list-inline mb-0 font-size-16">
                                                <li className="list-inline-item">
                                                    <Link to="#" className="text-muted px-1">
                                                        <i className="mdi mdi-trash-can-outline"></i>
                                                    </Link>
                                                </li>
                                                <li className="list-inline-item">
                                                    <Link to="#" className="text-muted px-1">
                                                        <i className="mdi mdi-heart-outline"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div>
                                        <Row>
                                            <Col md={4}>
                                                <div className="mt-3">
                                                    <p className="text-muted mb-2">Price</p>
                                                    <h5 className="mb-0 mt-2"><span className="text-muted me-2"><del className="font-size-16 fw-normal">$280</del></span>$240</h5>
                                                </div>
                                            </Col>
                                            <Col md={5}>
                                                <div className="mt-3">
                                                    <p className="text-muted mb-2">Quantity</p>
                                                    <div className="d-inline-flex">
                                                        <select className="form-select form-select-sm w-xl">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3" defaultValue>3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className="mt-3">
                                                    <p className="text-muted mb-2">Total</p>
                                                    <h5>$720</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                </CardBody>
                            </Card>

                            <Card className="border shadow-none">
                                <CardBody>

                                    <div className="d-flex align-items-start border-bottom pb-3">
                                        <div className="me-4">
                                            <img src={Img3} alt="" className="avatar-lg rounded" />
                                        </div>
                                        <div className="flex-grow-1 align-self-center overflow-hidden">
                                            <div>
                                                <h5 className="text-truncate font-size-18"><Link to="/ecommerce-product-detail" className="text-dark">Black Colour Smartphone </Link></h5>
                                                <p className="text-muted mb-0">
                                                    <i className="bx bxs-star text-warning"></i>
                                                    <i className="bx bxs-star text-warning"></i>
                                                    <i className="bx bxs-star text-warning"></i>
                                                </p>
                                                <p className="mb-0 mt-1">Color : <span className="fw-medium">Blue</span></p>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 ms-2">
                                            <ul className="list-inline mb-0 font-size-16">
                                                <li className="list-inline-item">
                                                    <Link to="#" className="text-muted px-1">
                                                        <i className="mdi mdi-trash-can-outline"></i>
                                                    </Link>
                                                </li>
                                                <li className="list-inline-item">
                                                    <Link to="#" className="text-muted px-1">
                                                        <i className="mdi mdi-heart-outline"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div>
                                        <Row>
                                            <Col md={4}>
                                                <div className="mt-3">
                                                    <p className="text-muted mb-2">Price</p>
                                                    <h5 className="mb-0 mt-2"><span className="text-muted me-2"><del className="font-size-16 fw-normal">$750</del></span>$950</h5>
                                                </div>
                                            </Col>
                                            <Col md={5}>
                                                <div className="mt-3">
                                                    <p className="text-muted mb-2">Quantity</p>
                                                    <div className="d-inline-flex">
                                                        <select className="form-select form-select-sm w-xl">
                                                            <option value="1" defaultValue>1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className="mt-3">
                                                    <p className="text-muted mb-2">Total</p>
                                                    <h5>$950</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                </CardBody>
                            </Card>

                            <Row className="my-4">
                                <Col sm={6}>
                                    <Link to="/ecommerce-products" className="btn btn-link text-muted">
                                        <i className="mdi mdi-arrow-left me-1"></i> Continue Shopping </Link>
                                </Col>
                                <Col sm={6}>
                                    <div className="text-sm-end mt-2 mt-sm-0">
                                        <Link to="/ecommerce-checkout" className="btn btn-success">
                                            <i className="mdi mdi-cart-outline me-1"></i> Checkout </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col xl={4}>
                            <div className="mt-5 mt-lg-0">
                                <Card className="border shadow-none">
                                    <CardHeader className="bg-transparent border-bottom py-3 px-4">
                                        <h5 className="font-size-16 mb-0">Order Summary <span className="float-end">#MN0124</span></h5>
                                    </CardHeader>
                                    <CardBody className="p-4 pt-2">

                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td>Sub Total :</td>
                                                        <td className="text-end">$ 780</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Discount : </td>
                                                        <td className="text-end">- $ 78</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Shipping Charge :</td>
                                                        <td className="text-end">$ 25</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Estimated Tax : </td>
                                                        <td className="text-end">$ 18.20</td>
                                                    </tr>
                                                    <tr className="bg-light">
                                                        <th>Total :</th>
                                                        <td className="text-end">
                                                            <span className="fw-bold">
                                                                $ 745.2
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Cart;
