import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';

import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";

const RecentFiles = () => {
    return (
        <React.Fragment>
            <div className="d-flex flex-wrap">
                <h5 className="font-size-16 me-3">Recent Files</h5>
                <div className="ms-auto">
                    <Link to="#" className="fw-medium text-reset">View All</Link>
                </div>
            </div>
            <hr className="mt-2" />
            <div className="table-responsive">
                <table className="table align-middle table-nowrap table-hover mb-0">
                    <thead className="table-light">
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Date modified</th>
                            <th scope="col">Size</th>
                            <th scope="col" colSpan="2">Members</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Link to="#" className="text-dark fw-medium"><i className="mdi mdi-file-document font-size-16 align-middle text-primary me-2"></i> index.html</Link></td>
                            <td>12-10-2020, 09:45</td>
                            <td>09 KB</td>
                            <td>
                                <div className="avatar-group">
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar4} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar5} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <div className="avatar-sm">
                                                <span className="avatar-title rounded-circle bg-success text-white font-size-16">
                                                    A
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <UncontrolledDropdown>
                                    <DropdownToggle className="font-size-16 text-muted" type="button" tag="a" to="#">
                                        <i className="mdi mdi-dots-horizontal"></i>
                                    </DropdownToggle>

                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Open</DropdownItem>
                                        <DropdownItem>Edit</DropdownItem>
                                        <DropdownItem>Rename</DropdownItem>
                                        <div className="dropdown-divider"></div>
                                        <DropdownItem>Remove</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </td>
                        </tr>
                        <tr>
                            <td><Link to="#" className="text-dark fw-medium"><i className="mdi mdi-folder-zip font-size-16 align-middle text-warning me-2"></i> Project-A.zip</Link></td>
                            <td>11-10-2020, 17:05</td>
                            <td>115 KB</td>
                            <td>
                                <div className="avatar-group">
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar8} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="dropdown">
                                    <Link to="#" className="font-size-16 text-muted" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                        <i className="mdi mdi-dots-horizontal"></i>
                                    </Link>

                                    <div className="dropdown-menu dropdown-menu-end">
                                        <Link className="dropdown-item" to="#">Open</Link>
                                        <Link className="dropdown-item" to="#">Edit</Link>
                                        <Link className="dropdown-item" to="#">Rename</Link>
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to="#">Remove</Link>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><Link to="#" className="text-dark fw-medium"><i className="mdi mdi-image font-size-16 align-middle text-muted me-2"></i> Img-1.jpeg</Link></td>
                            <td>11-10-2020, 13:26</td>
                            <td>86 KB</td>
                            <td>
                                <div className="avatar-group">
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <div className="avatar-sm">
                                                <span className="avatar-title rounded-circle bg-info text-white font-size-16">
                                                    K
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="dropdown">
                                    <Link to="#" className="font-size-16 text-muted" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                        <i className="mdi mdi-dots-horizontal"></i>
                                    </Link>

                                    <div className="dropdown-menu dropdown-menu-end">
                                        <Link className="dropdown-item" to="#">Open</Link>
                                        <Link className="dropdown-item" to="#">Edit</Link>
                                        <Link className="dropdown-item" to="#">Rename</Link>
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to="#">Remove</Link>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><Link to="#" className="text-dark fw-medium"><i className="mdi mdi-text-box font-size-16 align-middle text-muted me-2"></i> update list.txt</Link></td>
                            <td>10-10-2020, 11:32</td>
                            <td>08 KB</td>
                            <td>
                                <div className="avatar-group">
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar1} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar6} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar7} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="dropdown">
                                    <Link to="#" className="font-size-16 text-muted" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                        <i className="mdi mdi-dots-horizontal"></i>
                                    </Link>

                                    <div className="dropdown-menu dropdown-menu-end">
                                        <Link className="dropdown-item" to="#">Open</Link>
                                        <Link className="dropdown-item" to="#">Edit</Link>
                                        <Link className="dropdown-item" to="#">Rename</Link>
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to="#">Remove</Link>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><Link to="#" className="text-dark fw-medium"><i className="mdi mdi-folder font-size-16 align-middle text-warning me-2"></i> Project B</Link></td>
                            <td>10-10-2020, 10:51</td>
                            <td>72 KB</td>
                            <td>
                                <div className="avatar-group">
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar1} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar3} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <div className="avatar-sm">
                                                <span className="avatar-title rounded-circle bg-danger text-white font-size-16">
                                                    3+
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="dropdown">
                                    <Link to="#" className="font-size-16 text-muted" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                        <i className="mdi mdi-dots-horizontal"></i>
                                    </Link>

                                    <div className="dropdown-menu dropdown-menu-end">
                                        <Link className="dropdown-item" to="#">Open</Link>
                                        <Link className="dropdown-item" to="#">Edit</Link>
                                        <Link className="dropdown-item" to="#">Rename</Link>
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to="#">Remove</Link>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><Link to="#" className="text-dark fw-medium"><i className="mdi mdi-text-box font-size-16 align-middle text-muted me-2"></i> Changes list.txt</Link></td>
                            <td>09-10-2020, 17:05</td>
                            <td>07 KB</td>
                            <td>
                                <div className="avatar-group">
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar4} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar5} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="dropdown">
                                    <Link to="#" className="font-size-16 text-muted" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                        <i className="mdi mdi-dots-horizontal"></i>
                                    </Link>

                                    <div className="dropdown-menu dropdown-menu-end">
                                        <Link className="dropdown-item" to="#">Open</Link>
                                        <Link className="dropdown-item" to="#">Edit</Link>
                                        <Link className="dropdown-item" to="#">Rename</Link>
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to="#">Remove</Link>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><Link to="#" className="text-dark fw-medium"><i className="mdi mdi-image font-size-16 align-middle text-success me-2"></i> Img-2.png</Link></td>
                            <td>09-10-2020, 15:12</td>
                            <td>31 KB</td>
                            <td>
                                <div className="avatar-group">
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <div className="avatar-sm">
                                                <span className="avatar-title rounded-circle bg-pink text-white font-size-16">
                                                    L
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="dropdown">
                                    <Link to="#" className="font-size-16 text-muted" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                        <i className="mdi mdi-dots-horizontal"></i>
                                    </Link>

                                    <div className="dropdown-menu dropdown-menu-end">
                                        <Link className="dropdown-item" to="#">Open</Link>
                                        <Link className="dropdown-item" to="#">Edit</Link>
                                        <Link className="dropdown-item" to="#">Rename</Link>
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to="#">Remove</Link>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><Link to="#" className="text-dark fw-medium"><i className="mdi mdi-folder font-size-16 align-middle text-warning me-2"></i> Project C</Link></td>
                            <td>09-10-2020, 10:11</td>
                            <td>20 KB</td>
                            <td>
                                <div className="avatar-group">
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar4} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar5} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <div className="avatar-sm">
                                                <span className="avatar-title rounded-circle bg-success text-white font-size-16">
                                                    A
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="dropdown">
                                    <Link to="#" className="font-size-16 text-muted" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                        <i className="mdi mdi-dots-horizontal"></i>
                                    </Link>

                                    <div className="dropdown-menu dropdown-menu-end">
                                        <Link className="dropdown-item" to="#">Open</Link>
                                        <Link className="dropdown-item" to="#">Edit</Link>
                                        <Link className="dropdown-item" to="#">Rename</Link>
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to="#">Remove</Link>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><Link to="#" className="text-dark fw-medium"><i className="bx bxs-file font-size-16 align-middle text-primary me-2"></i> starter-page.html</Link></td>
                            <td>08-10-2020, 03:22</td>
                            <td>11 KB</td>
                            <td>
                                <div className="avatar-group">
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar8} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                        </Link>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="dropdown">
                                    <Link to="#" className="font-size-16 text-muted" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                        <i className="mdi mdi-dots-horizontal"></i>
                                    </Link>

                                    <div className="dropdown-menu dropdown-menu-end">
                                        <Link className="dropdown-item" to="#">Open</Link>
                                        <Link className="dropdown-item" to="#">Edit</Link>
                                        <Link className="dropdown-item" to="#">Rename</Link>
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to="#">Remove</Link>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

export default RecentFiles;