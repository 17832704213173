import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row, UncontrolledAlert, } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import { Link } from 'react-router-dom';


const UiAlerts = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Alerts",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);
    return (
        <React.Fragment>
            <MetaTags>
                <title>Alerts | Painel Gomus</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Default Alerts</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Alerts are available for any length of
                                        text, as well as an optional dismiss button. For proper styling, use one
                                        of the four <strong>required</strong> contextual classes (e.g., <code
                                        >.alert-success</code>). For inline
                                        dismissal, use the alerts jQuery plugin.</p>

                                    <div className="">
                                        <div className="alert alert-primary" role="alert">
                                            A simple primary alert—check it out!
                                        </div>
                                        <div className="alert alert-secondary" role="alert">
                                            A simple secondary alert—check it out!
                                        </div>
                                        <div className="alert alert-success" role="alert">
                                            A simple success alert—check it out!
                                        </div>
                                        <div className="alert alert-danger" role="alert">
                                            A simple danger alert—check it out!
                                        </div>
                                        <div className="alert alert-warning" role="alert">
                                            A simple warning alert—check it out!
                                        </div>
                                        <div className="alert alert-info mb-0" role="alert">
                                            A simple info alert—check it out!
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>


                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Link color</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Use the <code
                                    >.alert-link</code> utility class to
                                        quickly provide matching colored links within any alert.</p>

                                    <div className="">
                                        <div className="alert alert-primary" role="alert">
                                            A simple primary alert with <Link to="#" className="alert-link">an example link</Link>. Give it a click if you like.
                                        </div>
                                        <div className="alert alert-secondary" role="alert">
                                            A simple secondary alert with <Link to="#" className="alert-link">an example link</Link>. Give it a click if you like.
                                        </div>
                                        <div className="alert alert-success" role="alert">
                                            A simple success alert with <Link to="#" className="alert-link">an example link</Link>. Give it a click if you like.
                                        </div>
                                        <div className="alert alert-danger" role="alert">
                                            A simple danger alert with <Link to="#" className="alert-link">an example link</Link>. Give it a click if you like.
                                        </div>
                                        <div className="alert alert-warning" role="alert">
                                            A simple warning alert with <Link to="#" className="alert-link">an example link</Link>. Give it a click if you like.
                                        </div>
                                        <div className="alert alert-info mb-0" role="alert">
                                            A simple info alert with <Link to="#" className="alert-link">an example link</Link>. Give it a click if you like.
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Dismissing</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">
                                        Add a dismiss button and the <code>.alert-dismissible</code> class, which adds extra padding
                                        to the right of the alert and positions the <code>.btn-close</code> button.
                                    </p>
                                    <div className="">
                                        <UncontrolledAlert color='primary' >
                                            A simple primary alert—check it out!
                                        </UncontrolledAlert>
                                        <UncontrolledAlert color='secondary' >
                                            A simple secondary alert—check it out!
                                        </UncontrolledAlert>
                                        <UncontrolledAlert color='success'>
                                            A simple success alert—check it out!
                                        </UncontrolledAlert>
                                        <UncontrolledAlert color='danger'>
                                            A simple danger alert—check it out!
                                        </UncontrolledAlert>
                                        <UncontrolledAlert color='warning'>
                                            A simple warning alert—check it out!
                                        </UncontrolledAlert>
                                        <UncontrolledAlert color='info'>
                                            A simple info alert—check it out!
                                        </UncontrolledAlert>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">With Icon</h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="">

                                        <UncontrolledAlert color='primary' >
                                            <i className="mdi mdi-bullseye-arrow me-2"></i>
                                            A simple primary alert—check it out!
                                        </UncontrolledAlert>
                                        <UncontrolledAlert color='secondary' >
                                            <i className="mdi mdi-grease-pencil me-2"></i>
                                            A simple secondary alert—check it out!
                                        </UncontrolledAlert>
                                        <UncontrolledAlert color='success'>
                                            <i className="mdi mdi-check-all me-2"></i>
                                            A simple success alert—check it out!
                                        </UncontrolledAlert>
                                        <UncontrolledAlert color='danger'>
                                            <i className="mdi mdi-block-helper me-2"></i>
                                            A simple danger alert—check it out!
                                        </UncontrolledAlert>
                                        <UncontrolledAlert color='warning'>
                                            <i className="mdi mdi-alert-outline me-2"></i>
                                            A simple warning alert—check it out!
                                        </UncontrolledAlert>
                                        <UncontrolledAlert color='info'>
                                            <i className="mdi mdi-alert-circle-outline me-2"></i>
                                            A simple info alert—check it out!
                                        </UncontrolledAlert>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UiAlerts;
