import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Popover, PopoverBody, PopoverHeader, Row, Tooltip, UncontrolledPopover } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import { Link } from 'react-router-dom';

const UiGeneral = () => {

    const [popovertop, setpopovertop] = useState(false);
    const [popoverleft, setpopoverleft] = useState(false);
    const [popoverright, setpopoverright] = useState(false);
    const [popoverbottom, setpopoverbottom] = useState(false);
    const [popoverdismiss, setpopoverdismiss] = useState(false);

    const [ttop, setttop] = useState(false);
    const [tbottom, settbottom] = useState(false);
    const [tleft, settleft] = useState(false);
    const [tright, settright] = useState(false);


    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "General",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);
    return (
        <React.Fragment>
            <MetaTags>
                <title>General | Painel Gomus</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Badges</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Add any of the below mentioned modifier classes to change the appearance of a badge.</p>
                                    <div>
                                        <span className="badge bg-primary">Primary</span>
                                        <span className="badge bg-success">Success</span>
                                        <span className="badge bg-info">Info</span>
                                        <span className="badge bg-warning">Warning</span>
                                        <span className="badge bg-danger">Danger</span>
                                        <span className="badge bg-dark">Dark</span>
                                    </div>

                                    <div className="mt-4">
                                        <h5 className="font-size-14">Soft Badge</h5>
                                        <div className="mt-3">
                                            <span className="badge badge-soft-primary">Primary</span>
                                            <span className="badge badge-soft-success">Success</span>
                                            <span className="badge badge-soft-info">Info</span>
                                            <span className="badge badge-soft-warning">Warning</span>
                                            <span className="badge badge-soft-danger">Danger</span>
                                            <span className="badge badge-soft-dark">Dark</span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Pill badges</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Use the <code>.rounded-pill</code> modifier class to make
                                        badges more rounded (with a larger <code>border-radius</code>
                                        and additional horizontal <code>padding</code>).
                                        Useful if you miss the badges from v3.</p>
                                    <div>
                                        <span className="badge rounded-pill bg-primary">Primary</span>
                                        <span className="badge rounded-pill bg-success">Success</span>
                                        <span className="badge rounded-pill bg-info">Info</span>
                                        <span className="badge rounded-pill bg-warning">Warning</span>
                                        <span className="badge rounded-pill bg-danger">Danger</span>
                                        <span className="badge rounded-pill bg-dark">Dark</span>
                                    </div>

                                    <div className="mt-4">
                                        <h5 className="font-size-14">Soft Badge</h5>
                                        <div className="mt-3">
                                            <span className="badge rounded-pill badge-soft-primary">Primary</span>
                                            <span className="badge rounded-pill badge-soft-success">Success</span>
                                            <span className="badge rounded-pill badge-soft-info">Info</span>
                                            <span className="badge rounded-pill badge-soft-warning">Warning</span>
                                            <span className="badge rounded-pill badge-soft-danger">Danger</span>
                                            <span className="badge rounded-pill badge-soft-dark">Dark</span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Popovers</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Four options are available: top, right, bottom, and left aligned. Directions are mirrored when using Bootstrap in RTL.</p>

                                    <div className="d-flex flex-wrap gap-2">


                                        <Button
                                            id="Popovertop"
                                            color="secondary"
                                            onClick={() => {
                                                setpopovertop(!popovertop);
                                            }}
                                        >
                                            Popover on top
                                        </Button>
                                        <Popover
                                            placement="top"
                                            isOpen={popovertop}
                                            target="Popovertop"
                                            toggle={() => {
                                                setpopovertop(!popovertop);
                                            }}
                                        >
                                            <PopoverBody>
                                                Vivamus sagittis lacus vel augue laoreet rutrum faucibus.
                                            </PopoverBody>
                                        </Popover>
                                        <Button
                                            id="Popoverright"
                                            onClick={() => {
                                                setpopoverright(!popoverright);
                                            }}
                                            color="secondary"
                                        >
                                            Popover on right
                                        </Button>
                                        <Popover
                                            placement="right"
                                            isOpen={popoverright}
                                            target="Popoverright"
                                            toggle={() => {
                                                setpopoverright(!popoverright);
                                            }}
                                        >
                                            <PopoverBody>
                                                Vivamus sagittis lacus vel augue laoreet rutrum faucibus.
                                            </PopoverBody>
                                        </Popover>
                                        <Button
                                            id="Popoverbottom"
                                            onClick={() => {
                                                setpopoverbottom(!popoverbottom);
                                            }}
                                            color="secondary"
                                        >
                                            Popover on bottom
                                        </Button>
                                        <Popover
                                            placement="bottom"
                                            isOpen={popoverbottom}
                                            target="Popoverbottom"
                                            toggle={() => {
                                                setpopoverbottom(!popoverbottom);
                                            }}
                                        >
                                            <PopoverBody>
                                                Vivamus sagittis lacus vel augue laoreet rutrum faucibus.
                                            </PopoverBody>
                                        </Popover>
                                        <Button
                                            id="Popoverleft"
                                            onClick={() => {
                                                setpopoverleft(!popoverleft);
                                            }}
                                            color="secondary"
                                        >
                                            Popover on left
                                        </Button>
                                        <Popover
                                            placement="left"
                                            isOpen={popoverleft}
                                            target="Popoverleft"
                                            toggle={() => {
                                                setpopoverleft(!popoverleft);
                                            }}
                                        >
                                            <PopoverHeader>Popover Title</PopoverHeader>
                                            <PopoverBody>
                                                Vivamus sagittis lacus vel augue laoreet rutrum faucibus
                                            </PopoverBody>
                                        </Popover>
                                        <Button
                                            id="Popoverdismiss"
                                            className="btn"
                                            color="success"
                                            onClick={() => {
                                                setpopoverdismiss(!popoverdismiss);
                                            }}
                                        >
                                            Dismissible popover
                                        </Button>
                                        <UncontrolledPopover
                                            trigger="focus"
                                            target="Popoverdismiss"
                                            placement="right"
                                        >
                                            <PopoverHeader>Dismissible popover</PopoverHeader>
                                            <PopoverBody>
                                                And here's some amazing content. It's very engaging. Right?
                                            </PopoverBody>
                                        </UncontrolledPopover>

                                    </div>

                                </CardBody>
                            </Card>

                        </Col>

                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Tooltips</h4>
                                </CardHeader>
                                <CardBody>

                                    <Tooltip
                                        placement="top"
                                        isOpen={ttop}
                                        target="TooltipTop"
                                        toggle={() => {
                                            setttop(!ttop);
                                        }}
                                    >
                                        Tooltip on top
                                    </Tooltip>
                                    <Tooltip
                                        placement="right"
                                        isOpen={tright}
                                        target="TooltipRight"
                                        toggle={() => {
                                            settright(!tright);
                                        }}
                                    >
                                        Tooltip on Right
                                    </Tooltip>
                                    <Tooltip
                                        placement="bottom"
                                        isOpen={tbottom}
                                        target="TooltipBottom"
                                        toggle={() => {
                                            settbottom(!tbottom);
                                        }}
                                    >
                                        Tooltip on Bottom
                                    </Tooltip>
                                    <Tooltip
                                        placement="left"
                                        isOpen={tleft}
                                        target="TooltipLeft"
                                        toggle={() => {
                                            settleft(!tleft);
                                        }}
                                    >
                                        Tooltip on Left
                                    </Tooltip>


                                    <p className="card-title-desc">Hover over the links below to see tooltips:</p>

                                    <div className="d-flex flex-wrap gap-2">
                                        <button type="button" className="btn btn-primary" id='TooltipTop' title="Tooltip on top">
                                            Tooltip on top
                                        </button>

                                        <button type="button" className="btn btn-primary" id='TooltipRight' title="Tooltip on right">
                                            Tooltip on right
                                        </button>

                                        <button type="button" className="btn btn-primary" id='TooltipBottom' title="Tooltip on bottom">
                                            Tooltip on bottom
                                        </button>

                                        <button type="button" className="btn btn-primary" id='TooltipLeft' title="Tooltip on left">
                                            Tooltip on left
                                        </button>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>

                                    <h4 className="card-title">Pagination</h4>
                                </CardHeader>
                                <CardBody>

                                    <Row>
                                        <Col xl={6}>
                                            <h5 className="font-size-14">Default Example</h5>
                                            <p className="card-title-desc">Pagination links indicate a series of related content exists across multiple pages.</p>

                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className="page-item"><Link className="page-link" to="#">Previous</Link></li>
                                                    <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                                                    <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                                                    <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                                                    <li className="page-item"><Link className="page-link" to="#">Next</Link></li>
                                                </ul>
                                            </nav>

                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className="page-item">
                                                        <Link className="page-link" to="#" aria-label="Previous">
                                                            <i className="mdi mdi-chevron-left"></i>
                                                        </Link>
                                                    </li>
                                                    <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                                                    <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                                                    <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                                                    <li className="page-item">
                                                        <Link className="page-link" to="#" aria-label="Next">
                                                            <i className="mdi mdi-chevron-right"></i>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </nav>

                                        </Col>

                                        <Col xl={6}>
                                            <div className="mt-4 mt-lg-0">
                                                <h5 className="font-size-14">Disabled and active states</h5>
                                                <p className="card-title-desc">Pagination links are customizable for
                                                    different circumstances. Use <code>.disabled</code> for links that appear
                                                    un-clickable and <code>.active</code> to
                                                    indicate the current page.</p>


                                                <nav aria-label="...">
                                                    <ul className="pagination">
                                                        <li className="page-item disabled">
                                                            <Link className="page-link" to="#" tabIndex="-1">Previous</Link>
                                                        </li>
                                                        <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                                                        <li className="page-item active">
                                                            <Link className="page-link" to="#">2 <span className="sr-only">(current)</span></Link>
                                                        </li>
                                                        <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                                                        <li className="page-item">
                                                            <Link className="page-link" to="#">Next</Link>
                                                        </li>
                                                    </ul>
                                                </nav>

                                                <nav aria-label="...">
                                                    <ul className="pagination">
                                                        <li className="page-item disabled">
                                                            <span className="page-link"><i className="mdi mdi-chevron-left"></i></span>
                                                        </li>
                                                        <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                                                        <li className="page-item active">
                                                            <span className="page-link">
                                                                2
                                                                <span className="sr-only">(current)</span>
                                                            </span>
                                                        </li>
                                                        <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                                                        <li className="page-item">
                                                            <Link className="page-link" to="#"><i className="mdi mdi-chevron-right"></i></Link>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xl={6}>
                                            <div className="mt-4">
                                                <h5 className="font-size-14">Sizing</h5>
                                                <p className="card-title-desc">Fancy larger or smaller pagination? Add <code>.pagination-lg</code> or <code>.pagination-sm</code> for additional
                                                    sizes.</p>

                                                <nav aria-label="...">
                                                    <ul className="pagination pagination-lg">
                                                        <li className="page-item disabled">
                                                            <Link className="page-link" to="#" tabIndex="-1">Previous</Link>
                                                        </li>
                                                        <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                                                        <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                                                        <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                                                        <li className="page-item">
                                                            <Link className="page-link" to="#">Next</Link>
                                                        </li>
                                                    </ul>
                                                </nav>

                                                <nav aria-label="...">
                                                    <ul className="pagination pagination-sm">
                                                        <li className="page-item disabled">
                                                            <Link className="page-link" to="#" tabIndex="-1">Previous</Link>
                                                        </li>
                                                        <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                                                        <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                                                        <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                                                        <li className="page-item">
                                                            <Link className="page-link" to="#">Next</Link>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>

                                        </Col>

                                        <Col xl={6}>
                                            <div className="mt-4">
                                                <h5 className="font-size-14">Alignment</h5>
                                                <p className="card-title-desc">Change the alignment of pagination
                                                    components with flexbox utilities.</p>

                                                <nav aria-label="Page navigation example">
                                                    <ul className="pagination justify-content-center">
                                                        <li className="page-item disabled">
                                                            <Link className="page-link" to="#" tabIndex="-1">Previous</Link>
                                                        </li>
                                                        <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                                                        <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                                                        <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                                                        <li className="page-item">
                                                            <Link className="page-link" to="#">Next</Link>
                                                        </li>
                                                    </ul>
                                                </nav>

                                                <nav aria-label="Page navigation example">
                                                    <ul className="pagination justify-content-end">
                                                        <li className="page-item disabled">
                                                            <Link className="page-link" to="#" tabIndex="-1">Previous</Link>
                                                        </li>
                                                        <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                                                        <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                                                        <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                                                        <li className="page-item">
                                                            <Link className="page-link" to="#">Next</Link>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Border spinner</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Use the border spinners for a lightweight loading indicator.</p>
                                    <div>
                                        <div className="spinner-border text-primary m-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <div className="spinner-border text-secondary m-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <div className="spinner-border text-success m-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <div className="spinner-border text-info m-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <div className="spinner-border text-warning m-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <div className="spinner-border text-danger m-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <div className="spinner-border text-dark m-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Growing spinner</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">If you don’t fancy a border spinner, switch to the grow spinner. While it doesn’t technically spin, it does repeatedly grow!</p>
                                    <div>
                                        <div className="spinner-grow text-primary m-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <div className="spinner-grow text-secondary m-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <div className="spinner-grow text-success m-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <div className="spinner-grow text-info m-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <div className="spinner-grow text-warning m-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <div className="spinner-grow text-danger m-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <div className="spinner-grow text-dark m-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UiGeneral;
