import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import ClipLoader from "react-spinners/ClipLoader";
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';

// redux
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import ModalDelete from '../../components/Modals/ModalDelete';
import Paginate from '../../components/Paginate/Paginate';
import { APIClient } from '../../helpers/api_helper';
import { setBreadcrumb } from "../../store/actions";
import ModalUserManagement from './Components/ModalUserManagement';

const UserPage = () => {
    const [data, setData] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [identifier, setIdentifier] = useState('');

    const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
    const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);

    const [isOpenModalUserManagement, setIsOpenModalUserManagement] = useState(false);

    const [action, setAction] = useState(1);
    const [meta, setMeta] = useState({
        current_page: 1,
    });

    const dispatch = useDispatch();

    const api = new APIClient();

    function params() {
        return `?page=${meta.current_page > 0 ? meta.current_page : 1}`;
    };

    const loadData = () => {
        setIsLoadingData(true);

        api
            .get(`/adm/user/admin/list${params()}`, {
                headers: {
                    Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                },
            })
            .then((response) => {
                setData(response.data);
                setMeta(response.meta);
                setIsLoadingData(false);
            })
            .catch((error) => {
                setIsLoadingData(false);
            })
        ;
    };

    const deleteAction = (identifier = '') => {
        setIsLoadingDeleteData(true);

        api
            .delete(`/adm/user/${identifier}/remove`, {
                headers: {
                    Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                },
            })
            .then((response) => {
                toast.success("Usuário removido com sucesso")
                setIsLoadingDeleteData(false);
                loadData();
                actionModalDelete();
            })
            .catch((error) => {
                toast.error("Problemas ao remover usuário, tente novamente")
                setIsLoadingDeleteData(false);
            })
        ;
    }

    function actionModalDelete(identifier = '') {
        setIsOpenModalDelete(!isOpenModalDelete);
        setIdentifier(identifier);
    }

    function actionModalUserManagement(identifier = '') {
        setIsOpenModalUserManagement(!isOpenModalUserManagement);
        setIdentifier(identifier);
    }

    useEffect(() => {
        const breadCrumbItems = {
            title: "Usuários",
        };

        dispatch(setBreadcrumb(breadCrumbItems));
        loadData();
    }, [dispatch, action]);

    return (
        <React.Fragment>
            <ModalUserManagement
                identifier={identifier}
                isOpen={isOpenModalUserManagement}
                onCloseClick={() => actionModalUserManagement()}
                centered={true}
                loadData={loadData}
            />
            <ModalDelete
                show={isOpenModalDelete}
                onDeleteClick={() => deleteAction(identifier)}
                onCloseClick={() => actionModalDelete()}
                centered={true}
            />
             <MetaTags>
                <title>Usuários | Painel Gomus</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xxl={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Listagem de usuários</h4>
                                </CardHeader>
                                <div className="text-center p-3">
                                    <button
                                        style={{ float: 'right' }}
                                        type="button"
                                        onClick={actionModalUserManagement}
                                        className="btn btn-success"
                                        data-bs-toggle="modal"
                                        data-bs-target="#success-btn"
                                        id="btn-save-event"
                                    >
                                        <i className="bx bx-add me-1"></i>
                                        Novo usuário
                                    </button>
                                </div>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Nome</th>
                                                    <th>E-mail</th>
                                                    <th>Tipo de perfil</th>
                                                    <th>Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoadingData ? (
                                                    <td colSpan={4} className="text-center">
                                                        <ClipLoader size={13} loading={isLoadingData} />
                                                    </td>
                                                ): (
                                                    <>
                                                        {data.map((entity, key) => (
                                                            <tr key={key}>
                                                                <td>{entity.name ?? '-'}</td>
                                                                <td>{entity.username ?? '-'}</td>
                                                                <td>{entity.roleHumanReadable ?? '-'}</td>
                                                                <td>
                                                                    <div className="d-flex gap-3">
                                                                        <button
                                                                            onClick={() => actionModalUserManagement(entity?.identifier)}
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title="Editar usuário"
                                                                            className="text-success"
                                                                            data-bs-original-title="Edit"
                                                                            aria-label="Edit"
                                                                            style={{ border: 'none', background: 'none'}}
                                                                        >
                                                                            <i className="mdi mdi-pencil font-size-18"></i>
                                                                        </button>
                                                                        {entity?.identifier !== window.localStorage.getItem("profile-identifier") && (
                                                                            <button
                                                                                onClick={() => actionModalDelete(entity?.identifier)}
                                                                                data-bs-toggle="tooltip"
                                                                                data-bs-placement="top"
                                                                                title="Excluir usuário"
                                                                                className="text-danger"
                                                                                data-bs-original-title="Delete"
                                                                                aria-label="Delete"
                                                                                style={{ border: 'none', background: 'none'}}
                                                                            >
                                                                                <i className="mdi mdi-delete font-size-18"></i>
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                        {meta.total_pages > 1 && (
                                            <Paginate
                                                meta={meta}
                                                setMeta={setMeta}
                                                action={action}
                                                setAction={setAction}
                                                showDetails={true}
                                            />
                                        )}
                                        {data.length === 0 && <div className="text-center mt-4">Nenhum dado encontrado</div>}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UserPage;
