import React, { useEffect } from 'react';
import { Route, Switch } from "react-router-dom";

import jwt_decode from 'jwt-decode';

// redux
import { useSelector } from "react-redux";

//constants
import { layoutTypes } from "../components/constants/layout";

// layouts
import HorizontalLayout from "../Layout/HorizontalLayout/Index";
import NonAuthLayout from "../Layout/NonAuthLayout";
import VerticalLayout from "../Layout/VerticalLayout/Index";
import { AccessRoute } from "./AuthProtected";

import { useHistory, useLocation } from 'react-router-dom'; // Importa os hooks do React Router
import { authProtectedRoutes, publicRoutes } from "./allRoutes";

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

// const currentRoute = window.location.pathname;
// console.log("debug", currentRoute, authProtectedRoutes, publicRoutes);

const Index = () => {
  const history = useHistory(); // Hook para gerenciar histórico de navegação
  const location = useLocation(); // Hook para obter a localização atual

  const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
  const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path);

  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));

  const Layout = getLayout(layoutType);

  useEffect(() => {
    let currentRoute = location.pathname;
    let shouldBeLoggedIn = false;
    let shouldRedirectToLogin = false;

    for (let key in authProtectedRoutes) {
      if (authProtectedRoutes[key].path === currentRoute) {
        shouldBeLoggedIn = true;
        break;
      }
    }

    if (shouldBeLoggedIn) {
      let token = window.localStorage.getItem('session-token');
      if (token && token.length > 0) {
        let decoded = jwt_decode(token);
        let dateToExpires = new Date(decoded.exp * 1000);

        if (Date.now() > dateToExpires) {
          shouldRedirectToLogin = true; // Token expirado
        }
      } else {
        shouldRedirectToLogin = true; // Sem token
      }
    }

    if (shouldRedirectToLogin) {
      window.localStorage.clear();
      history.push('/login'); // Use history.push para navegar para a página de login
    }
  }, [location.pathname, history]); // Dependências corretas

  return (
    <Switch>
      <Route path={availablePublicRoutesPaths}>
        <NonAuthLayout>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Route
                path={route.path}
                component={route.component}
                key={idx}
                exact={true}
              />
            ))}
          </Switch>
        </NonAuthLayout>
      </Route>

      <Route path={availableAuthRoutesPath}>
        <Layout>
          <Switch>
            {authProtectedRoutes.map((route, idx) => (
              <AccessRoute
                path={route.path}
                component={route.component}
                key={idx}
                exact={true}
              />
            ))}
          </Switch>
        </Layout>
      </Route>
    </Switch>
  );
};

export default Index;
