import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Form, Row, CardHeader } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import Dropzone from "react-dropzone";
import { Link } from 'react-router-dom';

const CreateNew = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Create New",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [selectedFiles, setselectedFiles] = useState([])

    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        setselectedFiles(files)
    }

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }


    return (
        <React.Fragment>
            <MetaTags>
                <title>Create New | Painel Gomus</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Create New Project</h4>
                                </CardHeader>
                                <CardBody>

                                    <form>
                                        <Row className="mb-4">
                                            <label htmlFor="projectname" className="col-form-label col-lg-2">Project Name</label>
                                            <Col lg={10}>
                                                <input id="projectname" name="projectname" type="text" className="form-control" placeholder="Enter Project Name..." />
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <label htmlFor="projectdesc" className="col-form-label col-lg-2">Project Description</label>
                                            <Col lg={10}>
                                                <textarea className="form-control" id="projectdesc" rows="3" placeholder="Enter Project Description..."></textarea>
                                            </Col>
                                        </Row>

                                        <Row className="mb-4">
                                            <label className="col-form-label col-lg-2">Project Date</label>
                                            <Col lg={10}>
                                                <div className="input-daterange input-group" id="project-date-inputgroup" data-provide="datepicker" data-date-format="dd M, yyyy" data-date-container='#project-date-inputgroup' data-date-autoclose="true">
                                                    <input type="text" className="form-control" id="datepicker-datetime-start" placeholder="Start Date" name="start" />
                                                    <input type="text" className="form-control" id="datepicker-datetime-end" placeholder="End Date" name="end" />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-4">
                                            <label htmlFor="projectbudget" className="col-form-label col-lg-2">Budget</label>
                                            <Col lg={10}>
                                                <input id="projectbudget" name="projectbudget" type="text" placeholder="Enter Project Budget..." className="form-control" />
                                            </Col>
                                        </Row>
                                    </form>
                                    <Row className="mb-4">
                                        <label className="col-form-label col-lg-2">Attached Files</label>
                                        <Col lg={10}>


                                            <Form
                                                className="dropzone"
                                            >
                                                <Dropzone
                                                    onDrop={acceptedFiles => {
                                                        handleAcceptedFiles(acceptedFiles)
                                                    }}
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div style={{ textAlign: "center" }}>
                                                            <div
                                                                className="dz-message needsclick"
                                                                {...getRootProps()}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <div className="mb-3 mt-5">
                                                                    <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                                                </div>
                                                                <h4>Drop files here or click to upload.</h4>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                <div className="dropzone-previews mt-3" id="file-previews">
                                                    {selectedFiles.map((f, i) => {
                                                        return (
                                                            <Card
                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                key={i + "-file"}
                                                            >
                                                                <div className="p-2">
                                                                    <Row className="align-items-center">
                                                                        <Col className="col-auto">
                                                                            <img
                                                                                data-dz-thumbnail=""
                                                                                height="80"
                                                                                className="avatar-sm rounded bg-light"
                                                                                alt={f.name}
                                                                                src={f.preview}
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <Link
                                                                                to="#"
                                                                                className="text-muted font-weight-bold"
                                                                            >
                                                                                {f.name}
                                                                            </Link>
                                                                            <p className="mb-0">
                                                                                <strong>{f.formattedSize}</strong>
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Card>
                                                        )
                                                    })}
                                                </div>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-end">
                                        <Col lg={10}>
                                            <button type="submit" className="btn btn-primary">Create Project</button>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CreateNew;

