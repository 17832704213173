import React from 'react';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';

import RecentFiles from "./RecentFiles";

//import images
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";

const MyFiles = () => {
    return (
        <React.Fragment>
            <Row className="mb-3">
                <Col lg={4} sm={6}>
                    <div className="search-box mb-2 me-2">
                        <div className="position-relative">
                            <input type="text" className="form-control bg-light border-light rounded" placeholder="Search..." />
                            <i className="search-icon" data-eva="search-outline" data-eva-height="26" data-eva-width="26"></i>
                        </div>
                    </div>
                </Col>
                <Col lg={8} sm={6}>
                    <div className="mt-4 mt-sm-0 d-flex align-items-center justify-content-sm-end">

                        <div className="mb-2 me-2">
                            <UncontrolledDropdown>
                                <DropdownToggle className="btn btn-primary" type="button" tag="a" to="#">
                                    <i className="mdi mdi-plus me-1"></i> Create New
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem><i className="mdi mdi-folder-outline me-1"></i> Folder</DropdownItem>
                                    <DropdownItem><i className="mdi mdi-file-outline me-1"></i> File</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>

                        <UncontrolledDropdown className="mb-0">
                            <DropdownToggle className="btn btn-link text-muted p-1 mt-n2" type="button" tag="a" to="#">
                                <i className="mdi mdi-dots-vertical font-size-20"></i>
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem>Share Files</DropdownItem>
                                <DropdownItem>Share with me</DropdownItem>
                                <DropdownItem>Other Actions</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </Col>
            </Row>
            <h5 className="font-size-16 me-3 mb-0">My Files</h5>
            <div className="row mt-4">
                <div className="col-xl-4 col-sm-6">
                    <div className="card shadow-none border">
                        <div className="card-body p-3">
                            <div className="">
                                <UncontrolledDropdown className="float-end">
                                    <DropdownToggle className="text-muted font-size-16" type="button" tag="a" to="#">
                                        <i className="bx bx-dots-vertical-rounded font-size-20"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Edit</DropdownItem>
                                        <DropdownItem>Action</DropdownItem>
                                        <DropdownItem>Remove</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <div className="d-flex align-items-center">
                                    <div className="avatar align-self-center me-3">
                                        <div className="avatar-title rounded bg-soft-primary text-primary font-size-24">
                                            <i className="mdi mdi-google-drive"></i>
                                        </div>
                                    </div>

                                    <div className="flex-1">
                                        <h5 className="font-size-15 mb-1">Google Drive</h5>
                                        <Link to="#" className="font-size-13 text-muted"><u>View Folder</u></Link>
                                    </div>
                                </div>
                                <div className="mt-3 pt-1">
                                    <div className="d-flex justify-content-between">
                                        <p className="text-muted font-size-13 mb-1">20GB</p>
                                        <p className="text-muted font-size-13 mb-1">50GB used</p>
                                    </div>
                                    <div className="progress animated-progess custom-progress">
                                        <div className="progress-bar bg-gradient bg-primary" role="progressbar" style={{ width: "90%" }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="90">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-sm-6">
                    <div className="card shadow-none border">
                        <div className="card-body p-3">
                            <div className="">
                                <UncontrolledDropdown className="float-end">
                                    <DropdownToggle className="text-muted font-size-16" type="button" tag="a" to="#">
                                        <i className="bx bx-dots-vertical-rounded font-size-20"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Edit</DropdownItem>
                                        <DropdownItem>Action</DropdownItem>
                                        <DropdownItem>Remove</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <div className="d-flex align-items-center">
                                    <div className="avatar align-self-center me-3">
                                        <div className="avatar-title rounded bg-soft-info text-info font-size-24">
                                            <i className="mdi mdi-dropbox"></i>
                                        </div>
                                    </div>

                                    <div className="flex-1">
                                        <h5 className="font-size-15 mb-1">Dropbox</h5>
                                        <Link to="#" className="font-size-13 text-muted"><u>View Folder</u></Link>
                                    </div>

                                </div>
                                <div className="mt-3 pt-1">
                                    <div className="d-flex justify-content-between">
                                        <p className="text-muted font-size-13 mb-1">20GB</p>
                                        <p className="text-muted font-size-13 mb-1">50GB used</p>
                                    </div>
                                    <div className="progress animated-progess custom-progress">
                                        <div className="progress-bar bg-gradient bg-info" role="progressbar" style={{ width: "90%" }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="90">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-sm-6">
                    <div className="card shadow-none border">
                        <div className="card-body p-3">
                            <div className="">
                                <UncontrolledDropdown className="float-end">
                                    <DropdownToggle className="text-muted font-size-16" type="button" tag="a" to="#">
                                        <i className="bx bx-dots-vertical-rounded font-size-20"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Edit</DropdownItem>
                                        <DropdownItem>Action</DropdownItem>
                                        <DropdownItem>Remove</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <div className="d-flex align-items-center">
                                    <div className="avatar align-self-center me-3">
                                        <div className="avatar-title rounded bg-soft-primary text-primary font-size-24">
                                            <i className="mdi mdi-apple-icloud"></i>
                                        </div>
                                    </div>

                                    <div className="flex-1">
                                        <h5 className="font-size-15 mb-1">One Drive</h5>
                                        <Link to="#" className="font-size-13 text-muted"><u>View Folder</u></Link>
                                    </div>

                                </div>
                                <div className="mt-3 pt-1">
                                    <div className="d-flex justify-content-between">
                                        <p className="text-muted font-size-13 mb-1">20GB</p>
                                        <p className="text-muted font-size-13 mb-1">50GB used</p>
                                    </div>
                                    <div className="progress animated-progess custom-progress">
                                        <div className="progress-bar bg-gradient bg-primary" role="progressbar" style={{ width: "90%" }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="90">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h5 className="font-size-16 me-3 mb-0">Folders</h5>
            <div className="row mt-4">
                <div className="col-xl-4 col-sm-6">
                    <div className="card shadow-none border">
                        <div className="card-body p-3">
                            <div className="">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <i className="bx bxs-folder h1 mb-0 text-warning"></i>
                                    </div>
                                    <div className="avatar-group">
                                        <div className="avatar-group-item">
                                            <Link to="#" className="d-inline-block">
                                                <img src={avatar4} alt="" className="rounded-circle avatar-sm" />
                                            </Link>
                                        </div>
                                        <div className="avatar-group-item">
                                            <Link to="#" className="d-inline-block">
                                                <img src={avatar5} alt="" className="rounded-circle avatar-sm" />
                                            </Link>
                                        </div>
                                        <div className="avatar-group-item">
                                            <Link to="#" className="d-inline-block">
                                                <div className="avatar-sm">
                                                    <span className="avatar-title rounded-circle bg-success text-white font-size-16">
                                                        A
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mt-3">
                                    <div className="overflow-hidden me-auto">
                                        <h5 className="font-size-15 text-truncate mb-1"><Link to="#" className="text-body">Analytics</Link></h5>
                                        <p className="text-muted text-truncate mb-0">12 Files</p>
                                    </div>
                                    <div className="align-self-end ms-2">
                                        <p className="text-muted mb-0 font-size-13"><i className="mdi mdi-clock"></i> 15 min ago</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-sm-6">
                    <div className="card shadow-none border">
                        <div className="card-body p-3">
                            <div className="">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <i className="bx bxs-folder h1 mb-0 text-warning"></i>
                                    </div>
                                    <div className="avatar-group">
                                        <div className="avatar-group-item">
                                            <Link to="#" className="d-inline-block">
                                                <img src={avatar3} alt="" className="rounded-circle avatar-sm" />
                                            </Link>
                                        </div>
                                        <div className="avatar-group-item">
                                            <Link to="#" className="d-inline-block">
                                                <img src={avatar6} alt="" className="rounded-circle avatar-sm" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mt-3">
                                    <div className="overflow-hidden me-auto">
                                        <h5 className="font-size-15 text-truncate mb-1"><Link to="#" className="text-body">Sketch Design</Link></h5>
                                        <p className="text-muted text-truncate mb-0">235 Files</p>
                                    </div>
                                    <div className="align-self-end ms-2">
                                        <p className="text-muted mb-0 font-size-13"><i className="mdi mdi-clock"></i> 23 min ago</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-sm-6">
                    <div className="card shadow-none border">
                        <div className="card-body p-3">
                            <div className="">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <i className="bx bxs-folder h1 mb-0 text-warning"></i>
                                    </div>
                                    <div className="avatar-group">
                                        <div className="avatar-group-item">
                                            <Link to="#" className="d-inline-block">
                                                <div className="avatar-sm">
                                                    <span className="avatar-title rounded-circle bg-info text-white font-size-16">
                                                        K
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="avatar-group-item">
                                            <Link to="#" className="d-inline-block">
                                                <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mt-3">
                                    <div className="overflow-hidden me-auto">
                                        <h5 className="font-size-15 text-truncate mb-1"><Link to="#" className="text-body">Applications</Link></h5>
                                        <p className="text-muted text-truncate mb-0">20 Files</p>
                                    </div>
                                    <div className="align-self-end ms-2">
                                        <p className="text-muted mb-0 font-size-13"><i className="mdi mdi-clock"></i> 45 min ago</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <RecentFiles />
        </React.Fragment>
    );
};

export default MyFiles;