import PropTypes from "prop-types";
import React from "react";

const ButtonPaginate = ({ children, type, className, disabled, onClick }) => {
    const buttonStyles = {
        backgroundColor: disabled ? "#f1f3f7" : "#040622",
        color: disabled ? "black" : "#f1f3f7",
        fontSize: "16px",
        fontWeight: "normal",
        padding: "8px 16px",
        borderRadius: "4px",
        marginLeft: "4px", // Ajustando as margens para 4px
        marginRight: "4px", // Ajustando as margens para 4px
        paddingLeft: "8px",
        paddingRight: "8px",
        height: "32px",
        cursor: "pointer",
        outline: "none",
        alignItems: "center",
    };

    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={className}
            style={buttonStyles}
            type={type ? type : "button"}>
            {children}
        </button>
    );
};

ButtonPaginate.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ButtonPaginate;
