import { ErrorMessage, Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row } from "reactstrap";
import { APIClient } from '../../../helpers/api_helper';
import { userInitialValues } from '../helpers/userInitialValues';
import { userValidationSchema } from '../helpers/userValidationSchema';

const RegistrationData = ({
    entity = {},
    onCloseClick = () => {},
    loadData = () => {}
}) => {
    const api = new APIClient();

    const saveRegistrationData = (values, setSubmitting) => {
        setSubmitting(true);

        let route = `/adm/user/${entity.identifier}/edit`;

        let aux = {
            name: values.name,
            email: values.username,
        };

        if (!entity.identifier) {
            route = '/adm/user/admin/new';

            aux = {
                ...aux,
                password: values.password
            }
        }

        api
            .post(route, aux)
            .then((response) => {
                toast.success(`Usuário ${!entity.identifier ? "cadastrado" : "editado" } com sucesso`)
                setSubmitting(false);
                onCloseClick();
                loadData();
            })
            .catch((error) => {
                toast.error(`Problemas ao ${!entity.identifier ? "cadastrar" : "editar" } usuário, tente novamente`)
                setSubmitting(false);
            })
        ;
    }

    return (
        <Formik
            initialValues={userInitialValues(entity)}
            validationSchema={userValidationSchema(entity)}
            onSubmit={(values, { resetForm, setValues, setFieldError, setSubmitting }) => {
                saveRegistrationData(values, setSubmitting);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                isSubmitting,
            }) => (
                <form className="mt-4 pt-2" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="name">Nome completo</label>
                                <Field
                                    type="text"
                                    id="name"
                                    name="name"
                                    className="form-control"
                                    placeholder="Preencha o nome completo"
                                    value={values.name}
                                />
                                <ErrorMessage
                                    component="p"
                                    className="text-red text-xs"
                                    name="name"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="username">E-mail</label>
                                <Field
                                    type="email"
                                    id="username"
                                    name="username"
                                    className="form-control"
                                    placeholder="Preencha o e-mail"
                                    value={values.username}
                                />
                                <ErrorMessage
                                    component="p"
                                    className="text-red text-xs"
                                    name="username"
                                />
                            </div>
                        </div>
                        {!entity?.identifier && (
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="password">Senha</label>
                                    <Field
                                        type="password"
                                        id="password"
                                        name="password"
                                        className="form-control"
                                        placeholder="Preencha a senha"
                                        value={values.password}
                                    />
                                    <ErrorMessage
                                        component="p"
                                        className="text-red text-xs"
                                        name="password"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <Row className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    onCloseClick();
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Cancelar e fechar
                            </button>
                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                                disabled={isSubmitting}
                            >
                                <i className="bx bx-check me-1"></i>
                                {isSubmitting ? (
                                    <>Salvando</>
                                ): (
                                    <>Salvar</>
                                )}
                            </button>
                        </div>
                    </Row>
                </form>
            )}
        </Formik>
    );
};

RegistrationData.propTypes = {
    entity: PropTypes.object,
    onCloseClick: PropTypes.func,
    loadData: PropTypes.func,
};

export default RegistrationData;
