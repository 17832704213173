import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, UncontrolledDropdown } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import { Link } from 'react-router-dom';

const UiOffcanvas = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Offcanvas",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);


    const [open, setOpen] = useState(false);
    const [isRight, setIsRight] = useState(false);
    const [isBottom, setIsBottom] = useState(false);
    const [isEnableScroll, setIsEnableScroll] = useState(false);
    const [isBackdrop, setIsBackdrop] = useState(false);
    const [isScrollBackDrop, setIsScrollBackDrop] = useState(false);

    const toggleLeftCanvas = () => {
        setOpen(!open);
    };

    
    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };
    const toggleBottomCanvas = () => {
        setIsBottom(!isBottom);
    };

    const toggleEnableScroll = () => {
        setIsEnableScroll(!isEnableScroll);
    };
    const toggleBackdrop = () => {
        setIsBackdrop(!isBackdrop);
    };
    const toggleScrollBackDrop = () => {
        setIsScrollBackDrop(!isScrollBackDrop);
    };


    return (
        <React.Fragment>
            <MetaTags>
                <title>Offcanvas | Painel Gomus</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title">Demo</h5>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Use the buttons below to show and hide an offcanvas element via JavaScript that toggles the <code>.show</code> class on an element with the <code>.offcanvas</code> class.</p>

                                    <div className="d-flex flex-wrap gap-2">
                                        <Link className="btn btn-primary" onClick={toggleLeftCanvas} data-bs-toggle="offcanvas" to="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                                            Link with href
                                        </Link>
                                        <button className="btn btn-primary" onClick={toggleLeftCanvas} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                                            Button with data-bs-target
                                        </button>
                                    </div>


                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title">Placement</h5>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Offcanvas Diffrent Placement Example: Left, Right & Bottom</p>

                                    <div className="d-flex flex-wrap gap-2">
                                        <button className="btn btn-primary" onClick={toggleRightCanvas} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Toggle right offcanvas</button>

                                        <button className="btn btn-primary" onClick={toggleBottomCanvas} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">Toggle bottom offcanvas</button>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title">Backdrop</h5>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Scrolling the <code>&lt;body&gt;</code> element is disabled when an offcanvas and its backdrop are visible. Use the <code>data-bs-scroll</code> attribute to toggle <code>&lt;body&gt;</code> scrolling and <code>data-bs-backdrop</code> to toggle the backdrop.</p>

                                    <div className="d-flex flex-wrap gap-2">
                                        <button className="btn btn-primary" onClick={toggleEnableScroll} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">Enable body scrolling</button>
                                        <button className="btn btn-primary" onClick={toggleBackdrop} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop">Enable backdrop (default)</button>
                                        <button className="btn btn-primary" onClick={toggleScrollBackDrop} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">Enable both scrolling & backdrop</button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Demo Offcanvas */}
            <Offcanvas isOpen={open} toggle={toggleLeftCanvas} id="offcanvasExample">
                <OffcanvasHeader toggle={toggleLeftCanvas} id="offcanvasExampleLabel">
                    Offcanvas
                </OffcanvasHeader>
                <OffcanvasBody>
                    <div>
                        Some text as placeholder. In real life you can have the elements you have chosen. Like, text, images, lists, etc.
                    </div>
                    <UncontrolledDropdown className="mt-3" id="dropdownMenuButton">
                        <DropdownToggle className="btn btn-primary" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown">
                            Dropdown button <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <li><DropdownItem>Action</DropdownItem></li>
                            <li><DropdownItem>Another action</DropdownItem></li>
                            <li><DropdownItem>Something else here</DropdownItem></li>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </OffcanvasBody>
            </Offcanvas>

            {/* Right offcanvas */}
            <Offcanvas
                isOpen={isRight}
                direction="end"
                toggle={toggleRightCanvas}
                id="offcanvasRight"
            >
                <OffcanvasHeader toggle={toggleRightCanvas} id="offcanvasRightLabel">
                    Offcanvas Right
                </OffcanvasHeader>
                <OffcanvasBody>
                    ...
                </OffcanvasBody>
            </Offcanvas>

            {/* Bottom offcanvas */}
            <Offcanvas
                isOpen={isBottom}
                direction="bottom"
                toggle={toggleBottomCanvas}
                id="offcanvasBottom"
            >
                <OffcanvasHeader toggle={toggleBottomCanvas} id="offcanvasBottomLabel">
                    Offcanvas Bottom
                </OffcanvasHeader>
                <OffcanvasBody>
                    ...
                </OffcanvasBody>
            </Offcanvas>

            {/* Enable body scrolling */}
            <Offcanvas
                isOpen={isEnableScroll}
                scrollable
                backdrop={false}
                toggle={toggleEnableScroll}
                id="offcanvasScrolling"
            >
                <OffcanvasHeader toggle={toggleEnableScroll}
                    id="offcanvasScrollingLabel"
                >
                    Colored with scrolling
                </OffcanvasHeader>
                <OffcanvasBody>
                    <p>Try scrolling the rest of the page to see this option in action.</p>
                </OffcanvasBody>
            </Offcanvas>

            {/* Enable backdrop (default) */}
            <Offcanvas
                isOpen={isBackdrop}
                toggle={toggleBackdrop}
                id="offcanvasWithBackdrop"
            >
                <OffcanvasHeader toggle={toggleBackdrop} id="offcanvasWithBackdropLabel">
                    Offcanvas with backdrop
                </OffcanvasHeader>
                <OffcanvasBody>
                    <p>.....</p>
                </OffcanvasBody>
            </Offcanvas>

            {/* Enable both scrolling & backdrop */}
            <Offcanvas
                isOpen={isScrollBackDrop}
                scrollable
                toggle={toggleScrollBackDrop}
                id="offcanvasWithBothOptions"
            >
                <OffcanvasHeader toggle={toggleScrollBackDrop} id="offcanvasWithBothOptionsLabel">
                    Backdroped with scrolling
                </OffcanvasHeader>
                <OffcanvasBody>
                    <p>Try scrolling the rest of the page to see this option in action.</p>
                </OffcanvasBody>
            </Offcanvas>
        </React.Fragment>
    );
};

export default UiOffcanvas;
