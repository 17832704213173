import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

//import Charts
import {
    ChartSparkline1,
    ChartSparkline2,
    ChartSparkline3,
    ChartSparkline4
} from './ChartSparkline';

const Widgets = () => {
    return (
        <React.Fragment>
            <Row>
                <Col sm={6}>
                    <Card>
                        <CardBody>
                            <div>
                                <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                        <div className="avatar">
                                            <div className="avatar-title rounded bg-primary bg-gradient">
                                                <i data-eva="shopping-bag" className="fill-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <div className="text-muted"><i data-eva="diagonal-arrow-right-up" data-eva-width="18" data-eva-height="18" className="align-text-bottom fill-success me-1"></i> 1.02%</div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div className="d-flex">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <h4 className="text-truncate mb-1">$34,123</h4>
                                            <p className="text-truncate text-muted mb-0">Total Sales</p>
                                        </div>
                                        <div className="flex-shrink-0 align-self-center">
                                            <div id="chart-sparkline1">
                                                <ChartSparkline1 />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col sm={6}>
                    <Card>
                        <CardBody>
                            <div>
                                <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                        <div className="avatar">
                                            <div className="avatar-title rounded bg-primary bg-gradient">
                                                <i data-eva="pie-chart-2" className="fill-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <div className="text-muted"><i data-eva="diagonal-arrow-left-down" data-eva-width="18" data-eva-height="18" className="align-text-bottom fill-danger me-1"></i> 0.2%</div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div className="d-flex">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <h4 className="text-truncate mb-1">$21,456</h4>
                                            <p className="text-truncate text-muted mb-0">Av. Sales Yearly</p>
                                        </div>
                                        <div className="flex-shrink-0 align-self-center">
                                            <div id="chart-sparkline2">
                                                <ChartSparkline2 />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col sm={6}>
                    <Card>
                        <CardBody>
                            <div>
                                <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                        <div className="avatar">
                                            <div className="avatar-title rounded bg-primary bg-gradient">
                                                <i data-eva="activity" className="fill-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <div className="text-muted"><i data-eva="diagonal-arrow-left-down" data-eva-width="18" data-eva-height="18" className="align-text-bottom fill-danger me-1"></i> 0.1%</div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div className="d-flex">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <h4 className="text-truncate mb-1">10.23%</h4>
                                            <p className="text-truncate text-muted mb-0">Profit Growth</p>
                                        </div>
                                        <div className="flex-shrink-0 align-self-center">
                                            <div id="chart-sparkline3">
                                                <ChartSparkline3 />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col sm={6}>
                    <Card>
                        <CardBody>
                            <div>
                                <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                        <div className="avatar">
                                            <div className="avatar-title rounded bg-primary bg-gradient">
                                                <i data-eva="people" className="fill-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <div className="text-muted"><i data-eva="diagonal-arrow-right-up" data-eva-width="18" data-eva-height="18" className="align-text-bottom fill-success me-1"></i> 1.15%</div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div className="d-flex">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <h4 className="text-truncate mb-1">20.4k</h4>
                                            <p className="text-truncate text-muted mb-0">Users</p>
                                        </div>
                                        <div className="flex-shrink-0 align-self-center">
                                            <div id="chart-sparkline4">
                                                <ChartSparkline4 />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Widgets;