import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Card,
    CardBody,
} from "reactstrap";

// Import Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//import images
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";

const EmailSidebar = () => {
    const [modal, setmodal] = useState(false);
    return (
        <React.Fragment>
            <div className="email-leftbar">
                <Card>
                    <CardBody>
                        <button
                            onClick={() => {
                                setmodal(!modal);
                            }}
                            type="button" className="btn btn-danger waves-effect waves-light w-100" data-bs-toggle="modal" data-bs-target="#composemodal">
                            Compose
                        </button>

                        <Card className="p-0 overflow-hidden mt-4">
                            <div className="mail-list">
                                <Link to="#" className="active bg-soft-primary">
                                    <div className="d-flex align-items-center">
                                        <i className="mdi mdi-email-outline font-size-20 align-middle me-3"></i>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-14 mb-0">All Inbox</h5>
                                            <span className="text-muted font-size-13 text-truncate">How To Boost Website</span>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div className="float-end">
                                                <span className="bg-primary badge">18</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="#" className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <i className="mdi mdi-star-outline font-size-20 align-middle me-3"></i>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-14 mb-0">Starred</h5>
                                            <span className="text-muted font-size-13 text-truncate">Selected messages</span>
                                        </div>
                                        <div className="flex-shrink-0">
                                        </div>
                                    </div>
                                </Link>

                                <Link to="#" className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <i className="mdi mdi-diamond-stone font-size-20 align-middle me-3"></i>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-14 mb-0">Important</h5>
                                            <span className="text-muted font-size-13 text-truncate">Selected messages</span>
                                        </div>
                                        <div className="flex-shrink-0">
                                        </div>
                                    </div>
                                </Link>

                                <Link to="#" className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <i className="mdi mdi-file-outline font-size-20 align-middle me-3"></i>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-14 mb-0">Draft</h5>
                                            <span className="text-muted font-size-13 text-truncate">Re-edit your messages</span>
                                        </div>
                                        <div className="flex-shrink-0">
                                        </div>
                                    </div>
                                </Link>

                                <Link to="#" className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <i className="mdi mdi-email-check-outline font-size-20 align-middle me-3"></i>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-14 mb-0">Sent Mail</h5>
                                            <span className="text-muted font-size-13 text-truncate">Successfully messages</span>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div className="float-end">
                                                <span className="bg-success badge">08</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="#">
                                    <div className="d-flex align-items-center">
                                        <i className="mdi mdi-trash-can-outline font-size-20 align-middle me-3"></i>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-14 mb-0">Trash</h5>
                                            <span className="text-muted font-size-13 text-truncate">Removed messages</span>
                                        </div>
                                        <div className="flex-shrink-0">
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </Card>

                        <h5 className="mt-3 font-size-15 text-uppercase">Labels</h5>


                        <div className="card p-0 overflow-hidden mt-3">
                            <div className="mail-list">
                                <Link to="#" className="border-bottom"><span className="mdi mdi-arrow-right-drop-circle text-info float-end"></span>Theme Support</Link>
                                <Link to="#" className="border-bottom"><span className="mdi mdi-arrow-right-drop-circle text-warning float-end"></span>Freelance</Link>
                                <Link to="#" className="border-bottom"><span className="mdi mdi-arrow-right-drop-circle text-primary float-end"></span>Social</Link>
                                <Link to="#" className="border-bottom"><span className="mdi mdi-arrow-right-drop-circle text-danger float-end"></span>Friends</Link>
                                <Link to="#"><span className="mdi mdi-arrow-right-drop-circle text-success float-end"></span>Family</Link>
                            </div>
                        </div>

                        <h5 className="mt-3 font-size-15 text-uppercase">Chat</h5>


                        <div className="card p-0 overflow-hidden mt-3 mb-1">
                            <div className="mail-list">
                                <Link to="#" className="d-flex align-items-start border-bottom">
                                    <img className="flex-shrink-0 me-3 rounded-circle" src={avatar2} alt="" height="36" />
                                    <div className="flex-grow-1 chat-user-box">
                                        <p className="user-title m-0">Scott Median</p>
                                        <p className="text-muted mb-0">Hello</p>
                                    </div>
                                </Link>

                                <Link to="#" className="d-flex align-items-start border-bottom">
                                    <img className="flex-shrink-0 me-3 rounded-circle" src={avatar3} alt="" height="36" />
                                    <div className="flex-grow-1 chat-user-box">
                                        <p className="user-title m-0">Julian Rosa</p>
                                        <p className="text-muted mb-0">What about our next..</p>
                                    </div>
                                </Link>

                                <Link to="#" className="d-flex align-items-start border-bottom">
                                    <img className="flex-shrink-0 me-3 rounded-circle" src={avatar4} alt="" height="36" />
                                    <div className="flex-grow-1 chat-user-box">
                                        <p className="user-title m-0">David Medina</p>
                                        <p className="text-muted mb-0">Yeah everything is fine</p>
                                    </div>
                                </Link>

                                <Link to="#" className="d-flex align-items-start border-bottom">
                                    <img className="flex-shrink-0 me-3 rounded-circle" src={avatar6} alt="" height="36" />
                                    <div className="flex-grow-1 chat-user-box">
                                        <p className="user-title m-0">Jay Baker</p>
                                        <p className="text-muted mb-0">Wow that's great</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>

            <Modal
                isOpen={modal}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                tabIndex="-1"
                toggle={() => {
                    setmodal(!modal);
                }}
            >
                <div className="modal-content">
                    <ModalHeader
                        toggle={() => {
                            setmodal(!modal);
                        }}
                    >
                        New Message
                    </ModalHeader>
                    <ModalBody>
                        <form>
                            <div className="mb-3">
                                <Input type="email" className="form-control" placeholder="To" />
                            </div>

                            <div className="mb-3">
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Subject"
                                />
                            </div>
                            <Editor
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                            />
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type="button"
                            color="secondary"
                            onClick={() => {
                                setmodal(!modal);
                            }}
                        >
                            Close
                        </Button>
                        <Button type="button" color="primary">
                            Send <i className="fab fa-telegram-plane ms-1"></i>
                        </Button>
                    </ModalFooter>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default EmailSidebar;