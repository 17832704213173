import React from 'react';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { StatisticsChart } from "./DashboardSassChart";

const Statistics = () => {
    return (
        <React.Fragment>
            <Col xl={4}>
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h5 className="card-title mb-2">Statistics</h5>
                            </div>
                            <div className="flex-shrink-0">
                                <UncontrolledDropdown>
                                    <DropdownToggle className="text-reset" to="#" type="button" tag="a">
                                        <span className="text-muted">Monthly<i className="mdi mdi-chevron-down ms-1"></i></span>
                                    </DropdownToggle>

                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Yearly</DropdownItem>
                                        <DropdownItem>Monthly</DropdownItem>
                                        <DropdownItem>Weekly</DropdownItem>
                                        <DropdownItem>Today</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </div>

                        <div>
                            <div id="chart-radialBar">
                                <StatisticsChart />
                            </div>
                        </div>

                        <div>
                            <div className="d-flex justify-content-between border-bottom pb-3">
                                <p className="mb-0"><i className="mdi mdi-circle font-size-12 text-primary me-1"></i> Smart Camera</p>
                                <div>
                                    <span className="pe-5"> 56,236</span>
                                    <span className="badge badge-soft-success font-size-12"> + 0.2% </span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between border-bottom py-3">
                                <p className="mb-0"><i className="mdi mdi-circle font-size-12 text-danger me-1"></i> Ipad 2021 Modal</p>
                                <div>
                                    <span className="pe-5"> 220k</span>
                                    <span className="badge badge-soft-danger font-size-12"> - 0.7% </span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between pt-3">
                                <p className="mb-0"><i className="mdi mdi-circle font-size-12 text-warning me-1"></i> New Smart 4k TV</p>
                                <div>
                                    <span className="pe-5"> 51,568</span>
                                    <span className="badge badge-soft-success font-size-12"> + 0.4% </span>
                                </div>
                            </div>
                        </div>

                    </CardBody>
                </Card>
            </Col>

        </React.Fragment>
    );
};

export default Statistics;