import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";

import {
    Card,
    CardBody,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown,
} from "reactstrap";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

const Shops = () => {
    const dispatch = useDispatch();

    /*
      set breadcrumbs
      */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Shops",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);
    return (
        <React.Fragment>
            <MetaTags>
                <title>Shops | Painel Gomus</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="position-relative">
                                        <div className="modal-button mt-2"></div>
                                    </div>

                                    <div id="table-ecommerce-shops" className="mt-3">
                                        <div
                                            role="complementary"
                                            className="gridjs gridjs-container"
                                            style={{ width: "100%" }}
                                        >
                                            <div className="gridjs-head">
                                                <div className="gridjs-search">
                                                    <input
                                                        type="search"
                                                        placeholder="Type a keyword..."
                                                        aria-label="Type a keyword..."
                                                        className="gridjs-input gridjs-search-input"
                                                        style={{
                                                            backgroundColor: "#fff",
                                                            fontSize: "13px",
                                                            borderRadius: "30px",
                                                            padding: "10px 13px",
                                                            width: "250px",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="gridjs-wrapper"
                                                style={{ height: "auto" }}
                                            >
                                                <table
                                                    role="grid"
                                                    className="gridjs-table"
                                                    style={{ height: "auto" }}
                                                >
                                                    <thead className="gridjs-thead">
                                                        <tr className="gridjs-tr">
                                                            <th
                                                                data-column-id="brand"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "78px", width: "102px" }}
                                                            >
                                                                <div className="gridjs-th-content">Brand</div>
                                                            </th>
                                                            <th
                                                                data-column-id="name"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "144px", width: "188px" }}
                                                            >
                                                                <div className="gridjs-th-content">Name</div>
                                                            </th>
                                                            <th
                                                                data-column-id="email"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "206px", width: "269px" }}
                                                            >
                                                                <div className="gridjs-th-content">Email</div>
                                                            </th>
                                                            <th
                                                                data-column-id="date"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "98px", width: "127px" }}
                                                            >
                                                                <div className="gridjs-th-content">Date</div>
                                                            </th>
                                                            <th
                                                                data-column-id="product"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "91px", width: "119px" }}
                                                            >
                                                                <div className="gridjs-th-content">Product</div>
                                                            </th>
                                                            <th
                                                                data-column-id="currentBalance"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "148px", width: "193px" }}
                                                            >
                                                                <div className="gridjs-th-content">
                                                                    Current Balance
                                                                </div>
                                                            </th>
                                                            <th
                                                                data-column-id="action"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "81px", width: "106px" }}
                                                            >
                                                                <div className="gridjs-th-content">Action</div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="gridjs-tbody">
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="brand" className="gridjs-td">
                                                                <span>
                                                                    <div className="avatar">
                                                                        <span className="avatar-title bg-soft-primary text-primary font-size-16 rounded-circle">
                                                                            {" "}
                                                                            M{" "}
                                                                        </span>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="name" className="gridjs-td">
                                                                <span>
                                                                    <h5 className="font-size-15">Nedick's</h5>
                                                                    <p className="text-muted mb-0">
                                                                        {" "}
                                                                        <i className="mdi mdi-account me-1"></i>
                                                                        Wayne McClain
                                                                    </p>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="email" className="gridjs-td">
                                                                WayneMcclain@gmail.com
                                                            </td>
                                                            <td data-column-id="date" className="gridjs-td">
                                                                07/10/2021
                                                            </td>
                                                            <td
                                                                data-column-id="product"
                                                                className="gridjs-td"
                                                            >
                                                                86
                                                            </td>
                                                            <td
                                                                data-column-id="currentBalance"
                                                                className="gridjs-td"
                                                            >
                                                                $12,456
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-muted dropdown-toggle font-size-20"
                                                                        >
                                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Action</DropdownItem>
                                                                            <DropdownItem>Remove</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="brand" className="gridjs-td">
                                                                <span>
                                                                    <div className="avatar">
                                                                        <span className="avatar-title bg-soft-warning text-warning font-size-16 rounded-circle">
                                                                            {" "}
                                                                            B{" "}
                                                                        </span>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="name" className="gridjs-td">
                                                                <span>
                                                                    <h5 className="font-size-15">Brendle's</h5>
                                                                    <p className="text-muted mb-0">
                                                                        {" "}
                                                                        <i className="mdi mdi-account me-1"></i>
                                                                        David Marshall
                                                                    </p>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="email" className="gridjs-td">
                                                                Davidmarshall@gmail.com
                                                            </td>
                                                            <td data-column-id="date" className="gridjs-td">
                                                                12/10/2021
                                                            </td>
                                                            <td
                                                                data-column-id="product"
                                                                className="gridjs-td"
                                                            >
                                                                82
                                                            </td>
                                                            <td
                                                                data-column-id="currentBalance"
                                                                className="gridjs-td"
                                                            >
                                                                $34,523
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-muted dropdown-toggle font-size-20"
                                                                        >
                                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Action</DropdownItem>
                                                                            <DropdownItem>Remove</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="brand" className="gridjs-td">
                                                                <span>
                                                                    <div className="avatar">
                                                                        <span className="avatar-title bg-soft-success text-success font-size-16 rounded-circle">
                                                                            {" "}
                                                                            K{" "}
                                                                        </span>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="name" className="gridjs-td">
                                                                <span>
                                                                    <h5 className="font-size-15">Tech Hifi</h5>
                                                                    <p className="text-muted mb-0">
                                                                        {" "}
                                                                        <i className="mdi mdi-account me-1"></i>
                                                                        Katia Stapleton
                                                                    </p>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="email" className="gridjs-td">
                                                                Katiastapleton@gmail.com
                                                            </td>
                                                            <td data-column-id="date" className="gridjs-td">
                                                                14/11/2021
                                                            </td>
                                                            <td
                                                                data-column-id="product"
                                                                className="gridjs-td"
                                                            >
                                                                75
                                                            </td>
                                                            <td
                                                                data-column-id="currentBalance"
                                                                className="gridjs-td"
                                                            >
                                                                $63,265
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-muted dropdown-toggle font-size-20"
                                                                        >
                                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Action</DropdownItem>
                                                                            <DropdownItem>Remove</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="brand" className="gridjs-td">
                                                                <span>
                                                                    <div className="avatar">
                                                                        <span className="avatar-title bg-soft-danger text-danger font-size-16 rounded-circle">
                                                                            {" "}
                                                                            P{" "}
                                                                        </span>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="name" className="gridjs-td">
                                                                <span>
                                                                    <h5 className="font-size-15">Packer</h5>
                                                                    <p className="text-muted mb-0">
                                                                        {" "}
                                                                        <i className="mdi mdi-account me-1"></i>Mae
                                                                        Rankin
                                                                    </p>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="email" className="gridjs-td">
                                                                Maerankingmail.com
                                                            </td>
                                                            <td data-column-id="date" className="gridjs-td">
                                                                14/11/2021
                                                            </td>
                                                            <td
                                                                data-column-id="product"
                                                                className="gridjs-td"
                                                            >
                                                                62
                                                            </td>
                                                            <td
                                                                data-column-id="currentBalance"
                                                                className="gridjs-td"
                                                            >
                                                                $42,652
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-muted dropdown-toggle font-size-20"
                                                                        >
                                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Action</DropdownItem>
                                                                            <DropdownItem>Remove</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="brand" className="gridjs-td">
                                                                <span>
                                                                    <div className="avatar">
                                                                        <span className="avatar-title bg-soft-primary text-primary font-size-16 rounded-circle">
                                                                            {" "}
                                                                            L{" "}
                                                                        </span>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="name" className="gridjs-td">
                                                                <span>
                                                                    <h5 className="font-size-15">Lafayette</h5>
                                                                    <p className="text-muted mb-0">
                                                                        {" "}
                                                                        <i className="mdi mdi-account me-1"></i>
                                                                        Andrew Bivens
                                                                    </p>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="email" className="gridjs-td">
                                                                Andrewbivens@gmail.com
                                                            </td>
                                                            <td data-column-id="date" className="gridjs-td">
                                                                15/11/2021
                                                            </td>
                                                            <td
                                                                data-column-id="product"
                                                                className="gridjs-td"
                                                            >
                                                                55
                                                            </td>
                                                            <td
                                                                data-column-id="currentBalance"
                                                                className="gridjs-td"
                                                            >
                                                                $52,652
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-muted dropdown-toggle font-size-20"
                                                                        >
                                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Action</DropdownItem>
                                                                            <DropdownItem>Remove</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="brand" className="gridjs-td">
                                                                <span>
                                                                    <div className="avatar">
                                                                        <span className="avatar-title bg-soft-warning text-warning font-size-16 rounded-circle">
                                                                            {" "}
                                                                            B{" "}
                                                                        </span>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="name" className="gridjs-td">
                                                                <span>
                                                                    <h5 className="font-size-15">Tech Hifi</h5>
                                                                    <p className="text-muted mb-0">
                                                                        {" "}
                                                                        <i className="mdi mdi-account me-1"></i>John
                                                                        McLeroy
                                                                    </p>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="email" className="gridjs-td">
                                                                JohnmcLeroy@gmail.com
                                                            </td>
                                                            <td data-column-id="date" className="gridjs-td">
                                                                20/11/2021
                                                            </td>
                                                            <td
                                                                data-column-id="product"
                                                                className="gridjs-td"
                                                            >
                                                                53
                                                            </td>
                                                            <td
                                                                data-column-id="currentBalance"
                                                                className="gridjs-td"
                                                            >
                                                                $12,523
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-muted dropdown-toggle font-size-20"
                                                                        >
                                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Action</DropdownItem>
                                                                            <DropdownItem>Remove</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="brand" className="gridjs-td">
                                                                <span>
                                                                    <div className="avatar">
                                                                        <span className="avatar-title bg-soft-success text-success font-size-16 rounded-circle">
                                                                            {" "}
                                                                            K{" "}
                                                                        </span>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="name" className="gridjs-td">
                                                                <span>
                                                                    <h5 className="font-size-15">Packer</h5>
                                                                    <p className="text-muted mb-0">
                                                                        {" "}
                                                                        <i className="mdi mdi-account me-1"></i>
                                                                        Katia Stapleton
                                                                    </p>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="email" className="gridjs-td">
                                                                Katiastapleton@gmail.com
                                                            </td>
                                                            <td data-column-id="date" className="gridjs-td">
                                                                23/11/2021
                                                            </td>
                                                            <td
                                                                data-column-id="product"
                                                                className="gridjs-td"
                                                            >
                                                                66
                                                            </td>
                                                            <td
                                                                data-column-id="currentBalance"
                                                                className="gridjs-td"
                                                            >
                                                                63,523
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-muted dropdown-toggle font-size-20"
                                                                        >
                                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Action</DropdownItem>
                                                                            <DropdownItem>Remove</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="gridjs-footer mt-2">
                                                <div className="gridjs-pagination">
                                                    <div
                                                        role="status"
                                                        aria-live="polite"
                                                        className="gridjs-summary"
                                                        title="Page 1 of 3"
                                                    >
                                                        Showing <b>1</b> to <b>8</b> of <b>18</b> results
                                                    </div>
                                                    <div
                                                        className="gridjs-pages text-muted"
                                                        style={{ textAlign: "right" }}
                                                    >
                                                        <button
                                                            tabIndex="0"
                                                            
                                                            disabled=""
                                                            title="Previous"
                                                            aria-label="Previous"
                                                            className=""
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            tabIndex="0"
                                                            
                                                            className="gridjs-currentPage"
                                                            title="Page 1"
                                                            aria-label="Page 1"
                                                        >
                                                            1
                                                        </button>
                                                        <button
                                                            tabIndex="0"
                                                            
                                                            className=""
                                                            title="Page 2"
                                                            aria-label="Page 2"
                                                        >
                                                            2
                                                        </button>
                                                        <button
                                                            tabIndex="0"
                                                            
                                                            className=""
                                                            title="Page 3"
                                                            aria-label="Page 3"
                                                        >
                                                            3
                                                        </button>
                                                        <button
                                                            tabIndex="0"
                                                            
                                                            title="Next"
                                                            aria-label="Next"
                                                            className=""
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="gridjs-temp" className="gridjs-temp"></div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Shops;
