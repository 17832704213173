import { ErrorMessage, Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import ClipLoader from 'react-spinners/ClipLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Row } from "reactstrap";
import { APIClient } from '../../../helpers/api_helper';
import { brandInitialValues } from '../helpers/brandInitialValues';
import { brandValidationSchema } from '../helpers/brandValidationSchema';

const ModalBrandManagementCreate = ({
    identifier = null,
    isOpen = false,
    onCloseClick,
    loadData,
    centered
}) => {
    const [entity, setEntity] = useState({});
    const [isLoadingEntity, setIsLoadingEntity] = useState(false);

    const api = new APIClient();

    const saveRegistrationData = (values, setSubmitting) => {
        setSubmitting(true);

        let route = `/adm/brand/${entity.identifier}/edit`;

        let aux = {
            name: values.name,
            description: values.description,
        };

        if (!entity.identifier) {
            route = '/adm/brand/new';
        }

        api
            .post(route, aux)
            .then((response) => {
                toast.success(`Marca ${!entity.identifier ? "cadastrada" : "editada" } com sucesso`)
                setSubmitting(false);
                onCloseClick();
                loadData();
            })
            .catch((error) => {
                toast.error(`Problemas ao ${!entity.identifier ? "cadastrar" : "editar" } marca, tente novamente`)
                setSubmitting(false);
            })
        ;
    }

    const loadEntity = () => {
        setIsLoadingEntity(true);

        api
            .get(`/adm/brand/${identifier}/show`, {
                headers: {
                    Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                },
            })
            .then((response) => {
                setEntity(response);
                setIsLoadingEntity(false);
            })
            .catch((error) => {
                setIsLoadingEntity(false);
            })
        ;
    };

    useEffect(() => {
        if (identifier?.length > 0) {
            loadEntity();
            return;
        }

        setEntity({});
    }, [identifier]);

    return (
        <>
            <ToastContainer />
            <Modal
                size="xl"
                isOpen={isOpen}
                className="new-customer"
                centered={centered}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{entity.identifier ? "Edição de marca" : "Cadastro de marca"}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => {
                            onCloseClick();
                        }}
                    />
                </div>
                {isLoadingEntity ? (
                    <ClipLoader size={13} loading={isLoadingEntity} />
                ) : (
                    <>
                        <div className="modal-body">
                            <Formik
                                initialValues={brandInitialValues(entity)}
                                validationSchema={brandValidationSchema(entity)}
                                onSubmit={(values, { resetForm, setValues, setFieldError, setSubmitting }) => {
                                    saveRegistrationData(values, setSubmitting);
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    setFieldValue,
                                    handleSubmit,
                                    isSubmitting,
                                }) => (
                                    <form className="mt-4 pt-2" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="name">Nome</label>
                                                    <Field
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder="Preencha o nome da marca"
                                                        value={values.name}
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red text-xs"
                                                        name="name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="description">Descrição</label>
                                                    <textarea
                                                        className="form-control"
                                                        rows="2"
                                                        id="description"
                                                        name="description"
                                                        placeholder="Preencha a descrição"
                                                        defaultValue={values.description}
                                                        onChange={(e) => setFieldValue("description", e.target.value)}
                                                    />
                                                    <ErrorMessage
                                                        component="p"
                                                        className="text-red text-xs"
                                                        name="description"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <Row className="mt-2">
                                            <div className="col-12 text-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger me-2"
                                                    data-bs-dismiss="modal"
                                                    onClick={() => {
                                                        onCloseClick();
                                                    }}
                                                >
                                                    <i className="bx bx-x me-1"></i> Cancelar e fechar
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-success"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#success-btn"
                                                    id="btn-save-event"
                                                    disabled={isSubmitting}
                                                >
                                                    <i className="bx bx-check me-1"></i>
                                                    {isSubmitting ? (
                                                        <>Salvando</>
                                                    ): (
                                                        <>Salvar</>
                                                    )}
                                                </button>
                                            </div>
                                        </Row>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </>
                )}
            </Modal>
        </>
    )
}

ModalBrandManagementCreate.propTypes = {
    onCloseClick: PropTypes.func,
    isOpen: PropTypes.any,
    identifier: PropTypes.any,
    centered: PropTypes.any
};

export default ModalBrandManagementCreate;