const yup = require("yup");

export const brandValidationSchema = (entity = {}) => {
  return (
    yup.object().shape({
      name: yup.string().required("Campo obrigatório"),
      description: yup.string(),
    })
  );
}
