const yup = require("yup");

export const registerValidationSchema = () => {
  return (
    yup.object().shape({
        name: yup.string().required("Campo obrigatório"),
        email: yup.string()
            .required("Campo obrigatório")
            .email("Deve ser um e-mail válido")
            .trim()
        ,
        password: yup
            .string()
            .required("Campo obrigatório")
            .min(6, "A senha deve conter no mínimo 6 caracteres")
        ,
    })
  );
}
