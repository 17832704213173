import { ErrorMessage, Field, Formik } from 'formik';
import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row } from "reactstrap";
import { APIClient } from '../../../helpers/api_helper';
import { passwordInitialValues } from '../helpers/passwordInitialValues';
import { passwordValidationSchema } from '../helpers/passwordValidationSchema';

const CompanyData = ({
    entity = {},
    onCloseClick = () => {},
    loadData = () => {}
}) => {
    const api = new APIClient();

    const saveCompanyData = (values, setSubmitting) => {
        setSubmitting(true);

        api
            .post(`/adm/user/${entity.identifier}/change-password`, { password: values.password })
            .then((response) => {
                toast.success(`Senha alterada com sucesso`)
                setSubmitting(false);
                onCloseClick();
                loadData();
            })
            .catch((error) => {
                toast.error(`Problemas ao alterar senha, tente novamente`)
                setSubmitting(false);
            })
        ;
    }

    return (
        <Formik
            initialValues={passwordInitialValues()}
            validationSchema={passwordValidationSchema()}
            onSubmit={(values, { resetForm, setValues, setFieldError, setSubmitting }) => {
                saveCompanyData(values, setSubmitting);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                isSubmitting,
            }) => (
                <form className="mt-4 pt-2" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="password">Senha</label>
                                <Field
                                    type="password"
                                    id="password"
                                    name="password"
                                    className="form-control"
                                    placeholder="Preencha a senha"
                                    value={values.password}
                                />
                                <ErrorMessage
                                    component="p"
                                    className="text-red text-xs"
                                    name="password"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="confirmPassword">Confirmar senha</label>
                                <Field
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    className="form-control"
                                    placeholder="Confirme a senha"
                                    value={values.confirmPassword}
                                />
                                <ErrorMessage
                                    component="p"
                                    className="text-red text-xs"
                                    name="confirmPassword"
                                />
                            </div>
                        </div>
                    </div>
                    <Row className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    onCloseClick();
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Cancelar e fechar
                            </button>
                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                                disabled={isSubmitting}
                            >
                                <i className="bx bx-check me-1"></i>
                                {isSubmitting ? (
                                    <>Salvando</>
                                ): (
                                    <>Salvar</>
                                )}
                            </button>
                        </div>
                    </Row>
                </form>
            )}
        </Formik>
    );
};


export default CompanyData;
