import React from "react";
import { Col, Row } from "reactstrap";
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import login from "../../assets/images/login-img.png";


const AuthSlider = () => {
  return (
    <React.Fragment>
      <Col className="col-xxl-8 col-lg-8 col-md-6">
        <div className="auth-bg bg-white py-md-5 p-4 d-flex">
          <div className="bg-overlay bg-white"></div>

          <Row className="justify-content-center align-items-center">
            <Col xl={8}>
              <div className="mt-4">
                <img src={login} className="img-fluid" alt="" />
              </div>
              <div className="p-0 p-sm-4 px-xl-0 py-5">
              <div className="carousel-inner w-75 mx-auto">

                <Carousel showThumbs={false} className="slider_css">

                    <div>
                      <div className="item">
                        <div className="testi-contain text-center">
                          <h5 className="font-size-20 mt-4">“I feel confident
                            imposing change
                            on myself”
                          </h5>
                          <p className="font-size-15 text-muted mt-3 mb-0">Vestibulum auctor orci in risus iaculis consequat suscipit felis rutrum aliquet iaculis
                            augue sed tempus In elementum ullamcorper lectus vitae pretium Aenean sed odio dolor Nullam ultricies diam
                            eu ultrices tellus eifend sagittis.</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="item">
                        <div className="testi-contain text-center">
                          <h5 className="font-size-20 mt-4">“Our task must be to
                            free widening our circle”</h5>
                          <p className="font-size-15 text-muted mt-3 mb-0">
                            Curabitur eget nulla eget augue dignissim condintum Nunc imperdiet ligula porttitor commodo elementum
                            Vivamus justo risus fringilla suscipit faucibus orci luctus
                            ultrices posuere cubilia curae lectus non ultricies cursus.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="item">
                        <div className="testi-contain text-center">
                          <h5 className="font-size-20 mt-4">“I've learned that
                            people will forget what you”</h5>
                          <p className="font-size-15 text-muted mt-3 mb-0">
                            Pellentesque lacinia scelerisque arcu in aliquam augue molestie rutrum magna Fusce dignissim dolor id auctor accumsan
                            vehicula dolor
                            vivamus feugiat odio erat sed vehicula lorem tempor quis Donec nec scelerisque magna
                          </p>
                        </div>
                      </div>
                    </div>
                </Carousel>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Col>

    </React.Fragment>
  );
};

export default AuthSlider;





