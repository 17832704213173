import React from 'react';
import ReactApexChart from "react-apexcharts";

const OverviewChart = () => {
    const series = [{
        name: 'Website Blog',
        type: 'area',
        data: [24, 21, 26, 24, 27, 27, 25, 28, 26]
    }, {
        name: 'Social Media',
        data: [21, 24, 20, 27, 25, 29, 26, 34, 30],
        type: 'line',
    }];
    var options = {
        chart: {
            type: 'line',
            height: 350,
            toolbar: {
                show: false,
            },
        },


        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            curve: 'smooth',
            width: [0, 4]
        },
        forecastDataPoints: {
            count: 7
        },
        colors: ["#f1f3f7", "#f56e6e", "#f1f3f7", "#3b76e1"],
        xaxis: {
            categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return value + "k";
                }
            },
            tickAmount: 4,
        },
        legend: {
            show: false,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                gradientToColors: ["#f1f3f7", "#3b76e1"],
                shadeIntensity: 1,
                type: 'horizontal',
                opacityFrom: [0.75, 1],
                opacityTo: [0.75, 1],
                stops: [0, 100, 100, 100]
            },
        },
        markers: {
            size: 3,
            strokeWidth: 3,

            hover: {
                size: 6,
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            }
        },
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height="350"
                className="apex-charts"
            />
        </React.Fragment>
    );
};


const StatisticsChart = () => {

    const series = [44, 55, 67];
    var options = {
        chart: {
            height: 323,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                offsetY: 0,
                startAngle: 0,
                endAngle: 270,
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        show: false,
                    }
                },

                hollow: {
                    margin: 7,
                    size: '20%',
                },
                track: {
                    strokeWidth: '60%',
                    opacity: 1,
                    margin: 16,
                },
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: 'horizontal',
                shadeIntensity: 0.5,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
            }
        },
        stroke: {
            lineCap: 'round'
        },
        colors: ["#3b76e1", "#f56e6e", "#f9c256"],
        labels: ['Product A', 'Product B', 'Product C'],
        legend: {
            show: true,
            floating: true,
            fontSize: '16px',
            position: 'left',
            offsetX: -24,
            offsetY: 15,
            labels: {
                useSeriesColors: true,
            },
            markers: {
                size: 0
            },
            formatter: function (seriesName, opts) {
                return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
            },
            itemMargin: {
                vertical: 3
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    show: false
                }
            }
        }]
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="radialBar"
                height="323"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

export { OverviewChart, StatisticsChart };