import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

//Simple bar
import SimpleBar from "simplebar-react";

//import images
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";

const Inbox = () => {
    return (
        <React.Fragment>
            <Col xl={4}>
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h5 className="card-title mb-4">Inbox</h5>
                            </div>
                            <div className="flex-shrink-0">
                                <UncontrolledDropdown>
                                    <DropdownToggle className="dropdown-toggle text-reset" to="#" type="button" tag="a">
                                        <span className="text-muted">Monthly<i className="mdi mdi-chevron-down ms-1"></i></span>
                                    </DropdownToggle>

                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Yearly</DropdownItem>
                                        <DropdownItem>Monthly</DropdownItem>
                                        <DropdownItem>Weekly</DropdownItem>
                                        <DropdownItem>Today</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </div>

                        <SimpleBar className="chat-message-list widget-chat-list mx-n3">
                            <div className="px-3">
                                <ul className="list-unstyled chat-list">
                                    <li className="active">
                                        <Link to="#" className="mt-0">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 user-img online align-self-center me-3">
                                                    <img src={avatar2} className="rounded-circle avatar-sm" alt="" />
                                                    <span className="user-status"></span>
                                                </div>

                                                <div className="flex-grow-1">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <h5 className="text-truncate font-size-14 mb-1">Steven Jones</h5>
                                                            <p className="text-truncate mb-0">Hey! there I'm available</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="font-size-11">02 min</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="unread">
                                        <Link to="#">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 user-img online align-self-center me-3">
                                                    <div className="avatar-sm align-self-center">
                                                        <span className="avatar-title rounded-circle bg-primary bg-gradient">
                                                            H
                                                        </span>
                                                    </div>
                                                    <span className="user-status"></span>
                                                </div>

                                                <div className="flex-grow-1">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <h5 className="text-truncate font-size-14 mb-1">Alex Mulvey</h5>
                                                            <p className="text-truncate mb-0">I've finished it! See you so</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="font-size-11">10 min</div>
                                                        </div>

                                                        <div className="unread-message">
                                                            <span className="badge bg-danger rounded-pill">1</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 user-img away align-self-center me-3">
                                                    <img src={avatar3} className="rounded-circle avatar-sm" alt="" />
                                                    <span className="user-status"></span>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <h5 className="text-truncate font-size-14 mb-1">Justin Moore</h5>
                                                            <p className="text-truncate mb-0">This theme is awesome!</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="font-size-11">22 min</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <div className="d-flex align-items-start">

                                                <div className="flex-shrink-0 user-img align-self-center me-3">
                                                    <img src={avatar4} className="rounded-circle avatar-sm" alt="" />
                                                    <span className="user-status"></span>
                                                </div>

                                                <div className="flex-grow-1">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <h5 className="text-truncate font-size-14 mb-1">Edith McLain</h5>
                                                            <p className="text-truncate mb-0">Nice to meet you</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="font-size-11">01 Hr</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <div className="d-flex align-items-start">

                                                <div className="flex-shrink-0 user-img online align-self-center me-3">
                                                    <div className="avatar-sm align-self-center">
                                                        <span className="avatar-title rounded-circle bg-primary bg-gradient">
                                                            W
                                                        </span>
                                                    </div>
                                                    <span className="user-status"></span>
                                                </div>

                                                <div className="flex-grow-1">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <h5 className="text-truncate font-size-14 mb-1">William Declue</h5>
                                                            <p className="text-truncate mb-0">Wow that's great</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="font-size-11">04 Hrs</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <div className="d-flex align-items-start">

                                                <div className="flex-shrink-0 user-img online align-self-center me-3">
                                                    <div className="avatar-sm align-self-center">
                                                        <span className="avatar-title rounded-circle bg-primary bg-gradient">
                                                            W
                                                        </span>
                                                    </div>
                                                    <span className="user-status"></span>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <h5 className="text-truncate font-size-14 mb-1">Wendy Lennox</h5>
                                                            <p className="text-truncate mb-0">Hey! there I'm available</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="font-size-11">10 Hrs</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <div className="d-flex align-items-start">

                                                <div className="flex-shrink-0 user-img online align-self-center me-3">
                                                    <div className="avatar-sm align-self-center">
                                                        <span className="avatar-title rounded-circle bg-primary bg-gradient">
                                                            W
                                                        </span>
                                                    </div>
                                                    <span className="user-status"></span>
                                                </div>

                                                <div className="flex-grow-1">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <h5 className="text-truncate font-size-14 mb-1">Warren Daly</h5>
                                                            <p className="text-truncate mb-0">Hey! there I'm available</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="font-size-11">10 Hrs</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 user-img away align-self-center me-3">
                                                    <img src={avatar3} className="rounded-circle avatar-sm" alt="" />
                                                    <span className="user-status"></span>
                                                </div>

                                                <div className="flex-grow-1">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <h5 className="text-truncate font-size-14 mb-1">Mary Welch</h5>
                                                            <p className="text-truncate mb-0">This theme is awesome!</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="font-size-11">19 Sept</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </SimpleBar>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default Inbox;