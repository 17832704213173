import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

// Import Images
import Img2 from "../../assets/images/small/img-2.jpg";
import Img5 from "../../assets/images/small/img-5.jpg";
import { Link } from 'react-router-dom';

const TimelinePage = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Timeline",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);
    return (
        <React.Fragment>
            <MetaTags>
                <title>Timeline | Painel Gomus</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Vertical Timeline</h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="my-2">
                                        <ul className="verti-timeline list-unstyled">
                                            <li className="event-list">
                                                <div className="event-timeline-dot">
                                                    <i className="bx bx-right-arrow-circle"></i>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="bx bx-copy-alt h4 text-primary"></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div>
                                                            <h5>Timeline Event One</h5>
                                                            <p className="text-muted mb-0">Perspitis unde omnis it voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae explicabo.</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="event-list">
                                                <div className="event-timeline-dot">
                                                    <i className="bx bx-right-arrow-circle"></i>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="bx bx-package h4 text-primary"></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div>
                                                            <h5>Timeline Event two</h5>
                                                            <p className="text-muted">At vero eos dignissimos ducimus quos dolores chooses to enjoy pleasure that has no annoying.</p>
                                                            <div className="d-flex flex-wrap align-items-start event-img mt-3 gap-2">
                                                                <img src={Img2} alt="" className="img-fluid rounded" width="60" />
                                                                <img src={Img5} alt="" className="img-fluid rounded" width="60" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="event-list active">
                                                <div className="event-timeline-dot">
                                                    <i className="bx bx-right-arrow-circle bx-fade-right"></i>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="bx bx-car h4 text-primary"></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div>
                                                            <h5>Timeline Event Three</h5>
                                                            <p className="text-muted mb-0">Vivamus ultrices massa turna interdum eu. Pellentesque habitant morbi tristique eget justo sit amet est varius mollis et quis nisi.
                                                                Suspendisse potenti. senectus et netus et malesuada fames ac turpis egestas.</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="event-list">
                                                <div className="event-timeline-dot">
                                                    <i className="bx bx-right-arrow-circle"></i>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="bx bx-badge-check h4 text-primary"></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div>
                                                            <h5>Timeline Event Four</h5>
                                                            <p className="text-muted mb-0">Printing and typesetting industry. been the industry'scrambled it make a type specimen book.</p>
                                                            <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light mt-4">See
                                                                more detail
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="event-list">
                                                <div className="event-timeline-dot">
                                                    <i className="bx bx-right-arrow-circle"></i>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="bx bx-cart-alt h4 text-primary"></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div>
                                                            <h5>Timeline Event Five</h5>
                                                            <p className="text-muted mb-0">Excepturi, obcaecati, quisquam id molestias eaque asperiores
                                                                voluptatibus cupiditate error assumenda delectus odit similique earum voluptatem Odit,
                                                                itaque, deserunt corporis vero ipsum nisi repellat ... <Link to="#">Read more</Link></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="event-list">
                                                <div className="event-timeline-dot">
                                                    <i className="bx bx-right-arrow-circle"></i>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="bx bx-aperture h4 text-primary"></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div>
                                                            <h5>Timeline Event End</h5>
                                                            <p className="text-muted mb-0">Suspendisse tempor porttitor elit non maximus. Sed suscipit, purus in convallis condimentum, risus ex pellentesque sapien,
                                                                vel tempor arcu dolor ut est. Nam ac felis id mauris fermentum nisl pharetra auctor.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Horizontal Timeline</h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="my-2">
                                        <div className="hori-timeline">

                                        <Swiper watchSlidesProgress={true} slidesPerView={4} className="swiper-container slider events">
                                                <div className="swiper-wrapper">
                                                    <SwiperSlide>

                                                    <div className="swiper-slide event-list">
                                                        <div>
                                                            <div className="event-date">
                                                                <div className="text-primary mb-1">12 September</div>
                                                                <h5 className="mb-4">First event</h5>
                                                            </div>
                                                            <div className="event-down-icon">
                                                                <i className="bx bx-down-arrow-circle h3 text-primary down-arrow-icon"></i>
                                                            </div>

                                                            <div className="mt-3 px-3">
                                                                <p className="text-muted">It will be as simple as occidental in fact it will be Cambridge</p>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    </SwiperSlide>
                                                    <SwiperSlide>

                                                    <div className="swiper-slide event-list">
                                                        <div>
                                                            <div className="event-date">
                                                                <div className="text-primary mb-1">06 October</div>
                                                                <h5 className="mb-4">Second event</h5>
                                                            </div>
                                                            <div className="event-down-icon">
                                                                <i className="bx bx-down-arrow-circle h3 text-primary down-arrow-icon"></i>
                                                            </div>

                                                            <div className="mt-3 px-3">
                                                                <p className="text-muted">To an English person, it will seem like simplified English existence.</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    </SwiperSlide>
                                                    <SwiperSlide>

                                                    <div className="swiper-slide event-list active">
                                                        <div>
                                                            <div className="event-date">
                                                                <div className="text-primary mb-1">25 October</div>
                                                                <h5 className="mb-4">Third event</h5>
                                                            </div>
                                                            <div className="event-down-icon">
                                                                <i className="bx bx-down-arrow-circle h3 text-primary down-arrow-icon"></i>
                                                            </div>

                                                            <div className="mt-3 px-3">
                                                                <p className="text-muted">For science, music, sport, etc, Europe uses the same vocabulary.</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    </SwiperSlide>
                                                    <SwiperSlide>

                                                    <div className="swiper-slide event-list">
                                                        <div>
                                                            <div className="event-date">
                                                                <div className="text-primary mb-1">04 November</div>
                                                                <h5 className="mb-4">Fourth event</h5>
                                                            </div>
                                                            <div className="event-down-icon">
                                                                <i className="bx bx-down-arrow-circle h3 text-primary down-arrow-icon"></i>
                                                            </div>

                                                            <div className="mt-3 px-3">
                                                                <p className="text-muted">New common language will be more simple than existing.</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    </SwiperSlide>
                                                    <SwiperSlide>

                                                    <div className="swiper-slide event-list">
                                                        <div>
                                                            <div className="event-date">
                                                                <div className="text-primary mb-1">19 November</div>
                                                                <h5 className="mb-4">Sixth event</h5>
                                                            </div>
                                                            <div className="event-down-icon">
                                                                <i className="bx bx-down-arrow-circle h3 text-primary down-arrow-icon"></i>
                                                            </div>

                                                            <div className="mt-3 px-3">
                                                                <p className="text-muted">It will be as simple as occidental in fact it will be Cambridge</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    </SwiperSlide>
                                                    <SwiperSlide>

                                                    <div className="swiper-slide event-list">
                                                        <div>
                                                            <div className="event-date">
                                                                <div className="text-primary mb-1">21 December</div>
                                                                <h5 className="mb-4">Seventh event</h5>
                                                            </div>
                                                            <div className="event-down-icon">
                                                                <i className="bx bx-down-arrow-circle h3 text-primary down-arrow-icon"></i>
                                                            </div>

                                                            <div className="mt-3 px-3">
                                                                <p className="text-muted">To an English person, it will seem like simplified English existence.</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    </SwiperSlide>
                                                </div>
                                        </Swiper>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TimelinePage;
