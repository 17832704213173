import React from 'react';
import { Link } from 'react-router-dom';
import {Col, Container, Row } from 'reactstrap';
import MetaTags from "react-meta-tags";

// Import Images
import error500 from "../../assets/images/error-img.png";

const Error500 = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Error 500 | Painel Gomus</title>
            </MetaTags>
            <div className="bg-light">
                <div className="authentication-bg min-vh-100 py-5 py-lg-0">
                    <div className="bg-overlay bg-light"></div>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Row>
                                    <Col lg={12}>
                                        <div className="text-center mb-5">
                                            <h1 className="error-title"> <span className="blink-infinite">500</span></h1>
                                            <h4 className="text-uppercase">Internal Server Error</h4>
                                            <p className="font-size-15 mx-auto text-muted w-50 mt-4">It will be as simple as Occidental in fact, it will Occidental to an English person</p>
                                            <div className="mt-5 text-center">
                                                <Link className="btn btn-primary waves-effect waves-light" to="/">Back to Dashboard</Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col md={8} xl={7}>
                                        <div className="mt-2">
                                            <img src={error500} alt="" className="img-fluid" />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </React.Fragment>
    );
};

export default Error500;
