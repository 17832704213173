import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';

import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
import avatar6 from "../../../assets/images/users/avatar-6.jpg";
import avatar7 from "../../../assets/images/users/avatar-7.jpg";
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import Toolbar from '../Toolbar';

const Inbox = () => {

    return (
        <React.Fragment>
            <div className="email-rightbar mb-3">

                <Toolbar />

                <div>
                    <h6 className="text-muted text-uppercase mb-3">Today</h6>
                    <Card className="mb-2">
                        <div className="message-list mb-0 p-1">
                            <div className="list">
                                <div className="col-mail col-mail-1">
                                    <div className="checkbox-wrapper-mail">
                                        <input type="checkbox" id="chk1" />
                                        <label htmlFor="chk1" className="toggle"></label>
                                    </div>
                                    <div className="d-flex title align-items-center">
                                        <img src={avatar1} className="avatar-sm rounded-circle" alt="" />
                                        <div className="flex-1 ms-2 ps-1">
                                            <h5 className="font-size-14 mb-0"><Link to="" className="text-dark">Whitney Peter</Link></h5>
                                            <Link to="" className="text-muted text-uppercase font-size-12">23 Threads</Link>
                                        </div>
                                    </div>
                                    <span className="star-toggle far fa-star"></span>
                                </div>
                                <div className="col-mail col-mail-2">
                                    <Link to="#" className="subject text-dark"> Delectus, ut aut reiciendis – <span className="teaser text-muted fw-normal">Trip home from Colombo has been arranged, then Jenna will come get me from Stockholm. :)</span>
                                    </Link>
                                    <div className="date"><i className="mdi mdi-link-variant me-2 font-size-15 align-middle"></i> 5:01 AM</div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <div className="card mb-2">
                        <div className="message-list mb-0 p-1">
                            <div className="list">
                                <div className="col-mail col-mail-1">
                                    <div className="checkbox-wrapper-mail">
                                        <input type="checkbox" id="chk2" />
                                        <label htmlFor="chk2" className="toggle"></label>
                                    </div>
                                    <div className="d-flex title align-items-center">
                                        <img src={avatar2} className="avatar-sm rounded-circle" alt="" />
                                        <div className="flex-1 ms-2 ps-1">
                                            <h5 className="font-size-14 mb-0"><Link to="" className="text-dark">me, Susanna</Link></h5>
                                            <Link to="" className="text-muted text-uppercase font-size-12">07 Threads</Link>
                                        </div>
                                    </div>
                                    <span className="star-toggle far fa-star"></span>
                                </div>
                                <div className="col-mail col-mail-2">
                                    <Link to="#" className="subject text-dark"><span className="bg-warning badge me-2">Freelance</span> Wolombo has been arranged, – <span className="teaser text-muted fw-normal">Alright thanks. I'll have to re-book that somehow, i'll get back to you.</span>
                                    </Link>
                                    <div className="date"><i className="mdi mdi-link-variant me-2 font-size-15 align-middle"></i> 8:23 AM</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="message-list mb-0 p-1">
                            <div className="list">
                                <div className="col-mail col-mail-1">
                                    <div className="checkbox-wrapper-mail">
                                        <input type="checkbox" id="chk3" />
                                        <label htmlFor="chk3" className="toggle"></label>
                                    </div>
                                    <div className="d-flex title align-items-center">
                                        <img src={avatar4} className="avatar-sm rounded-circle" alt="" />
                                        <div className="flex-1 ms-2 ps-1">
                                            <h5 className="font-size-14 mb-0"><Link to="" className="text-dark">Web Support</Link></h5>
                                            <Link to="" className="text-muted text-uppercase font-size-12">14 Threads</Link>
                                        </div>
                                    </div>
                                    <span className="star-toggle far fa-star"></span>
                                </div>
                                <div className="col-mail col-mail-2">
                                    <Link to="#" className="subject text-dark"> Re: New mail settings – <span className="teaser text-muted fw-normal">Will you answer him asap?</span>
                                    </Link>
                                    <div className="date"><i className="mdi mdi-link-variant me-2 font-size-15 align-middle"></i> 6:36 PM</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div>
                    <h6 className="text-muted text-uppercase mb-3">Yesterday</h6>
                    <div className="card mb-2">
                        <div className="message-list mb-0 p-1">
                            <div className="list">
                                <div className="col-mail col-mail-1">
                                    <div className="checkbox-wrapper-mail">
                                        <input type="checkbox" id="chk4" />
                                        <label htmlFor="chk4" className="toggle"></label>
                                    </div>
                                    <div className="d-flex title align-items-center">
                                        <img src={avatar1} className="avatar-sm rounded-circle" alt="" />
                                        <div className="flex-1 ms-2 ps-1">
                                            <h5 className="font-size-14 mb-0"><Link to="" className="text-dark">Whitney Peter</Link></h5>
                                            <Link to="" className="text-muted text-uppercase font-size-12">23 Threads</Link>
                                        </div>
                                    </div>
                                    <span className="star-toggle far fa-star"></span>
                                </div>
                                <div className="col-mail col-mail-2">
                                    <Link to="#" className="subject text-dark"> <span className="bg-info badge me-2">Support</span> Off on Thursday - <span className="teaser text-muted fw-normal">Eff that place, you might as well stay here with us instead! Sent from my iPhone 4  4 mar 2014 at 5:55 pm</span>
                                    </Link>
                                    <div className="date"><i className="mdi mdi-link-variant me-2 font-size-15 align-middle"></i> 3:26 AM</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="message-list mb-0 p-1">
                            <div className="list">
                                <div className="col-mail col-mail-1">
                                    <div className="checkbox-wrapper-mail">
                                        <input type="checkbox" id="chk5" />
                                        <label htmlFor="chk5" className="toggle"></label>
                                    </div>
                                    <div className="d-flex title align-items-center">
                                        <img src={avatar6} className="avatar-sm rounded-circle" alt="" />
                                        <div className="flex-1 ms-2 ps-1">
                                            <h5 className="font-size-14 mb-0"><Link to="" className="text-dark">Death to Stock</Link></h5>
                                            <Link to="" className="text-muted text-uppercase font-size-12">17 Threads</Link>
                                        </div>
                                    </div>
                                    <span className="star-toggle far fa-star"></span>
                                </div>
                                <div className="col-mail col-mail-2">
                                    <Link to="#" className="subject text-dark"> <span className="bg-primary badge me-2">Social</span> This Week's Top Stories – <span className="teaser text-muted fw-normal">Our top pick for you on Medium this week The Man Who Destroyed America’s Ego</span>
                                    </Link>
                                    <div className="date"><i className="mdi mdi-link-variant me-2 font-size-15 align-middle"></i> 4:32 PM</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <h6 className="text-muted text-uppercase mb-3">27 March</h6>
                    <div className="card mb-2">
                        <div className="message-list mb-0 p-1">
                            <div className="list">
                                <div className="col-mail col-mail-1">
                                    <div className="checkbox-wrapper-mail">
                                        <input type="checkbox" id="chk6" />
                                        <label htmlFor="chk6" className="toggle"></label>
                                    </div>
                                    <div className="d-flex title align-items-center">
                                        <img src={avatar1} className="avatar-sm rounded-circle" alt="" />
                                        <div className="flex-1 ms-2 ps-1">
                                            <h5 className="font-size-14 mb-0"><Link to="" className="text-dark">Andrew Zimmer</Link></h5>
                                            <Link to="" className="text-muted text-uppercase font-size-12">02 Threads</Link>
                                        </div>
                                    </div>
                                    <span className="star-toggle far fa-star"></span>
                                </div>
                                <div className="col-mail col-mail-2">
                                    <Link to="#" className="subject text-dark"> Mochila Beta: Subscription Confirmed – <span className="teaser text-muted fw-normal">You've been confirmed! Welcome to the ruling class of the inbox. For your records, here is a copy of the information you submitted to us...</span>
                                    </Link>
                                    <div className="date"><i className="mdi mdi-link-variant me-2 font-size-15 align-middle"></i> 4:24 PM</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-2">
                        <div className="message-list mb-0 p-1">
                            <div className="list">
                                <div className="col-mail col-mail-1">
                                    <div className="checkbox-wrapper-mail">
                                        <input type="checkbox" id="chk7" />
                                        <label htmlFor="chk7" className="toggle"></label>
                                    </div>
                                    <div className="d-flex title align-items-center">
                                        <img src={avatar2} className="avatar-sm rounded-circle" alt="" />
                                        <div className="flex-1 ms-2 ps-1">
                                            <h5 className="font-size-14 mb-0"><Link to="" className="text-dark">Randy, me (5)</Link></h5>
                                            <Link to="" className="text-muted text-uppercase font-size-12">15 Threads</Link>
                                        </div>
                                    </div>
                                    <span className="star-toggle far fa-star"></span>
                                </div>
                                <div className="col-mail col-mail-2">
                                    <Link to="#" className="subject text-dark"><span className="bg-success badge me-2">Family</span> Weekend on Revibe – <span className="teaser text-muted fw-normal">Today's Friday and we thought maybe you want some music inspiration for the weekend. Here are some trending tracks and playlists we think you should give a listen!</span>
                                    </Link>
                                    <div className="date"><i className="mdi mdi-link-variant me-2 font-size-15 align-middle"></i> 4:24 PM</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-2">
                        <div className="message-list mb-0 p-1">
                            <div className="list">
                                <div className="col-mail col-mail-1">
                                    <div className="checkbox-wrapper-mail">
                                        <input type="checkbox" id="chk8" />
                                        <label htmlFor="chk8" className="toggle"></label>
                                    </div>
                                    <div className="d-flex title align-items-center">
                                        <img src={avatar7} className="avatar-sm rounded-circle" alt="" />
                                        <div className="flex-1 ms-2 ps-1">
                                            <h5 className="font-size-14 mb-0"><Link to="" className="text-dark">KanbanFlow</Link></h5>
                                            <Link to="" className="text-muted text-uppercase font-size-12">06 Threads</Link>
                                        </div>
                                    </div>
                                    <span className="star-toggle far fa-star"></span>
                                </div>
                                <div className="col-mail col-mail-2">
                                    <Link to="#" className="subject text-dark"> Task assigned: Clone ARP's website
                                        –  <span className="teaser text-muted fw-normal">You have been assigned a task by Alex@Work on the board Web.</span>
                                    </Link>
                                    <div className="date"><i className="mdi mdi-link-variant me-2 font-size-15 align-middle"></i> 7:36 AM</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="message-list mb-0 p-1">
                            <div className="list">
                                <div className="col-mail col-mail-1">
                                    <div className="checkbox-wrapper-mail">
                                        <input type="checkbox" id="chk9" />
                                        <label htmlFor="chk9" className="toggle"></label>
                                    </div>
                                    <div className="d-flex title align-items-center">
                                        <img src={avatar3} className="avatar-sm rounded-circle" alt="" />
                                        <div className="flex-1 ms-2 ps-1">
                                            <h5 className="font-size-14 mb-0"><Link to="" className="text-dark">Revibe</Link></h5>
                                            <Link to="" className="text-muted text-uppercase font-size-12">25 Threads</Link>
                                        </div>
                                    </div>
                                    <span className="star-toggle far fa-star"></span>
                                </div>
                                <div className="col-mail col-mail-2">
                                    <Link to="#" className="subject text-dark"> Last pic over my village – <span className="teaser text-muted fw-normal">Yeah i'd like that! Do you remember the video you showed me of your train ride between Colombo and Kandy? The one with the mountain view? I would love to see that one again!</span>
                                    </Link>
                                    <div className="date"><i className="mdi mdi-link-variant me-2 font-size-15 align-middle"></i> 9:52 PM</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <h6 className="text-muted text-uppercase mb-3">26 March</h6>
                    <div className="card">
                        <div className="message-list mb-0 p-1">
                            <div className="list">
                                <div className="col-mail col-mail-1">
                                    <div className="checkbox-wrapper-mail">
                                        <input type="checkbox" id="chk10" />
                                        <label htmlFor="chk10" className="toggle"></label>
                                    </div>
                                    <div className="d-flex title align-items-center">
                                        <img src={avatar1} className="avatar-sm rounded-circle" alt="" />
                                        <div className="flex-1 ms-2 ps-1">
                                            <h5 className="font-size-14 mb-0"><Link to="" className="text-dark">Erik, me (5)</Link></h5>
                                            <Link to="" className="text-muted text-uppercase font-size-12">07 Threads</Link>
                                        </div>
                                    </div>
                                    <span className="star-toggle far fa-star"></span>
                                </div>
                                <div className="col-mail col-mail-2">
                                    <Link to="#" className="subject text-dark"> <span className="bg-danger badge me-2">Friends</span> Let's go fishing! – <span className="teaser text-muted fw-normal">Hey, You wanna join me and Fred at the lake tomorrow? It'll be awesome.</span>
                                    </Link>
                                    <div className="date"><i className="mdi mdi-link-variant me-2 font-size-15 align-middle"></i> 5:05 PM</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <h6 className="text-muted text-uppercase mb-3">25 March</h6>
                    <div className="card mb-2">
                        <div className="message-list mb-0 p-1">
                            <div className="list">
                                <div className="col-mail col-mail-1">
                                    <div className="checkbox-wrapper-mail">
                                        <input type="checkbox" id="chk11" />
                                        <label htmlFor="chk11" className="toggle"></label>
                                    </div>
                                    <div className="d-flex title align-items-center">
                                        <img src={avatar1} className="avatar-sm rounded-circle" alt="" />
                                        <div className="flex-1 ms-2 ps-1">
                                            <h5 className="font-size-14 mb-0"><Link to="" className="text-dark">Stack Exchange</Link></h5>
                                            <Link to="" className="text-muted text-uppercase font-size-12">19 Threads</Link>
                                        </div>
                                    </div>
                                    <span className="star-toggle far fa-star"></span>
                                </div>
                                <div className="col-mail col-mail-2">
                                    <Link to="#" className="subject text-dark"> 1 new items in your Stackexchange inbox
                                        – <span className="teaser text-muted fw-normal"> The following items were added to your Stack Exchange global inbox since you last checked it.</span>
                                    </Link>
                                    <div className="date"><i className="mdi mdi-link-variant me-2 font-size-15 align-middle"></i> 7:30 PM</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-2">
                        <div className="message-list mb-0 p-1">
                            <div className="list">
                                <div className="col-mail col-mail-1">
                                    <div className="checkbox-wrapper-mail">
                                        <input type="checkbox" id="chk12" />
                                        <label htmlFor="chk12" className="toggle"></label>
                                    </div>
                                    <div className="d-flex title align-items-center">
                                        <img src={avatar7} className="avatar-sm rounded-circle" alt="" />
                                        <div className="flex-1 ms-2 ps-1">
                                            <h5 className="font-size-14 mb-0"><Link to="" className="text-dark">Google Drive Team</Link></h5>
                                            <Link to="" className="text-muted text-uppercase font-size-12">47 Threads</Link>
                                        </div>
                                    </div>
                                    <span className="star-toggle far fa-star"></span>
                                </div>
                                <div className="col-mail col-mail-2">
                                    <Link to="#" className="subject text-dark"> You can now use your storage in Google
                                        Drive –  <span className="teaser text-muted fw-normal">Hey Nicklas Sandell! Thank you for purchasing extra storage space in Google Drive.</span>
                                    </Link>
                                    <div className="date"><i className="mdi mdi-link-variant me-2 font-size-15 align-middle"></i> 9:14 AM</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="message-list mb-0 p-1">
                            <div className="list">
                                <div className="col-mail col-mail-1">
                                    <div className="checkbox-wrapper-mail">
                                        <input type="checkbox" id="chk13" />
                                        <label htmlFor="chk13" className="toggle"></label>
                                    </div>
                                    <div className="d-flex title align-items-center">
                                        <img src={avatar4} className="avatar-sm rounded-circle" alt="" />
                                        <div className="flex-1 ms-2 ps-1">
                                            <h5 className="font-size-14 mb-0"><Link to="" className="text-dark">me, Peter (5)</Link></h5>
                                            <Link to="" className="text-muted text-uppercase font-size-12">07 Threads</Link>
                                        </div>
                                    </div>
                                    <span className="star-toggle far fa-star"></span>
                                </div>
                                <div className="col-mail col-mail-2">
                                    <Link to="#" className="subject text-dark"> <span className="bg-info badge me-2">Support</span> Home again! –  <span className="teaser text-muted fw-normal">That's just perfect! See you tomorrow.</span>
                                    </Link>
                                    <div className="date"><i className="mdi mdi-link-variant me-2 font-size-15 align-middle"></i> 6:56 PM</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-7">
                        Showing 1 - 20 of 1,524
                    </div>
                    <div className="col-5">
                        <div className="btn-group float-end">
                            <button type="button" className="btn btn-sm btn-success waves-effect"><i className="fa fa-chevron-left"></i></button>
                            <button type="button" className="btn btn-sm btn-success waves-effect"><i className="fa fa-chevron-right"></i></button>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
};

export default Inbox;