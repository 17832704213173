import React from "react";
import Routes from "./routes/index";

import "./assets/scss/themes.scss";

// Fake Backend 
import fakeBackend from "./helpers/AuthType/fakeBackend";
fakeBackend();

function App() {
  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}

export default App;
