import React, { useEffect } from 'react';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { setBreadcrumb } from "../../store/actions";

// import Components
import MyFiles from './MyFiles';
import UsageStorage from './UsageStorage';

const FileManager = () => {
    const dispatch = useDispatch();
    /*
    set breadcrumbs
    */
    useEffect(() => {
        const breadCrumbItems = {
            title: "Dashboard Saas",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    return (
        <React.Fragment>
            <MetaTags>
                <title>File Manager | Painel Gomus</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={9}>
                            <Card>
                                <CardBody>
                                    <MyFiles />
                                </CardBody>
                            </Card>
                        </Col>
                        <UsageStorage />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default FileManager;
