import React, { useState, useEffect } from 'react';
import { Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Modal, Row, UncontrolledDropdown } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";


// Import Images
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";
import { Link } from 'react-router-dom';

const UserList = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "UserList",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [modal_user, setmodal_user] = useState(false);
    const [modal_success, setmodal_success] = useState(false);

    function tog_user() {
        setmodal_user(!modal_user);
    }
    function tog_success() {
        setmodal_success(!modal_success);
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>User List | Painel Gomus</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>

                    <Row className="align-items-center">
                        <Col md={6}>
                            <div className="mb-3">
                                <h5 className="card-title">Contact List <span className="text-muted fw-normal ms-2">(834)</span></h5>
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                <div>
                                    <ul className="nav nav-pills">
                                        <li className="nav-item">
                                            <Link className="nav-link active" to="/contacts-list" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="List" aria-label="List"><i className="bx bx-list-ul"></i></Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/contacts-grid" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Grid" aria-label="Grid"><i className="bx bx-grid-alt"></i></Link>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <Link to="#" data-bs-toggle="modal" data-bs-target=".add-new" className="btn btn-primary" onClick={() => tog_user()}><i className="bx bx-plus me-1" ></i> Add New</Link>
                                </div>


                                <UncontrolledDropdown className="dropdown">
                                    <DropdownToggle tag="a" className="btn btn-link text-muted py-1 font-size-16 shadow-none">
                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Action</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>


                            </div>

                        </Col>
                    </Row>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="table-responsive">
                                    <table className="table project-list-table table-nowrap align-middle table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="ps-4" style={{ width: "50px" }}>
                                                    <div className="form-check font-size-16">
                                                        <input type="checkbox" className="form-check-input" id="contacusercheck" />
                                                        <label className="form-check-label" htmlFor="contacusercheck"></label>
                                                    </div>
                                                </th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Position</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Projects</th>
                                                <th scope="col" style={{ width: "200px" }}>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <th scope="row" className="ps-4">
                                                    <div className="form-check font-size-16">
                                                        <input type="checkbox" className="form-check-input" id="contacusercheck1" />
                                                        <label className="form-check-label" htmlFor="contacusercheck1"></label>
                                                    </div>
                                                </th>
                                                <td>
                                                    <img src={avatar2} alt="" className="avatar-sm rounded-circle me-2" />
                                                    <Link to="#" className="text-body">Simon Ryles</Link>
                                                </td>
                                                <td><span className="badge badge-soft-success mb-0">Full Stack Developer</span></td>
                                                <td>SimonRyles@minible.com</td>
                                                <td>125</td>
                                                <td>
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" className="px-2 text-primary"><i className="bx bx-pencil font-size-18"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" className="px-2 text-danger"><i className="bx bx-trash-alt font-size-18"></i></Link>
                                                        </li>


                                                        <li className="list-inline-item dropdown">
                                                            <UncontrolledDropdown className="dropdown">
                                                                <DropdownToggle tag="a" className="text-muted font-size-18 px-2">
                                                                    <i className="bx bx-dots-vertical-rounded"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end">
                                                                    <DropdownItem>Action</DropdownItem>
                                                                    <DropdownItem>Another action</DropdownItem>
                                                                    <DropdownItem>Something else here</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>

                                                    </ul>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th scope="row" className="ps-4">
                                                    <div className="form-check font-size-16">
                                                        <input type="checkbox" className="form-check-input" id="contacusercheck2" />
                                                        <label className="form-check-label" htmlFor="contacusercheck2"></label>
                                                    </div>
                                                </th>
                                                <td>
                                                    <img src={avatar3} alt="" className="avatar-sm rounded-circle me-2" />
                                                    <Link to="#" className="text-body">Marion Walker</Link>
                                                </td>
                                                <td><span className="badge badge-soft-info mb-0">Frontend Developer</span></td>
                                                <td>MarionWalker@minible.com</td>
                                                <td>132</td>
                                                <td>
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" className="px-2 text-primary"><i className="bx bx-pencil font-size-18"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" className="px-2 text-danger"><i className="bx bx-trash-alt font-size-18"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item dropdown">
                                                            <UncontrolledDropdown className="dropdown">
                                                                <DropdownToggle tag="a" className="text-muted font-size-18 px-2">
                                                                    <i className="bx bx-dots-vertical-rounded"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end">
                                                                    <DropdownItem>Action</DropdownItem>
                                                                    <DropdownItem>Another action</DropdownItem>
                                                                    <DropdownItem>Something else here</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th scope="row" className="ps-4">
                                                    <div className="form-check font-size-16">
                                                        <input type="checkbox" className="form-check-input" id="contacusercheck3" />
                                                        <label className="form-check-label" htmlFor="contacusercheck3"></label>
                                                    </div>
                                                </th>
                                                <td>
                                                    <div className="avatar-sm d-inline-block me-2">
                                                        <div className="avatar-title bg-soft-primary rounded-circle text-primary">
                                                            <i className="mdi mdi-account-circle m-0"></i>
                                                        </div>
                                                    </div>
                                                    <Link to="#" className="text-body">Frederick White</Link>
                                                </td>
                                                <td><span className="badge badge-soft-danger mb-0">UI/UX Designer</span></td>
                                                <td>FrederickWhite@minible.com</td>
                                                <td>112</td>
                                                <td>
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" className="px-2 text-primary"><i className="bx bx-pencil font-size-18"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" className="px-2 text-danger"><i className="bx bx-trash-alt font-size-18"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item dropdown">
                                                            <UncontrolledDropdown className="dropdown">
                                                                <DropdownToggle tag="a" className="text-muted font-size-18 px-2">
                                                                    <i className="bx bx-dots-vertical-rounded"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end">
                                                                    <DropdownItem>Action</DropdownItem>
                                                                    <DropdownItem>Another action</DropdownItem>
                                                                    <DropdownItem>Something else here</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th scope="row" className="ps-4">
                                                    <div className="form-check font-size-16">
                                                        <input type="checkbox" className="form-check-input" id="contacusercheck4" />
                                                        <label className="form-check-label" htmlFor="contacusercheck4"></label>
                                                    </div>
                                                </th>
                                                <td>
                                                    <img src={avatar4} alt="" className="avatar-sm rounded-circle me-2" />
                                                    <Link to="#" className="text-body">Shanon Marvin</Link>
                                                </td>
                                                <td><span className="badge badge-soft-primary mb-0">Backend Developer</span></td>
                                                <td>ShanonMarvin@minible.com</td>
                                                <td>121</td>
                                                <td>
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" className="px-2 text-primary"><i className="bx bx-pencil font-size-18"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" className="px-2 text-danger"><i className="bx bx-trash-alt font-size-18"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item dropdown">
                                                            <UncontrolledDropdown className="dropdown">
                                                                <DropdownToggle tag="a" className="text-muted font-size-18 px-2">
                                                                    <i className="bx bx-dots-vertical-rounded"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end">
                                                                    <DropdownItem>Action</DropdownItem>
                                                                    <DropdownItem>Another action</DropdownItem>
                                                                    <DropdownItem>Something else here</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th scope="row" className="ps-4">
                                                    <div className="form-check font-size-16">
                                                        <input type="checkbox" className="form-check-input" id="contacusercheck5" />
                                                        <label className="form-check-label" htmlFor="contacusercheck5"></label>
                                                    </div>
                                                </th>
                                                <td>
                                                    <div className="avatar-sm d-inline-block me-2">
                                                        <div className="avatar-title bg-soft-primary rounded-circle text-primary">
                                                            <i className="mdi mdi-account-circle m-0"></i>
                                                        </div>
                                                    </div>
                                                    <Link to="#" className="text-body">Mark Jones</Link>
                                                </td>
                                                <td><span className="badge badge-soft-info mb-0">Frontend Developer</span></td>
                                                <td>MarkJones@minible.com</td>
                                                <td>145</td>
                                                <td>
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" className="px-2 text-primary"><i className="bx bx-pencil font-size-18"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" className="px-2 text-danger"><i className="bx bx-trash-alt font-size-18"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item dropdown">
                                                            <Link className="text-muted dropdown-toggle font-size-18 px-2" to="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                <i className="bx bx-dots-vertical-rounded"></i>
                                                            </Link>

                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                <Link className="dropdown-item" to="#">Edit</Link>
                                                                <Link className="dropdown-item" to="#">Action</Link>
                                                                <Link className="dropdown-item" to="#">Remove</Link>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th scope="row" className="ps-4">
                                                    <div className="form-check font-size-16">
                                                        <input type="checkbox" className="form-check-input" id="contacusercheck6" />
                                                        <label className="form-check-label" htmlFor="contacusercheck6"></label>
                                                    </div>
                                                </th>
                                                <td>
                                                    <img src={avatar5} alt="" className="avatar-sm rounded-circle me-2" />
                                                    <Link to="#" className="text-body">Janice Morgan</Link>
                                                </td>
                                                <td><span className="badge badge-soft-primary mb-0">Backend Developer</span></td>
                                                <td>JaniceMorgan@minible.com</td>
                                                <td>136</td>
                                                <td>
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" className="px-2 text-primary"><i className="bx bx-pencil font-size-18"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" className="px-2 text-danger"><i className="bx bx-trash-alt font-size-18"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item dropdown">
                                                            <UncontrolledDropdown className="dropdown">
                                                                <DropdownToggle tag="a" className="text-muted font-size-18 px-2">
                                                                    <i className="bx bx-dots-vertical-rounded"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end">
                                                                    <DropdownItem>Action</DropdownItem>
                                                                    <DropdownItem>Another action</DropdownItem>
                                                                    <DropdownItem>Something else here</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th scope="row" className="ps-4">
                                                    <div className="form-check font-size-16">
                                                        <input type="checkbox" className="form-check-input" id="contacusercheck7" />
                                                        <label className="form-check-label" htmlFor="contacusercheck7"></label>
                                                    </div>
                                                </th>
                                                <td>
                                                    <img src={avatar7} alt="" className="avatar-sm rounded-circle me-2" />
                                                    <Link to="#" className="text-body">Patrick Petty</Link>
                                                </td>
                                                <td><span className="badge badge-soft-danger mb-0">UI/UX Designer</span></td>
                                                <td>PatrickPetty@minible.com</td>
                                                <td>125</td>
                                                <td>
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" className="px-2 text-primary"><i className="bx bx-pencil font-size-18"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" className="px-2 text-danger"><i className="bx bx-trash-alt font-size-18"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item dropdown">
                                                            <UncontrolledDropdown className="dropdown">
                                                                <DropdownToggle tag="a" className="text-muted font-size-18 px-2">
                                                                    <i className="bx bx-dots-vertical-rounded"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end">
                                                                    <DropdownItem>Action</DropdownItem>
                                                                    <DropdownItem>Another action</DropdownItem>
                                                                    <DropdownItem>Something else here</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th scope="row" className="ps-4">
                                                    <div className="form-check font-size-16">
                                                        <input type="checkbox" className="form-check-input" id="contacusercheck8" />
                                                        <label className="form-check-label" htmlFor="contacusercheck8"></label>
                                                    </div>
                                                </th>
                                                <td>
                                                    <img src={avatar8} alt="" className="avatar-sm rounded-circle me-2" />
                                                    <Link to="#" className="text-body">Marilyn Horton</Link>
                                                </td>
                                                <td><span className="badge badge-soft-primary mb-0">Backend Developer</span></td>
                                                <td>MarilynHorton@minible.com</td>
                                                <td>154</td>
                                                <td>
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" className="px-2 text-primary"><i className="bx bx-pencil font-size-18"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" className="px-2 text-danger"><i className="bx bx-trash-alt font-size-18"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item dropdown">
                                                            <UncontrolledDropdown className="dropdown">
                                                                <DropdownToggle tag="a" className="text-muted font-size-18 px-2">
                                                                    <i className="bx bx-dots-vertical-rounded"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end">
                                                                    <DropdownItem>Action</DropdownItem>
                                                                    <DropdownItem>Another action</DropdownItem>
                                                                    <DropdownItem>Something else here</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th scope="row" className="ps-4">
                                                    <div className="form-check font-size-16">
                                                        <input type="checkbox" className="form-check-input" id="contacusercheck9" />
                                                        <label className="form-check-label" htmlFor="contacusercheck9"></label>
                                                    </div>
                                                </th>
                                                <td>
                                                    <img src={avatar2} alt="" className="avatar-sm rounded-circle me-2" />
                                                    <Link to="#" className="text-body">Neal Womack</Link>
                                                </td>
                                                <td><span className="badge badge-soft-success mb-0">Full Stack Developer</span></td>
                                                <td>NealWomack@minible.com</td>
                                                <td>231</td>
                                                <td>
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" className="px-2 text-primary"><i className="bx bx-pencil font-size-18"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Link to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" className="px-2 text-danger"><i className="bx bx-trash-alt font-size-18"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item dropdown">
                                                            <UncontrolledDropdown className="dropdown">
                                                                <DropdownToggle tag="a" className="text-muted font-size-18 px-2">
                                                                    <i className="bx bx-dots-vertical-rounded"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end">
                                                                    <DropdownItem>Action</DropdownItem>
                                                                    <DropdownItem>Another action</DropdownItem>
                                                                    <DropdownItem>Something else here</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row g-0 align-items-center pb-4">
                        <div className="col-sm-6">
                            <div>
                                <p className="mb-sm-0">Showing 1 to 10 of 57 entries</p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="float-sm-end">
                                <ul className="pagination mb-sm-0">
                                    <li className="page-item disabled">
                                        <Link to="#" className="page-link"><i className="mdi mdi-chevron-left"></i></Link>
                                    </li>
                                    <li className="page-item active">
                                        <Link to="#" className="page-link">1</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link">2</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link">3</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link">4</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link">5</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link"><i className="mdi mdi-chevron-right"></i></Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </Container>
            </div>


            {/* Add New Modal */}
            <Modal
                size="xl"
                isOpen={modal_user}
                toggle={() => {
                    tog_user();
                }}
                className="new-customer"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="myExtraLargeModalLabel">
                        {" "}
                        Add New{" "}
                    </h5>

                    <button
                        onClick={() => {
                            setmodal_user(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddNew-Username">Username</label>
                                <input type="text" className="form-control" placeholder="Enter Username" id="AddNew-Username" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Position</label>
                                <select className="form-select">
                                    <option selected>Select Position</option>
                                    <option>Full Stack Developer</option>
                                    <option>Frontend Developer</option>
                                    <option>UI/UX Designer</option>
                                    <option>Backend Developer</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddNew-Email">Email</label>
                                <input type="text" className="form-control" placeholder="Enter Email" id="AddNew-Email" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddNew-Phone">Phone</label>
                                <input type="text" className="form-control" placeholder="Enter Phone" id="AddNew-Phone" />
                            </div>
                        </div>
                    </div>
                    <Row className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setmodal_user(false);
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Cancel
                            </button>

                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                                onClick={() => {
                                    setmodal_success(true);
                                    setmodal_user(false);
                                }}
                            >
                                <i className="bx bx-check me-1"></i> Confirm
                            </button>
                        </div>
                    </Row>
                </div>
            </Modal>

            {/* Success Modal */}
            <Modal
                isOpen={modal_success}
                toggle={() => {
                    tog_success();
                }}
                id="success-btn"
                centered
            >
                <div className="modal-body">
                    <div className="text-center">
                        <i className="bx bx-check-circle display-1 text-success"></i>
                        <h3 className="mt-3">User Added Successfully</h3>
                    </div>
                </div>
            </Modal>



        </React.Fragment>
    );
};

export default UserList;



