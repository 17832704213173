import React from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
//Simple bar
import SimpleBar from "simplebar-react";

//import images
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";

const Chat = () => {
    return (
        <React.Fragment>
            <div className="col-xl-4">
                <div className="card">
                    <div className="p-3 border-bottom">
                        <div className="row">
                            <div className="col-xl-4 col-7">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 avatar me-3 d-sm-block d-none">
                                        <img src={avatar2} alt="" className="img-thumbnail d-block rounded-circle" />
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="font-size-14 mb-1 text-truncate"><Link to="#" className="text-dark">Steven Jones</Link></h5>
                                        <p className="text-muted text-truncate mb-0"><i className="mdi mdi-circle font-size-12 text-success"></i> Online</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-5">
                                <ul className="list-inline user-chat-nav text-end mb-0">
                                    <li className="list-inline-item">
                                        <div className="dropdown">
                                            <button className="btn nav-btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="bx bx-search"></i>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-end dropdown-menu-md p-2">
                                                <form className="px-2">
                                                    <div>
                                                        <input type="text" className="form-control bg-light rounded" placeholder="Search..." />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-inline-item">
                                        <UncontrolledDropdown>
                                            <DropdownToggle className="btn nav-btn dropdown-toggle" type="button" tag="a">
                                                <i className="bx bx-dots-horizontal-rounded"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end">
                                                <DropdownItem>Profile</DropdownItem>
                                                <DropdownItem>Archive</DropdownItem>
                                                <DropdownItem>Muted</DropdownItem>
                                                <DropdownItem>Delete</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <SimpleBar className="chat-conversation p-3 widget-chat" data-simplebar>
                            <ul className="list-unstyled mb-0">
                                <li className="chat-day-title">
                                    <div className="title">Today</div>
                                </li>
                                <li>
                                    <div className="conversation-list">
                                        <div className="d-flex">
                                            <img src={avatar3} className="rounded-circle avatar-sm" alt="" />
                                            <div className="flex-1">
                                                <div className="ctext-wrap">
                                                    <div className="ctext-wrap-content">
                                                        <div className="conversation-name"><span className="time">10:00 AM</span></div>
                                                        <p className="mb-0">Good Morning</p>
                                                    </div>
                                                    <UncontrolledDropdown className="align-self-start">
                                                        <DropdownToggle type="button" tag="a">
                                                            <i className="bx bx-dots-vertical-rounded"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem>Copy</DropdownItem>
                                                            <DropdownItem>Save</DropdownItem>
                                                            <DropdownItem>Forward</DropdownItem>
                                                            <DropdownItem>Delete</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li className="right">
                                    <div className="conversation-list">
                                        <div className="d-flex">
                                            <div className="flex-1">
                                                <div className="ctext-wrap">
                                                    <div className="ctext-wrap-content">
                                                        <div className="conversation-name"><span className="time">10:02 AM</span></div>
                                                        <p className="mb-0">Good morning</p>
                                                    </div>
                                                    <UncontrolledDropdown className="align-self-start">
                                                        <DropdownToggle type="button" tag="a">
                                                            <i className="bx bx-dots-vertical-rounded"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem>Copy</DropdownItem>
                                                            <DropdownItem>Save</DropdownItem>
                                                            <DropdownItem>Forward</DropdownItem>
                                                            <DropdownItem>Delete</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </div>
                                            <img src={avatar6} className="rounded-circle avatar-sm" alt="" />
                                        </div>

                                    </div>

                                </li>

                                <li>
                                    <div className="conversation-list">
                                        <div className="d-flex">
                                            <img src={avatar3} className="rounded-circle avatar-sm" alt="" />
                                            <div className="flex-1">
                                                <div className="ctext-wrap">
                                                    <div className="ctext-wrap-content">
                                                        <div className="conversation-name"><span className="time">10:04 AM</span></div>
                                                        <p className="mb-0">
                                                            Hi there, How are you?
                                                        </p>
                                                    </div>
                                                    <UncontrolledDropdown className="align-self-start">
                                                        <DropdownToggle type="button" tag="a">
                                                            <i className="bx bx-dots-vertical-rounded"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem>Copy</DropdownItem>
                                                            <DropdownItem>Save</DropdownItem>
                                                            <DropdownItem>Forward</DropdownItem>
                                                            <DropdownItem>Delete</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex">
                                            <img src={avatar3} className="rounded-circle avatar-sm" alt="" />
                                            <div className="flex-1">
                                                <div className="ctext-wrap">
                                                    <div className="ctext-wrap-content">
                                                        <div className="conversation-name"><span className="time">10:04 AM</span></div>
                                                        <p className="mb-0">
                                                            Waiting for your reply.As I heve to go back soon. i have to travel long distance.
                                                        </p>
                                                    </div>
                                                    <UncontrolledDropdown className="align-self-start">
                                                        <DropdownToggle type="button" tag="a">
                                                            <i className="bx bx-dots-vertical-rounded"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem>Copy</DropdownItem>
                                                            <DropdownItem>Save</DropdownItem>
                                                            <DropdownItem>Forward</DropdownItem>
                                                            <DropdownItem>Delete</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </li>

                                <li className="right">
                                    <div className="conversation-list">
                                        <div className="d-flex">
                                            <div className="flex-1">
                                                <div className="ctext-wrap">
                                                    <div className="ctext-wrap-content">
                                                        <div className="conversation-name"><span className="time">10:08 AM</span></div>
                                                        <p className="mb-0">
                                                            Hi, I am coming there in few minutes. Please wait!! I am in taxi right now.
                                                        </p>
                                                    </div>
                                                    <UncontrolledDropdown className="align-self-start">
                                                        <DropdownToggle type="button" tag="a">
                                                            <i className="bx bx-dots-vertical-rounded"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem>Copy</DropdownItem>
                                                            <DropdownItem>Save</DropdownItem>
                                                            <DropdownItem>Forward</DropdownItem>
                                                            <DropdownItem>Delete</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </div>
                                            <img src={avatar6} className="rounded-circle avatar-sm" alt="" />
                                        </div>
                                    </div>

                                </li>

                                <li>
                                    <div className="conversation-list">
                                        <div className="d-flex">
                                            <img src={avatar3} className="rounded-circle avatar-sm" alt="" />
                                            <div className="flex-1">
                                                <div className="ctext-wrap">
                                                    <div className="ctext-wrap-content">
                                                        <div className="conversation-name"><span className="time">10:06 AM</span></div>
                                                        <p className="mb-0">
                                                            Thank You very much, I am waiting here at StarBuck cafe.
                                                        </p>
                                                    </div>
                                                    <UncontrolledDropdown className="align-self-start">
                                                        <DropdownToggle type="button" tag="a">
                                                            <i className="bx bx-dots-vertical-rounded"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem>Copy</DropdownItem>
                                                            <DropdownItem>Save</DropdownItem>
                                                            <DropdownItem>Forward</DropdownItem>
                                                            <DropdownItem>Delete</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </SimpleBar>
                    </div>
                    <div className="p-3 chat-input-section">
                        <div className="row">
                            <div className="col">
                                <div className="position-relative">
                                    <input type="text" className="form-control chat-input" placeholder="Enter Message..." />

                                </div>
                            </div>
                            <div className="col-auto">
                                <button type="submit" className="btn btn-primary chat-send w-md"><span className="d-none d-sm-inline-block me-2">Send</span> <i className="mdi mdi-send float-end"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Chat;