import React from 'react';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

//SimpleBar
import SimpleBar from "simplebar-react";
import { LineChart1, LineChart10, LineChart2, LineChart3, LineChart4, LineChart5, LineChart6, LineChart7, LineChart8, LineChart9 } from './CryptoMarketCharts';

const MarketCap = () => {
    return (
        <React.Fragment>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h5 className="card-title mb-3">Cryptocurrency Prices by Market Cap</h5>
                            </div>
                            <div className="flex-shrink-0">
                                <UncontrolledDropdown>
                                    <DropdownToggle className="text-reset" to="#" type="button" tag="a">
                                        <span className="fw-semibold">Report By:</span> <span
                                            className="text-muted">Monthly<i
                                                className="mdi mdi-chevron-down ms-1"></i></span>
                                    </DropdownToggle>

                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Yearly</DropdownItem>
                                        <DropdownItem>Monthly</DropdownItem>
                                        <DropdownItem>Weekly</DropdownItem>
                                        <DropdownItem>Today</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </div>

                        <SimpleBar className="table-responsive" style={{ maxHeight: "400px" }}>
                            <table className="table align-middle table-striped table-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th className="align-middle">#</th>
                                        <th className="align-middle">Coin</th>
                                        <th className="align-middle" style={{ width: '280px' }}></th>
                                        <th className="align-middle">Price</th>
                                        <th className="align-middle">1h</th>
                                        <th className="align-middle">24h</th>
                                        <th className="align-middle">24h volume</th>
                                        <th className="align-middle">Mkt Cap</th>
                                        <th className="align-middle">Last 7 Days</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><i className="mdi mdi-star-outline font-size-18 me-2 align-middle"></i>1</td>
                                        <td className="fw-semibold"><i className="mdi mdi-bitcoin font-size-24 text-warning align-middle me-2"></i>Bitcoin</td>
                                        <td>
                                            BTC <span className="badge badge-soft-success">Buy</span>
                                        </td>
                                        <td>
                                            $52,635.25
                                        </td>
                                        <td>
                                            <span className="text-danger"> -0.4%</span>
                                        </td>
                                        <td>
                                            <span className="text-danger"> -0.7%</span>
                                        </td>
                                        <td>
                                            $65,235,352,524
                                        </td>
                                        <td>
                                            $2,632,452,652,632
                                        </td>
                                        <td>
                                            <LineChart1  id="chart-1"/>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><i className="mdi mdi-star-outline font-size-18 me-2 align-middle"></i>2</td>
                                        <td className="fw-semibold"><i className="mdi mdi-ethereum font-size-24 text-muted align-middle me-2"></i>Ethereum</td>
                                        <td>
                                            ETH <span className="badge badge-soft-success">Buy</span>
                                        </td>
                                        <td>
                                            $4,718.09
                                        </td>
                                        <td>
                                            <span className="text-danger"> -0.4%</span>
                                        </td>
                                        <td>
                                            <span className="text-success"> 6.6%</span>
                                        </td>
                                        <td>
                                            $32,768,536,239
                                        </td>
                                        <td>
                                            $560,017,311,187
                                        </td>
                                        <td>
                                            <LineChart2 id="chart-2" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><i className="mdi mdi-star-outline font-size-18 me-2 align-middle"></i>3</td>
                                        <td className="fw-semibold"><i className="mdi mdi-litecoin font-size-24 text-primary align-middle me-2"></i>Litecoin</td>
                                        <td>
                                            LTC
                                        </td>
                                        <td>
                                            $52,635.25
                                        </td>
                                        <td>
                                            <span className="text-success"> -0.1%</span>
                                        </td>
                                        <td>
                                            <span className="text-success"> -0.5%</span>
                                        </td>
                                        <td>
                                            $23,154,356,321
                                        </td>
                                        <td>
                                            $1,235,321,541,231
                                        </td>
                                        <td>
                                            <LineChart3 id="chart-3" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><i className="mdi mdi-star-outline font-size-18 me-2 align-middle"></i>4</td>
                                        <td className="fw-semibold"><i className="mdi mdi-shape-polygon-plus font-size-24 text-warning align-middle me-2"></i>Polygon</td>
                                        <td>
                                            MATIC <span className="badge badge-soft-success">Buy</span>
                                        </td>
                                        <td>
                                            $1.90
                                        </td>
                                        <td>
                                            <span className="text-success"> 0.4%</span>
                                        </td>
                                        <td>
                                            <span className="text-danger"> -0.7%</span>
                                        </td>
                                        <td>
                                            $1,286,361,294
                                        </td>
                                        <td>
                                            $12,954,356,323
                                        </td>
                                        <td>
                                            <LineChart4 id="chart-4" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><i className="mdi mdi-star-outline font-size-18 me-2 align-middle"></i>5</td>
                                        <td className="fw-semibold"><i className="mdi mdi-judaism font-size-24 text-primary align-middle me-2"></i>Dai</td>
                                        <td>
                                            Dai
                                        </td>
                                        <td>
                                            $0.997608
                                        </td>
                                        <td>
                                            <span className="text-danger"> -0.4%</span>
                                        </td>
                                        <td>
                                            <span className="text-Success"> 0.0%</span>
                                        </td>
                                        <td>
                                            $760,103,372
                                        </td>
                                        <td>
                                            $8,848,073,381
                                        </td>
                                        <td>
                                            <LineChart5 id="chart-5" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><i className="mdi mdi-star-outline font-size-18 me-2 align-middle"></i>6</td>
                                        <td className="fw-semibold"><i className="mdi mdi-chart-line-stacked font-size-24 text-success align-middle me-2"></i>The Graph</td>
                                        <td>
                                            GRT <span className="badge badge-soft-success">Buy</span>
                                        </td>
                                        <td>
                                            $0.957746
                                        </td>
                                        <td>
                                            <span className="text-success"> 3.9%	</span>
                                        </td>
                                        <td>
                                            <span className="text-success"> 4.5%</span>
                                        </td>
                                        <td>
                                            $178,723,251
                                        </td>
                                        <td>
                                            $4,664,906,946
                                        </td>
                                        <td>
                                            <LineChart6 id="chart-6" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><i className="mdi mdi-star-outline font-size-18 me-2 align-middle"></i>7</td>
                                        <td className="fw-semibold"><i className="mdi mdi-bitcoin font-size-24 text-warning align-middle me-2"></i>Bitcoin</td>
                                        <td>
                                            BTC <span className="badge badge-soft-success">Buy</span>
                                        </td>
                                        <td>
                                            $52,635.25
                                        </td>
                                        <td>
                                            <span className="text-danger"> -0.4%</span>
                                        </td>
                                        <td>
                                            <span className="text-danger"> -0.7%</span>
                                        </td>
                                        <td>
                                            $65,235,352,524
                                        </td>
                                        <td>
                                            $2,632,452,652,632
                                        </td>
                                        <td>
                                        <LineChart7 id="chart-7" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><i className="mdi mdi-star-outline font-size-18 me-2 align-middle"></i>8</td>
                                        <td className="fw-semibold"><i className="mdi mdi-ethereum font-size-24 text-muted align-middle me-2"></i>Ethereum</td>
                                        <td>
                                            ETH <span className="badge badge-soft-success">Buy</span>
                                        </td>
                                        <td>
                                            $4,718.09
                                        </td>
                                        <td>
                                            <span className="text-danger"> -0.4%</span>
                                        </td>
                                        <td>
                                            <span className="text-success"> 6.6%</span>
                                        </td>
                                        <td>
                                            $32,768,536,239
                                        </td>
                                        <td>
                                            $560,017,311,187
                                        </td>
                                        <td>
                                        <LineChart8 id="chart-8" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><i className="mdi mdi-star-outline font-size-18 me-2 align-middle"></i>9</td>
                                        <td className="fw-semibold"><i className="mdi mdi-litecoin font-size-24 text-primary align-middle me-2"></i>Litecoin</td>
                                        <td>
                                            LTC
                                        </td>
                                        <td>
                                            $52,635.25
                                        </td>
                                        <td>
                                            <span className="text-success"> -0.1%</span>
                                        </td>
                                        <td>
                                            <span className="text-success"> -0.5%</span>
                                        </td>
                                        <td>
                                            $23,154,356,321
                                        </td>
                                        <td>
                                            $1,235,321,541,231
                                        </td>
                                        <td>
                                        <LineChart9 id="chart-9" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><i className="mdi mdi-star-outline font-size-18 me-2 align-middle"></i>10</td>
                                        <td className="fw-semibold"><i className="mdi mdi-shape-polygon-plus font-size-24 text-warning align-middle me-2"></i>Polygon</td>
                                        <td>
                                            MATIC <span className="badge badge-soft-success">Buy</span>
                                        </td>
                                        <td>
                                            $1.90
                                        </td>
                                        <td>
                                            <span className="text-success"> 0.4%</span>
                                        </td>
                                        <td>
                                            <span className="text-danger"> -0.7%</span>
                                        </td>
                                        <td>
                                            $1,286,361,294
                                        </td>
                                        <td>
                                            $12,954,356,323
                                        </td>
                                        <td>
                                        <LineChart10 id="chart-10" />
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </SimpleBar>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default MarketCap;