import React, { useState } from 'react';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, } from 'reactstrap';

const Toolbar = () => {
    const [folder_Menu, setfolder_Menu] = useState(false);
    const [tag_Menu, settag_Menu] = useState(false);
    const [more_Menu, setmore_Menu] = useState(false);
    return (
        <React.Fragment>
            <Row className="row mb-4">
                <Col xl={3} md={12}>
                    <div className="pb-3 pb-xl-0">
                        <form className="email-search">
                            <div className="position-relative">
                                <input type="text" className="form-control" placeholder="Search..." />
                                <span className="bx bx-search font-size-18"></span>
                            </div>
                        </form>
                    </div>
                </Col>
                <Col xl={9} md={12}>
                    <div className="pb-3 pb-xl-0">
                        <div className="btn-toolbar float-end" role="toolbar">
                            <div className="btn-group me-2 mb-2 mb-sm-0">
                                <button type="button" className="btn btn-primary waves-light waves-effect"><i className="fa fa-inbox"></i></button>
                                <button type="button" className="btn btn-primary waves-light waves-effect"><i className="fa fa-exclamation-circle"></i></button>
                                <button type="button" className="btn btn-primary waves-light waves-effect"><i className="far fa-trash-alt"></i></button>
                            </div>
                            <Dropdown
                                isOpen={folder_Menu}
                                toggle={() => {
                                    setfolder_Menu(!folder_Menu);
                                }}
                                className="btn-group me-2 mb-2 mb-sm-0"
                            >
                                <DropdownToggle className="btn btn-primary  dropdown-toggle" tag="i">
                                    <i className="fa fa-folder" />{" "}
                                    <i className="mdi mdi-chevron-down ms-1" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem to="#">Updates</DropdownItem>
                                    <DropdownItem to="#">Social</DropdownItem>
                                    <DropdownItem to="#">Team Manage</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown
                                isOpen={tag_Menu}
                                toggle={() => {
                                    settag_Menu(!tag_Menu);
                                }}
                                className="btn-group me-2 mb-2 mb-sm-0"
                            >
                                <DropdownToggle className="btn btn-primary  dropdown-toggle" tag="i">
                                    <i className="fa fa-tag" />{" "}
                                    <i className="mdi mdi-chevron-down ms-1" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem to="#">Updates</DropdownItem>
                                    <DropdownItem to="#">Social</DropdownItem>
                                    <DropdownItem to="#">Team Manage</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>

                            <Dropdown
                                isOpen={more_Menu}
                                toggle={() => {
                                    setmore_Menu(!more_Menu);
                                }}
                                className="btn-group me-2 mb-2 mb-sm-0"
                            >
                                <DropdownToggle
                                    className="btn btn-primary  dropdown-toggle"
                                    tag="div"
                                >
                                    More <i className="mdi mdi-dots-vertical ms-2" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem to="#">Mark as Unread</DropdownItem>
                                    <DropdownItem to="#">Mark as Important</DropdownItem>
                                    <DropdownItem to="#">Add to Tasks</DropdownItem>
                                    <DropdownItem to="#">Add Star</DropdownItem>
                                    <DropdownItem to="#">Mute</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Toolbar;