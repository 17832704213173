import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

const Trading = () => {
    return (
        <React.Fragment>
            <Col xl={6}>
                <Card>
                    <CardBody>

                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h5 className="card-title mb-4">Trading</h5>
                            </div>
                            <div className="flex-shrink-0">
                                <UncontrolledDropdown>
                                    <DropdownToggle className="text-reset" to="#" type="button" tag="a">
                                        <i className="mdi mdi-wallet me-1"></i> <span className="d-none d-sm-inline-block">Wallet Balance <i className="mdi mdi-chevron-down"></i></span>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end dropdown-menu-md">
                                        <div className="dropdown-item-text">
                                            <div>
                                                <p className="text-muted mb-2">Available Balance</p>
                                                <h5 className="mb-0">$ 9148.23</h5>
                                            </div>
                                        </div>

                                        <div className="dropdown-divider"></div>

                                        <DropdownItem>
                                            BTC : <span className="float-end">1.02356</span>
                                        </DropdownItem>
                                        <DropdownItem>
                                            ETH : <span className="float-end">0.04121</span>
                                        </DropdownItem>
                                        <DropdownItem>
                                            LTC : <span className="float-end">0.00356</span>
                                        </DropdownItem>

                                        <div className="dropdown-divider"></div>

                                        <DropdownItem className="dropdown-item text-primary text-center fw-semibold" to="#">
                                            Learn more
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </div>
                        <ul className="nav nav-pills bg-light rounded" role="tablist">
                            <li className="nav-item">
                                <Link className="nav-link active" data-bs-toggle="tab" to="#buy-tab" role="tab" aria-selected="true">Buy</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" data-bs-toggle="tab" to="#sell-tab" role="tab" aria-selected="false">Sell</Link>
                            </li>
                        </ul>

                        <div className="tab-content mt-4" style={{ minHeight: "340px" }}>
                            <div className="tab-pane active" id="buy-tab" role="tabpanel">
                                <form>
                                    <div className="mb-2">
                                        <label>Currency :</label>

                                        <div className="row">
                                            <div className="col-xl-4 col-sm-4">
                                                <div className="mb-3">
                                                    <label className="card-radio-label mb-2">
                                                        <input type="radio" name="currency" id="buycurrencyoption1" className="card-radio-input" defaultChecked />
                                                        <span className="card-radio">
                                                            <i className="mdi mdi-bitcoin font-size-24 text-warning align-middle me-2"></i>
                                                            <span>Bitcoin</span>
                                                        </span>
                                                    </label>

                                                    <div>
                                                        <p className="text-muted font-size-13 mb-1 text-truncate">Current price :</p>
                                                        <h5 className="font-size-14">0.00016 BTC</h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 col-sm-4">
                                                <div className="mb-3">
                                                    <label className="card-radio-label mb-2">
                                                        <input type="radio" name="currency" id="buycurrencyoption2" className="card-radio-input" />
                                                        <span className="card-radio">
                                                            <i className="mdi mdi-ethereum font-size-24 text-primary align-middle me-2"></i>
                                                            <span>Ethereum</span>
                                                        </span>
                                                    </label>

                                                    <div>
                                                        <p className="text-muted font-size-13 mb-1 text-truncate">Current price :</p>
                                                        <h5 className="font-size-14">0.0073 ETH</h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 col-sm-4">
                                                <div className="mb-3">
                                                    <label className="card-radio-label mb-2">
                                                        <input type="radio" name="currency" id="buycurrencyoption3" className="card-radio-input" />

                                                        <span className="card-radio">
                                                            <i className="mdi mdi-litecoin font-size-24 text-info align-middle me-2"></i>
                                                            <span>litecoin</span>
                                                        </span>
                                                    </label>

                                                    <div>
                                                        <p className="text-muted font-size-13 mb-1 text-truncate">Current price :</p>
                                                        <h5 className="font-size-14">0.025 LTC</h5>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="mb-2">
                                        <label>Payment method :</label>

                                        <div className="row">
                                            <div className="col-xl-6 col-sm-4">
                                                <label className="card-radio-label mb-3">
                                                    <input type="radio" name="pay-method" id="pay-methodoption1" className="card-radio-input" />

                                                    <span className="card-radio">
                                                        <i className="fab fa-cc-mastercard font-size-20 text-primary align-middle me-2"></i>
                                                        <span>Credit / Debit Card</span>
                                                    </span>
                                                </label>
                                            </div>

                                            <div className="col-xl-6 col-sm-4">
                                                <label className="card-radio-label mb-3">
                                                    <input type="radio" name="pay-method" id="pay-methodoption3" className="card-radio-input" defaultChecked />

                                                    <span className="card-radio">
                                                        <i className="fab fa-cc-paypal font-size-20 text-primary align-middle me-2"></i>
                                                        <span>Paypal</span>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label>Add value :</label>

                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="input-group mb-2 currency-value">
                                                    <span className="input-group-text">Bitcoin</span>

                                                    <input type="text" className="form-control" placeholder="Bitcoin" />
                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="input-group mb-2">
                                                    <input type="text" className="form-control text-sm-end" placeholder="USD Amount" />

                                                    <span className="input-group-text">USD Amount</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        <button type="button" className="btn btn-success bg-gradient">Buy Crypto currency</button>
                                    </div>
                                </form>
                            </div>

                            <div className="tab-pane" id="sell-tab" role="tabpanel">
                                <form>
                                    <div className="mb-2">
                                        <label>Currency :</label>

                                        <div className="row">
                                            <div className="col-xl-4 col-sm-4">
                                                <div className="mb-3">
                                                    <label className="card-radio-label mb-2">
                                                        <input type="radio" name="currency" id="sellcurrencyoption1" className="card-radio-input" defaultChecked />

                                                        <span className="card-radio">
                                                            <i className="mdi mdi-bitcoin font-size-24 text-warning align-middle me-2"></i>
                                                            <span>Bitcoin</span>
                                                        </span>
                                                    </label>
                                                    <div>
                                                        <p className="text-muted font-size-13 mb-1">Current price :</p>
                                                        <h5 className="font-size-14">0.00016 BTC</h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 col-sm-4">
                                                <div className="mb-3">
                                                    <label className="card-radio-label mb-2">
                                                        <input type="radio" name="currency" id="sellcurrencyoption2" className="card-radio-input" />

                                                        <span className="card-radio">
                                                            <i className="mdi mdi-ethereum font-size-24 text-primary align-middle me-2"></i>
                                                            <span>Ethereum</span>
                                                        </span>
                                                    </label>
                                                    <div>
                                                        <p className="text-muted font-size-13 mb-1">Current price :</p>
                                                        <h5 className="font-size-14">0.0073 ETH</h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 col-sm-4">
                                                <div className="mb-3">
                                                    <label className="card-radio-label mb-2">
                                                        <input type="radio" name="currency" id="sellcurrencyoption3" className="card-radio-input" />

                                                        <span className="card-radio">
                                                            <i className="mdi mdi-litecoin font-size-24 text-info align-middle me-2"></i>
                                                            <span>litecoin</span>
                                                        </span>
                                                    </label>

                                                    <div>
                                                        <p className="text-muted font-size-13 mb-1">Current price :</p>
                                                        <h5 className="font-size-14">0.025 LTC</h5>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="mb-4">
                                        <label>Email :</label>
                                        <input type="email" placeholder="Enter Email" className="form-control" />
                                    </div>

                                    <div className="mb-3">
                                        <label>Add value :</label>

                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="input-group mb-2 currency-value">
                                                    <span className="input-group-text">Crypto value</span>
                                                    <input type="text" placeholder="Crypto Value" className="form-control" />
                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="input-group mb-2">
                                                    <input type="text" placeholder="USD Amount" className="form-control text-sm-end" />

                                                    <span className="input-group-text">USD Amount</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        <button type="button" className="btn btn-danger bg-gradient">Sell Crypto currency</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default Trading;