import React from 'react'

const Footer = () => {
    return (
        <React.Fragment>            
                <div className="p-3 px-4 sidebar-footer">
                    <p className="mb-1 main-title"><script>{new Date().getFullYear()}</script> &copy; Borex.</p>
                    <p className="mb-0">Design & Develop by Themesbrand</p>
                </div>
        </React.Fragment>
    )
}

export default Footer
