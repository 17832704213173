import React from 'react';
import { Card, Col, Row } from 'reactstrap';

import { ChartSparkline1, ChartSparkline2, ChartSparkline3 } from "./CryptoCharts";

const Widget = () => {
    return (
        <React.Fragment>
            <Row>
                <Col xl={4} lg={6}>
                    <Card>
                        <div className="card-body">
                            <div className="d-flex align-items-center overflow-hidden">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar">
                                        <div className="avatar-title rounded bg-primary bg-gradient">
                                            <i className="mdi mdi-bitcoin font-size-24"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                    <h5 className="font-size-15 mb-2">BTC<span className="text-muted">/BIDR</span></h5>
                                    <p className="text-muted mb-0">26.5049</p>
                                </div>
                                <div className="flex-shrink-0 float-end">
                                    <div id="chart-sparkline1">
                                        <ChartSparkline1 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col xl={4} lg={6}>
                    <Card>
                        <div className="card-body">
                            <div className="d-flex align-items-center overflow-hidden">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar">
                                        <div className="avatar-title rounded bg-primary bg-gradient">
                                            <i className="mdi mdi-ethereum font-size-24"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                    <h5 className="font-size-15 mb-2">ETH<span className="text-muted">/BIDR</span></h5>
                                    <p className="text-muted mb-0">9,562.00</p>
                                </div>
                                <div className="flex-shrink-0 float-end">
                                    <div id="chart-sparkline2">
                                        <ChartSparkline2 />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Card>
                </Col>
                <Col xl={4}>
                    <Card>
                        <div className="card-body">
                            <div className="d-flex align-items-center overflow-hidden">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar">
                                        <div className="avatar-title rounded bg-primary bg-gradient">
                                            <i className="mdi mdi-litecoin font-size-24"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <h5 className="font-size-15 mb-2">LTC<span className="text-muted">/BIDR</span></h5>
                                    <p className="text-muted mb-0">209.11</p>
                                </div>
                                <div className="flex-shrink-0 float-end">
                                    <div id="chart-sparkline3">
                                        <ChartSparkline3 />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Widget;