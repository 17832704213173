import { SET_BREADCRUMB } from "./actionType";

export const INIT_STATE = {
  breadCrumbItems: {},
};

const BreadCrumb = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_BREADCRUMB:
      return {
        ...state,
        breadCrumbItems: action.payload,
      };
    default:
      return { ...state };
  }
};

export default BreadCrumb;
