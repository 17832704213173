import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid >
                    <Row>
                        <Col sm={12} >
                            {new Date().getFullYear()} &copy; Borex. Design & Develop by Themesbrand
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
