import React from 'react';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';

// Import Images
import flagUs from "../../assets/images/flags/us.jpg";
import flagSpain from "../../assets/images/flags/spain.jpg";
import flagGermany from "../../assets/images/flags/germany.jpg";
import flagItaly from "../../assets/images/flags/italy.jpg";

import Vector from './VectorMap';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination"

// import Swiper core and required modules
import SwiperCore, {
  Pagination
} from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination]);

const GlobalStatistics = (props) => {
    return (
        <React.Fragment>
            <Col xl={7}>
                <Card className="bg-primary bg-gradient">
                    <CardBody>
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h5 className="card-title text-white mb-3">Global Statistics</h5>
                            </div>
                            <div className="flex-shrink-0">
                                <UncontrolledDropdown>
                                    <DropdownToggle to="#" type="button" tag="a">
                                        <i data-eva="more-horizontal-outline" className="fill-white"
                                            data-eva-height="18" data-eva-width="18"></i>
                                    </DropdownToggle>

                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Yearly</DropdownItem>
                                        <DropdownItem>Monthly</DropdownItem>
                                        <DropdownItem>Weekly</DropdownItem>
                                        <DropdownItem>Today</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </div>

                        <Row>
                            <Col lg={7}>
                                <div id="world-map-markers" style={{ height: "275px" }}>
                                    <Vector
                                        value="world_mill"
                                        color="rgb(255, 255, 255, 0.1)"
                                    />
                                </div>
                            </Col>

                            <Col lg={5}>
                                <div>
                                    <Swiper slidesPerView={3} spaceBetween={24} direction={'vertical'} autoplay={{"delay": 2500, "disableOnInteraction": false}} loop={true} className="mySwiper swiper-location-widget overflow-hidden">
                                        <div className="swiper-wrapper">

                                            <SwiperSlide >
                                                <div className="card" >
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-start">
                                                            <div className="flex-shrink-0 align-self-center me-3">
                                                                <div className="avatar-sm">
                                                                    <div className="avatar-title bg-light rounded">
                                                                        <img src={flagUs} alt="" height="10" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <p className="text-muted mb-1">United States</p>
                                                                <h5 className="font-size-16 mb-0">81%</h5>
                                                            </div>
                                                            <div className="flex-shrink-0 align-self-end ms-2">
                                                                <div className="text-muted"><i data-eva="diagonal-arrow-right-up" data-eva-width="18" data-eva-height="18" className="align-text-bottom fill-success me-1"></i> 1.05%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide >
                                                <div className="card" >
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-start">
                                                            <div className="flex-shrink-0 align-self-center me-3">
                                                                <div className="avatar-sm">
                                                                    <div className="avatar-title bg-light rounded">
                                                                        <img src={flagSpain} alt="" height="10" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <p className="text-muted mb-1">Spain</p>
                                                                <h5 className="font-size-16 mb-0">77%</h5>
                                                            </div>
                                                            <div className="flex-shrink-0 align-self-end ms-2">
                                                                <div className="text-muted"><i data-eva="diagonal-arrow-right-up" data-eva-width="18" data-eva-height="18" className="align-text-bottom fill-success me-1"></i> 0.24%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </SwiperSlide>
                                            <SwiperSlide >
                                                <div className="card" >
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-start">
                                                            <div className="flex-shrink-0 align-self-center me-3">
                                                                <div className="avatar-sm">
                                                                    <div className="avatar-title bg-light rounded">
                                                                        <img src={flagGermany} alt="" height="10" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <p className="text-muted mb-1">Germany</p>
                                                                <h5 className="font-size-16 mb-0">80%</h5>
                                                            </div>
                                                            <div className="flex-shrink-0 align-self-end ms-2">
                                                                <div className="text-muted"><i data-eva="diagonal-arrow-right-up" data-eva-width="18" data-eva-height="18" className="align-text-bottom fill-success me-1"></i> 0.18%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </SwiperSlide>
                                            <SwiperSlide >
                                                <div className="card" >
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-start">
                                                            <div className="flex-shrink-0 align-self-center me-3">
                                                                <div className="avatar-sm">
                                                                    <div className="avatar-title bg-light rounded">
                                                                        <img src={flagItaly} alt="" height="10" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <p className="text-muted mb-1">Italy</p>
                                                                <h5 className="font-size-16 mb-0">73%</h5>
                                                            </div>
                                                            <div className="flex-shrink-0 align-self-end ms-2">
                                                                <div className="text-muted"><i data-eva="diagonal-arrow-right-up" data-eva-width="18" data-eva-height="18" className="align-text-bottom fill-success me-1"></i> 0.15%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </SwiperSlide>
                                        </div>
                                    </Swiper>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default GlobalStatistics;