const yup = require("yup");

export const userValidationSchema = (entity = {}) => {
  return (
    yup.object().shape({
        name: yup
            .string()
            .required("Campo obrigatório")
        ,
        username: yup.string()
            .required("Campo obrigatório")
            .email("Deve ser um e-mail válido")
            .trim()
        ,
        password: entity?.identifier ? yup.string() : yup
            .string()
            .required("Campo obrigatório")
            .min(6, "A senha deve conter no mínimo 6 caracteres")
        ,
    })
  );
}
