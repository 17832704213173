import React, { useEffect } from 'react';
import MetaTags from "react-meta-tags";
import { Col, Container, Row } from 'reactstrap';

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";


const DashBoard = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const breadCrumbItems = {
            title: "Dashboard",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);
    return (
        <React.Fragment>
             <MetaTags>
                <title>Dashboard | Painel Gomus</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xxl={12}>
                            Dashboard
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DashBoard;
