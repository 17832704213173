import React from 'react';
import ReactApexChart from "react-apexcharts";

const LineChart1 = () => {
    const Data = {
        series: [
            {
                data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
            },
        ],

        options: {
            chart: {
                type: 'line',
                width: 120,
                height: 30,
                sparkline: {
                    enabled: true
                }
            },
            colors: ["#f56e6e"],
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            tooltip: {
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
                marker: {
                    show: false
                }
            },
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={Data.options}
                series={Data.series}
                type="line"
                height={30}
                width={120}
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const LineChart2 = () => {
    const Data = {
        series: [
            {
                data: [50, 15, 35, 62, 23, 56, 44, 12, 36, 9, 54]
            },
        ],

        options: {


            chart: {
                type: 'line',
                width: 120,
                height: 30,
                sparkline: {
                    enabled: true
                }

            },
            colors: ["#f56e6e"],
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            tooltip: {
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
                marker: {
                    show: false
                }
            },
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={Data.options}
                series={Data.series}
                type="line"
                height={30}
                width={120}
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const LineChart3 = () => {
    const Data = {
        series: [
            {
                data: [25, 35, 35, 89, 63, 25, 44, 12, 36, 9, 54]
            },
        ],

        options: {


            chart: {
                type: 'line',
                width: 120,
                height: 30,
                sparkline: {
                    enabled: true
                }

            },
            colors: ["#63ad6f"],
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            tooltip: {
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
                marker: {
                    show: false
                }
            }
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={Data.options}
                series={Data.series}
                type="line"
                height={30}
                width={120}
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const LineChart4 = () => {
    const Data = {
        series: [
            {
                data: [50, 15, 35, 34, 23, 56, 65, 41, 36, 41, 32]
            },
        ],

        options: {


            chart: {
                type: 'line',
                width: 120,
                height: 30,
                sparkline: {
                    enabled: true
                }

            },
            colors: ["#f56e6e"],
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            tooltip: {
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
                marker: {
                    show: false
                }
            }
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={Data.options}
                series={Data.series}
                type="line"
                height={30}
                width={120}
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const LineChart5 = () => {
    const Data = {
        series: [
            {
                data: [45, 53, 24, 89, 63, 60, 36, 50, 36, 32, 54]
            },
        ],

        options: {


            chart: {
                type: 'line',
                width: 120,
                height: 30,
                sparkline: {
                    enabled: true
                }

            },
            colors: ["#63ad6f"],
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            tooltip: {
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
                marker: {
                    show: false
                }
            }
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={Data.options}
                series={Data.series}
                type="line"
                height={30}
                width={120}
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const LineChart6 = () => {
    const Data = {
        series: [
            {
                data: [50, 15, 35, 62, 23, 56, 44, 12, 36, 9, 54]
            },
        ],

        options: {


            chart: {
                type: 'line',
                width: 120,
                height: 30,
                sparkline: {
                    enabled: true
                }

            },
            colors: ["#63ad6f"],
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            tooltip: {
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
                marker: {
                    show: false
                }
            }
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={Data.options}
                series={Data.series}
                type="line"
                height={30}
                width={120}
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const LineChart7 = () => {
    const Data = {
        series: [
            {
                data: [63, 41, 20, 34, 42, 62, 35, 42, 36, 21, 56]
            },
        ],

        options: {


            chart: {
                type: 'line',
                width: 120,
                height: 30,
                sparkline: {
                    enabled: true
                }

            },
            colors: ["#f56e6e"],
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            tooltip: {
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
                marker: {
                    show: false
                }
            }
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={Data.options}
                series={Data.series}
                type="line"
                height={30}
                width={120}
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const LineChart8 = () => {
    const Data = {
        series: [
            {
                data: [50, 15, 35, 62, 23, 56, 44, 12, 36, 9, 54]
            },
        ],

        options: {


            chart: {
                type: 'line',
                width: 120,
                height: 30,
                sparkline: {
                    enabled: true
                }

            },
            colors: ["#63ad6f"],
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            tooltip: {
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
                marker: {
                    show: false
                }
            }
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={Data.options}
                series={Data.series}
                type="line"
                height={30}
                width={120}
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const LineChart9 = () => {
    const Data = {
        series: [
            {
                data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
            },
        ],

        options: {


            chart: {
                type: 'line',
                width: 120,
                height: 30,
                sparkline: {
                    enabled: true
                }

            },
            colors: ["#63ad6f"],
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            tooltip: {
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
                marker: {
                    show: false
                }
            }
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={Data.options}
                series={Data.series}
                type="line"
                height={30}
                width={120}
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const LineChart10 = () => {
    const Data = {
        series: [
            {
                data: [63, 42, 25, 63, 24, 56, 44, 63, 36, 24, 30]
            },
        ],

        options: {


            chart: {
                type: 'line',
                width: 120,
                height: 30,
                sparkline: {
                    enabled: true
                }

            },
            colors: ["#f56e6e"],
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            tooltip: {
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
                marker: {
                    show: false
                }
            }
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={Data.options}
                series={Data.series}
                type="line"
                height={30}
                width={120}
                className="apex-charts"
            />
        </React.Fragment>
    );
};

export { LineChart1, LineChart2, LineChart3, LineChart4, LineChart5, LineChart6, LineChart7, LineChart8, LineChart9, LineChart10 };

