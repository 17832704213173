import React from 'react';
import ReactApexChart from "react-apexcharts";

const ChartSparkline1 = () => {
    const series = [{
        data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14]
    }];
    var sparklineoptions1 = {
        chart: {
            type: 'area',
            width: 85,
            height: 30,
            sparkline: {
                enabled: true
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [20, 100, 100, 100]
            },
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        colors: ["#63ad6f"],
        tooltip: {
            fixed: {
                enabled: false
            },
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function (seriesName) {
                        return '';
                    }
                }
            },
            marker: {
                show: false
            }
        }
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={sparklineoptions1}
                series={series}
                type="area"
                height="30"
                width="85"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const ChartSparkline2 = () => {
    const series = [{
        data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
    }];
    var sparklineoptions2 = {
        chart: {
            type: 'area',
            width: 85,
            height: 30,
            sparkline: {
                enabled: true
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [20, 100, 100, 100]
            },
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        colors: ["#f56e6e"],
        tooltip: {
            fixed: {
                enabled: false
            },
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function (seriesName) {
                        return '';
                    }
                }
            },
            marker: {
                show: false
            }
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={sparklineoptions2}
                series={series}
                type="area"
                height="30"
                width="85"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const ChartSparkline3 = () => {
    const series = [{
        data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
    }];
    var sparklineoptions3 = {
        chart: {
            type: 'area',
            width: 85,
            height: 30,
            sparkline: {
                enabled: true
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [20, 100, 100, 100]
            },
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        colors: ["#f56e6e"],
        tooltip: {
            fixed: {
                enabled: false
            },
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function (seriesName) {
                        return '';
                    }
                }
            },
            marker: {
                show: false
            }
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={sparklineoptions3}
                series={series}
                type="area"
                height="30"
                width="85"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const ChartSparkline4 = () => {
    const series = [{
        data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
    }];
    var sparklineoptions2 = {
        chart: {
            type: 'area',
            width: 85,
            height: 30,
            sparkline: {
                enabled: true
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [20, 100, 100, 100]
            },
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        colors: ["#63ad6f"],
        tooltip: {
            fixed: {
                enabled: false
            },
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function (seriesName) {
                        return '';
                    }
                }
            },
            marker: {
                show: false
            }
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={sparklineoptions2}
                series={series}
                type="area"
                height="30"
                width="85"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

export { ChartSparkline1, ChartSparkline2, ChartSparkline3, ChartSparkline4 };