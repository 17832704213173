import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';

//import images
import img3 from "../../../assets/images/small/img-3.jpg";
import img2 from "../../../assets/images/small/img-2.jpg";
import img4 from "../../../assets/images/small/img-4.jpg";
import Toolbar from '../Toolbar';

const ReadEmail = () => {
    return (
        <React.Fragment>
            <div className="email-rightbar mb-3">
                <Toolbar />
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-center mb-4">
                            <div className="flex-shrink-0 me-3">
                                <img className="rounded-circle avatar-sm" src={img2} alt="" />
                            </div>
                            <div className="flex-grow-1">
                                <h5 className="font-size-14 mb-0">Humberto D. Champion</h5>
                                <small className="text-muted">support@domain.com</small>
                            </div>
                        </div>

                        <h4 className="font-size-16">This Week's Top Stories</h4>

                        <p>Dear Lorem Ipsum,</p>
                        <p>Praesent dui ex, dapibus eget mauris ut, finibus vestibulum enim. Quisque arcu leo, facilisis in fringilla id, luctus in tortor. Nunc vestibulum est quis orci varius viverra. Curabitur dictum volutpat massa vulputate molestie. In at felis ac velit maximus convallis.
                        </p>
                        <p>Sed elementum turpis eu lorem interdum, sed porttitor eros commodo. Nam eu venenatis tortor, id lacinia diam. Sed aliquam in dui et porta. Sed bibendum orci non tincidunt ultrices. Vivamus fringilla, mi lacinia dapibus condimentum, ipsum urna lacinia lacus, vel tincidunt mi nibh sit amet lorem.</p>
                        <p>Sincerly,</p>
                        <hr />

                        <Row>
                            <Col xl={2} xs={6}>
                                <div className="card">
                                    <img className="card-img-top img-fluid" src={img3} alt="" />
                                    <div className="py-2 text-center">
                                        <Link to="#" className="fw-medium">Download</Link>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={2} xs={6}>
                                <div className="card">
                                    <img className="card-img-top img-fluid" src={img4} alt="" />
                                    <div className="py-2 text-center">
                                        <Link to="#" className="fw-medium">Download</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Link to="#" className="btn btn-secondary waves-effect mt-4"><i className="mdi mdi-reply me-1"></i> Reply</Link>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
};

export default ReadEmail;