import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Modal, Row } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";


const UiModal = () => {

    const [modal_standard, setmodal_standard] = useState(false);
    const [modal_fullscreen, setmodal_fullscreen] = useState(false);
    const [modal_xlarge, setmodal_xlarge] = useState(false);
    const [modal_large, setmodal_large] = useState(false);
    const [modal_small, setmodal_small] = useState(false);
    const [modal_center, setmodal_center] = useState(false);
    const [modal_scroll, setmodal_scroll] = useState(false);
    const [modal_backdrop, setmodal_backdrop] = useState(false);
    const [modal_togSecond, setmodal_togSecond] = useState(false);
    const [modal_togFirst, setmodal_togFirst] = useState(false);


    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    function tog_fullscreen() {
        setmodal_fullscreen(!modal_fullscreen);
    }

    function tog_xlarge() {
        setmodal_xlarge(!modal_xlarge);
    }

    function tog_large() {
        setmodal_large(!modal_large);
    }

    function tog_small() {
        setmodal_small(!modal_small);
    }

    function tog_center() {
        setmodal_center(!modal_center);
    }

    function tog_scroll() {
        setmodal_scroll(!modal_scroll);
    }

    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
    }

    function tog_togSecond() {
        setmodal_togSecond(!modal_togSecond);
    }

    function tog_togFirst() {
        setmodal_togFirst(!modal_togFirst);
    }


    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Modals",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);
    return (
        <React.Fragment>
            <MetaTags>
                <title>Modals | Painel Gomus</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Modals Examples</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Modals are streamlined, but flexible
                                        dialog prompts powered by JavaScript. They support a number of use cases
                                        from user notification to completely custom content and feature a
                                        handful of helpful subcomponents, sizes, and more.</p>

                                    <div className="modal bs-example-modal" tabIndex="-1" role="dialog">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Modal title</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <p>One fine body&hellip;</p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-primary">Save changes</button>
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Default Modal</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Toggle a working modal demo by clicking the button below. It will slide down and fade in from the top of the page.</p>
                                    <div>
                                        <button type="button" onClick={() => { tog_standard() }} className="btn btn-primary waves-light">Standard modal</button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Fullscreen Modal</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Another override is the option to pop up a modal that covers the user viewport, available via modifier classes that are placed on a <code>.modal-fullscreen</code>.</p>
                                    <div>
                                        <button type="button" className="btn btn-primary waves-light" onClick={() => { tog_fullscreen() }} >Fullscreen modal</button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Optional Sizes</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Modals have three optional sizes, available via modifier classes to be placed on a <code>.modal-dialog</code>.</p>
                                    <div className="d-flex flex-wrap gap-2">
                                        <button type="button" className="btn btn-primary waves-light" onClick={() => { tog_xlarge() }}>Extra large modal</button>
                                        <button type="button" className="btn btn-light" onClick={() => { tog_large() }}>Large modal</button>
                                        <button type="button" className="btn btn-success waves-light" onClick={() => { tog_small() }}>Small modal</button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title">Vertically Centered</h5>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Add <code>.modal-dialog-centered</code> to <code>.modal-dialog</code> to vertically center the modal.</p>
                                    <div>
                                        <button type="button" className="btn btn-primary waves-light" onClick={() => { tog_center() }}>Center modal</button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title">Scrollable Modal</h5>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">You can also create a scrollable modal that allows scroll the modal body by adding <code>.modal-dialog-scrollable</code> to <code>.modal-dialog</code>.</p>
                                    <button type="button" className="btn btn-primary waves-light" onClick={() => { tog_scroll() }}>Scrollable Modal</button>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title">Static Backdrop</h5>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">When backdrop is set to static, the modal will not close when clicking outside it. Click the button below to try it.</p>
                                    <div>
                                        <button type="button" className="btn btn-primary waves-light" onClick={() => { tog_backdrop() }}>
                                            Static backdrop modal
                                        </button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Toggle between modals</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Toggle between multiple modals with some clever placement of the <code>data-bs-target</code> and <code>data-bs-toggle</code> attributes.</p>
                                    <div>
                                        <button type="button" className="btn btn-primary waves-light" onClick={() => { tog_togFirst() }}>Open First Modal</button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Standard Modal */}

            <Modal id="myModal"
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title"
                        id="myModalLabel"
                    >
                        Default Modal Heading
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        aria-label="Close"

                    >

                    </button>
                </div>
                <div className="modal-body">
                    <h5>Overflowing text to show scroll behavior</h5>
                    <p>Cras mattis consectetur purus sit amet fermentum.
                        Cras justo odio, dapibus ac facilisis in,
                        egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.</p>
                    <p>Praesent commodo cursus magna, vel scelerisque
                        nisl consectetur et. Vivamus sagittis lacus vel
                        augue laoreet rutrum faucibus dolor auctor.</p>
                    <p>Aenean lacinia bibendum nulla sed consectetur.
                        Praesent commodo cursus magna, vel scelerisque
                        nisl consectetur et. Donec sed odio dui. Donec
                        ullamcorper nulla non metus auctor
                        fringilla.</p>
                    <p>Cras mattis consectetur purus sit amet fermentum.
                        Cras justo odio, dapibus ac facilisis in,
                        egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.</p>
                    <p>Praesent commodo cursus magna, vel scelerisque
                        nisl consectetur et. Vivamus sagittis lacus vel
                        augue laoreet rutrum faucibus dolor auctor.</p>
                    <p>Aenean lacinia bibendum nulla sed consectetur.
                        Praesent commodo cursus magna, vel scelerisque
                        nisl consectetur et. Donec sed odio dui. Donec
                        ullamcorper nulla non metus auctor
                        fringilla.</p>
                    <p>Cras mattis consectetur purus sit amet fermentum.
                        Cras justo odio, dapibus ac facilisis in,
                        egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.</p>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard();
                        }}
                        className="btn btn-secondary "
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary "
                    >
                        Save changes
                    </button>
                </div>
            </Modal>


            {/* Full Screen Modal */}

            <Modal
                size="xl"
                isOpen={modal_fullscreen}
                toggle={() => {
                    tog_fullscreen();
                }}
                className="modal-fullscreen"
                id="exampleModalFullscreen"
            >
                <div className="modal-header">
                    <h5
                        className="modal-title"
                        id="exampleModalFullscreenLabel"
                    >
                        Fullscreen Modal
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_fullscreen(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    >

                    </button>
                </div>
                <div className="modal-body">
                    <h5>Overflowing text to show scroll behavior</h5>
                    <p>Cras mattis consectetur purus sit amet fermentum.
                        Cras justo odio, dapibus ac facilisis in,
                        egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.</p>
                    <p>Praesent commodo cursus magna, vel scelerisque
                        nisl consectetur et. Vivamus sagittis lacus vel
                        augue laoreet rutrum faucibus dolor auctor.</p>
                    <p>Aenean lacinia bibendum nulla sed consectetur.
                        Praesent commodo cursus magna, vel scelerisque
                        nisl consectetur et. Donec sed odio dui. Donec
                        ullamcorper nulla non metus auctor
                        fringilla.</p>
                    <p>Cras mattis consectetur purus sit amet fermentum.
                        Cras justo odio, dapibus ac facilisis in,
                        egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.</p>
                    <p>Praesent commodo cursus magna, vel scelerisque
                        nisl consectetur et. Vivamus sagittis lacus vel
                        augue laoreet rutrum faucibus dolor auctor.</p>
                    <p>Aenean lacinia bibendum nulla sed consectetur.
                        Praesent commodo cursus magna, vel scelerisque
                        nisl consectetur et. Donec sed odio dui. Donec
                        ullamcorper nulla non metus auctor
                        fringilla.</p>
                    <p>Cras mattis consectetur purus sit amet fermentum.
                        Cras justo odio, dapibus ac facilisis in,
                        egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.</p>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_fullscreen();
                        }}
                        className="btn btn-secondary"
                        data-dismiss="modal"
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary "
                    >
                        Save changes
                    </button>
                </div>
            </Modal>

            {/* Extra Large Modal */}

            <Modal
                size="xl"
                isOpen={modal_xlarge}
                toggle={() => {
                    tog_xlarge();
                }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title"
                        id="myExtraLargeModalLabel"
                    >
                        Extra large modal
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_xlarge(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    >

                    </button>
                </div>
                <div className="modal-body">
                    <p>Cras mattis consectetur purus sit amet fermentum.
                        Cras justo odio, dapibus ac facilisis in,
                        egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.</p>
                    <p>Praesent commodo cursus magna, vel scelerisque
                        nisl consectetur et. Vivamus sagittis lacus vel
                        augue laoreet rutrum faucibus dolor auctor.</p>
                    <p className="mb-0">Aenean lacinia bibendum nulla sed consectetur.
                        Praesent commodo cursus magna, vel scelerisque
                        nisl consectetur et. Donec sed odio dui. Donec
                        ullamcorper nulla non metus auctor
                        fringilla.</p>
                </div>
            </Modal>


            {/* Large Modal */}

            <Modal
                size="lg"
                isOpen={modal_large}
                toggle={() => {
                    tog_large();
                }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title"
                        id="myLargeModalLabel"
                    >
                        Large Modal
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_large(false);
                        }}
                        type="button"
                        className="btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >

                    </button>
                </div>
                <div className="modal-body">
                    <p>Cras mattis consectetur purus sit amet fermentum.
                        Cras justo odio, dapibus ac facilisis in,
                        egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.</p>
                    <p>Praesent commodo cursus magna, vel scelerisque
                        nisl consectetur et. Vivamus sagittis lacus vel
                        augue laoreet rutrum faucibus dolor auctor.</p>
                    <p className="mb-0">Aenean lacinia bibendum nulla sed consectetur.
                        Praesent commodo cursus magna, vel scelerisque
                        nisl consectetur et. Donec sed odio dui. Donec
                        ullamcorper nulla non metus auctor
                        fringilla.</p>
                </div>
            </Modal>

            {/* Small Modal */}

            <Modal
                size="sm"
                isOpen={modal_small}
                toggle={() => {
                    tog_small();
                }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title"
                        id="mySmallModalLabel"
                    >
                        Small Modal
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_small(false);
                        }}
                        type="button"
                        className="btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >

                    </button>
                </div>
                <div className="modal-body">
                    <p>Cras mattis consectetur purus sit amet fermentum.
                        Cras justo odio, dapibus ac facilisis in,
                        egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.</p>
                    <p>Praesent commodo cursus magna, vel scelerisque
                        nisl consectetur et. Vivamus sagittis lacus vel
                        augue laoreet rutrum faucibus dolor auctor.</p>
                    <p className="mb-0">Aenean lacinia bibendum nulla sed consectetur.
                        Praesent commodo cursus magna, vel scelerisque
                        nisl consectetur et. Donec sed odio dui. Donec
                        ullamcorper nulla non metus auctor
                        fringilla.</p>
                </div>
            </Modal>


            {/* Centered */}

            <Modal
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title">
                        Center Modal
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_center(false);
                        }}
                        className="btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >

                    </button>
                </div>
                <div className="modal-body">
                    <p>Cras mattis consectetur purus sit amet fermentum.
                        Cras justo odio, dapibus ac facilisis in,
                        egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.</p>
                    <p>Praesent commodo cursus magna, vel scelerisque
                        nisl consectetur et. Vivamus sagittis lacus vel
                        augue laoreet rutrum faucibus dolor auctor.</p>
                    <p className="mb-0">Aenean lacinia bibendum nulla sed consectetur.
                        Praesent commodo cursus magna, vel scelerisque
                        nisl consectetur et. Donec sed odio dui. Donec
                        ullamcorper nulla non metus auctor
                        fringilla.</p>
                </div>
            </Modal>


            {/* Scrollable Modal  */}

            <Modal
                isOpen={modal_scroll}
                toggle={() => {
                    tog_scroll();
                }}
                scrollable={true}
                id="exampleModalScrollable"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalScrollableTitle">
                        Scrollable modal
                    </h5>
                    <button
                        type="button"
                        onClick={() => setmodal_scroll(false)}
                        className="btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >

                    </button>
                </div>
                <div className="modal-body">
                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_scroll();
                        }}
                        className="btn btn-secondary "
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary "
                    >
                        Save changes
                    </button>
                </div>
            </Modal>


            {/* Static Backdrop */}

            <Modal
                isOpen={modal_backdrop}
                toggle={() => {
                    tog_backdrop();
                }}
                backdrop={'static'}
                id="staticBackdrop"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Modal title</h5>
                    <button type="button" className="btn-close"
                        onClick={() => {
                            setmodal_backdrop(false);
                        }} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <p>I will not close if you click outside me. Don't even try to press escape key.</p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" onClick={() => {
                        setmodal_backdrop(false);
                    }}>Close</button>
                    <button type="button" className="btn btn-primary">Understood</button>
                </div>
            </Modal>


            {/* Toggle Between Modal */}

            <Modal
                isOpen={modal_togFirst}
                toggle={() => {
                    tog_togFirst();
                }}
                id="firstmodal"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalToggleLabel">
                        Modal 1
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setmodal_togFirst(false);
                        }}
                        aria-label="Close"

                    >

                    </button>
                </div>
                <div className="modal-body">
                    <p>Show a second modal and hide this one with the button below.</p>
                </div>
                <div className="modal-footer">

                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            tog_togSecond();
                            tog_togFirst(false);
                        }}
                    >
                        Open Second Modal
                    </button>
                </div>
            </Modal>

            <Modal
                isOpen={modal_togSecond}
                toggle={() => {
                    tog_togSecond();
                }}
                id="secondmodal"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalToggleLabel2">Modal 2</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setmodal_togSecond(false);
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body">
                    <p>Hide this modal and show the first with the button below.</p>
                </div>
                <div className="modal-footer">

                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            tog_togFirst();
                            tog_togSecond(false);
                        }}
                    >
                        Back to First
                    </button>
                </div>
            </Modal>

        </React.Fragment>
    );
};

export default UiModal;
