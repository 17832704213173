import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
// redux
import { useSelector } from "react-redux";

const NonAuthLayout = (props) => {

  const { layoutMode } = useSelector((state) => ({
    layoutMode: state.Layout.layoutMode,
  }));

  useEffect(() => {
    if (layoutMode === "dark") {
      document.body.setAttribute("data-layout-mode", "dark");
    } else {
      document.body.setAttribute("data-layout-mode", "light");
    }
  }, [layoutMode]);
  return (
    <React.Fragment>{props.children}</React.Fragment>
  );
};

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
};

export default withRouter(NonAuthLayout);
