import React, { useEffect } from 'react';
import Icon from 'react-eva-icons';
import MetaTags from "react-meta-tags";

// redux
import 'react-toastify/dist/ReactToastify.css';

import { ErrorMessage, Field, Formik } from 'formik';
import { APIClient } from '../../helpers/api_helper';

// Import Images
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { default as logodark, default as logolight } from "../../assets/images/logo.png";

import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import AuthSlider from '../Auth/authCarousel';
import { loginInitialValues } from './helpers/loginInitialValues';
import { loginValidationSchema } from './helpers/loginValidationSchema';

const Login = () => {
    const [viewPassword, setViewPassword] = useState(false);

    const api = new APIClient();

    const saveData = (values, setSubmitting) => {
        setSubmitting(true);

        api
            .postWithoutBearer('/auth/check', {
                username: values.email,
                password: values.password,
            })
            .then((response) => {
                var token = response.token;
                localStorage.setItem('session-token', token);

                api
                    .get('/profile/show', {
                        headers: {
                            Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                        },
                    })
                    .then((profile) => {
                        localStorage.setItem('profile-role', profile.role);
                        localStorage.setItem('profile-identifier', profile.identifier);
                        localStorage.setItem('profile-name', profile.name);
                        localStorage.setItem('profile-email', profile.username);                        

                        window.location.href = '/dashboard';

                        setSubmitting(false);
                        return;
                    })
                ;

                setSubmitting(false);
            })
            .catch((error) => {
                setSubmitting(false);
                if (error.message === "Invalid credentials.") {
                    toast.error("Combinação de e-mail e senha inválida.");
                } else {
                    toast.error("Algo deu errado, tente novamente mais tarde!");
                }
            })
        ;
    }

    useEffect(() => {
        document.body.className = "bg-transparent";

        return function cleanup() {
            document.body.className = "";
        };
    });

    return (
        <React.Fragment>
            <MetaTags>
                <title>Login | Painel Gomus</title>
            </MetaTags>
            <ToastContainer />
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0 align-items-center">
                        <Col className="col-xxl-4 col-lg-4 col-md-6">
                            <Row className="justify-content-center g-0">
                                <Col xl={9}>
                                    <div className="p-4">
                                        <Card className="mb-0">
                                            <CardBody>
                                                <div className="auth-full-page-content rounded d-flex p-3 my-2">
                                                    <div className="w-100">
                                                        <div className="d-flex flex-column h-100">
                                                            <div className="mb-4 mb-md-5">
                                                                <div className="d-block auth-logo">
                                                                    <img src={logodark} alt="" height="22" className="auth-logo-dark me-start" />
                                                                    <img src={logolight} alt="" height="22" className="auth-logo-light me-start" />
                                                                </div>
                                                            </div>
                                                            <div className="auth-content my-auto">
                                                                <div className="text-center">
                                                                    <h5 className="mb-0">Acesse o sistema</h5>
                                                                </div>
                                                                <Formik
                                                                    initialValues={loginInitialValues()}
                                                                    validationSchema={loginValidationSchema()}
                                                                    onSubmit={(values, { resetForm, setValues, setFieldError, setSubmitting }) => {
                                                                        saveData(values, setSubmitting);
                                                                    }}
                                                                    >
                                                                    {({
                                                                        values,
                                                                        errors,
                                                                        touched,
                                                                        handleChange,
                                                                        handleBlur,
                                                                        setFieldValue,
                                                                        handleSubmit,
                                                                        isSubmitting,
                                                                    }) => (
                                                                        <form className="mt-4 pt-2" onSubmit={handleSubmit}>
                                                                            <div className="form-floating form-floating-custom mb-4">
                                                                                <Field
                                                                                    type="text"
                                                                                    id="email"
                                                                                    name="email"
                                                                                    className="form-control"
                                                                                    placeholder="Insira seu usuário"
                                                                                    value={values.email}
                                                                                />
                                                                                <ErrorMessage
                                                                                    component="p"
                                                                                    className="text-red text-xs"
                                                                                    name="email"
                                                                                />
                                                                                <label htmlFor="email">Usuário</label>
                                                                                <div className="form-floating-icon">
                                                                                    <Icon name="people-outline" fill="#555b6d" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-floating form-floating-custom mb-4 auth-pass-inputgroup">
                                                                                <Field
                                                                                    type={viewPassword ? "text" : "password"}
                                                                                    id="password"
                                                                                    name="password"
                                                                                    className="form-control"
                                                                                    placeholder="Insira sua senha"
                                                                                    value={values.password}
                                                                                />
                                                                                <ErrorMessage
                                                                                    component="p"
                                                                                    className="text-red text-xs"
                                                                                    name="password"
                                                                                />
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-link position-absolute h-100 end-0 top-0"
                                                                                    onClick={() => setViewPassword(!viewPassword)}
                                                                                >
                                                                                    <i className="mdi mdi-eye-outline font-size-18 text-muted"></i>
                                                                                </button>
                                                                                <label htmlFor="password">Senha</label>
                                                                                <div className="form-floating-icon">
                                                                                    <Icon name="lock-outline" fill="#555b6d" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <button
                                                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                                                    type="submit"
                                                                                    disabled={isSubmitting}
                                                                                >
                                                                                    {isSubmitting ? (
                                                                                        <>Acesando</>
                                                                                    ): (
                                                                                        <>Acessar</>
                                                                                    )}
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    )}
                                                                </Formik>
                                                                <div className="mt-4 pt-3 text-center">
                                                                    <p className="text-muted mb-0">
                                                                        Ainda não tem cadastro?
                                                                        <Link to="/register" className="text-primary fw-semibold">
                                                                            {" "}Cadastre-se agora
                                                                        </Link>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4 mb-0 pb-0 text-center">
                                                                <p className="mb-0">
                                                                    © {new Date().getFullYear()} Painel Gomus.
                                                                </p>
                                                                <hr />
                                                                <p className="mb-0">
                                                                    <small>Desenvolvido por <a href="https://ubicode.com.br/" target="_blank" rel="noreferrer">Ubicode SW</a></small>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <AuthSlider />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Login;
