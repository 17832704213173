import PropTypes from 'prop-types';
import React, { useState } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from "reactstrap";
import PasswordData from '../../../pages/User/Components/PasswordData';
import RegistrationData from '../../../pages/User/Components/RegistrationData';

const ModalProfile = ({
    isOpen = false,
    onCloseClick,
    loadData,
    centered
}) => {
    const [tabModal, setTabModal] = useState("Dados cadastrais");

    const entity = {
        identifier: window.localStorage.getItem("profile-identifier"),
        name: window.localStorage.getItem("profile-name"),
        username: window.localStorage.getItem("profile-email"),
    };

    return (
        <>
            <ToastContainer />
            <Modal
                size="xl"
                isOpen={isOpen}
                className="new-customer"
                centered={centered}
            >
                <div className="modal-header">
                    <div className="d-flex justify-content-between" style={{ width: "30%" }}>
                        <h5
                            className={`modal-title" ${tabModal === "Dados cadastrais" ? "text-primary" : ""}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => setTabModal("Dados cadastrais")}
                        >
                            Dados cadastrais
                        </h5>
                        <h5
                            className={`modal-title" ${tabModal === "Edição de senha" ? "text-primary" : ""}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => setTabModal("Edição de senha")}
                        >
                            Edição de senha
                        </h5>
                    </div>
                    <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => {
                            onCloseClick();
                        }}
                    />
                </div>
                <div className="modal-body">
                    {tabModal === "Dados cadastrais" && (
                        <RegistrationData
                            entity={entity}
                            loadData={loadData}
                            onCloseClick={onCloseClick}
                        />
                    )}
                    {tabModal === "Edição de senha" && (
                        <PasswordData
                            entity={entity}
                            loadData={loadData}
                            onCloseClick={onCloseClick}
                        />
                    )}
                </div>
            </Modal>
        </>
    )
}

ModalProfile.propTypes = {
    onCloseClick: PropTypes.func,
    isOpen: PropTypes.any,
    centered: PropTypes.any
};

export default ModalProfile;