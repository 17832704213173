import React from 'react';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

//Simple bar
import SimpleBar from "simplebar-react";

const RecentActivity = () => {
    return (
        <React.Fragment>
            <Col xl={6}>
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h5 className="card-title mb-3">Recent Activity</h5>
                            </div>
                            <div className="flex-shrink-0">
                                <UncontrolledDropdown>
                                    <DropdownToggle className="text-reset" href="#" to="#" type="button" tag="a">
                                        <span className="text-muted">Monthly<i className="mdi mdi-chevron-down ms-1"></i></span>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Yearly</DropdownItem>
                                        <DropdownItem>Monthly</DropdownItem>
                                        <DropdownItem>Weekly</DropdownItem>
                                        <DropdownItem>Today</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </div>

                        <SimpleBar className="px-3 mx-n3" style={{ maxHeight: "456px" }}>
                            <ol className="activity-checkout mb-0 mt-2 ps-3">
                                <li className="checkout-item crypto-activity">
                                    <div className="crypto-date">
                                        <h6 className="text-uppercase font-size-11 mb-0 text-muted">Nov</h6>
                                        <h5 className="font-size-15 mb-0 mt-1">02</h5>
                                    </div>
                                    <div className="avatar checkout-icon p-1">
                                        <div className="avatar-title rounded-circle bg-primary bg-gradient">
                                            <i className="mdi mdi-bitcoin text-white font-size-20"></i>
                                        </div>
                                    </div>
                                    <div className="feed-item-list">
                                        <div className="d-flex">
                                            <div className="flex-grow-1 overflow-hidden me-4">
                                                <h5 className="font-size-14 mb-1 text-truncate">Bought Bitcoin</h5>
                                                <p className="text-truncate text-muted mb-2">Using Visa credit ****9563</p>
                                            </div>
                                            <div className="flex-shrink-0 text-end">
                                                <h5 className="mb-1 font-size-14 text-success">+0.03555 BTC</h5>
                                                <p className="text-muted mb-0">+2,322 USD</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li className="checkout-item crypto-activity">
                                    <div className="crypto-date">
                                        <h6 className="text-uppercase font-size-11 mb-0 text-muted">Nov</h6>
                                        <h5 className="font-size-15 mb-0 mt-1">04</h5>
                                    </div>
                                    <div className="avatar checkout-icon p-1">
                                        <div className="avatar-title rounded-circle bg-danger bg-gradient">
                                            <i className="mdi mdi-ethereum text-white font-size-20"></i>
                                        </div>
                                    </div>
                                    <div className="feed-item-list">
                                        <div className="d-flex">
                                            <div className="flex-grow-1 overflow-hidden me-4">
                                                <h5 className="font-size-14 mb-1 text-truncate">Sent Ethereum</h5>
                                                <p className="text-truncate text-muted mb-2">0xb77ad0099e21da49e0fa2e431</p>
                                            </div>
                                            <div className="flex-shrink-0 text-end">
                                                <h5 className="mb-1 font-size-14 text-danger">-0.56525 ETH</h5>
                                                <p className="text-muted mb-0">-500 USD</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li className="checkout-item crypto-activity">
                                    <div className="crypto-date">
                                        <h6 className="text-uppercase font-size-11 mb-0 text-muted">Nov</h6>
                                        <h5 className="font-size-15 mb-0 mt-1">08</h5>
                                    </div>
                                    <div className="avatar checkout-icon p-1">
                                        <div className="avatar-title rounded-circle bg-primary bg-gradient">
                                            <i className="mdi mdi-litecoin text-white font-size-20"></i>
                                        </div>
                                    </div>
                                    <div className="feed-item-list">
                                        <div className="d-flex">
                                            <div className="flex-grow-1 overflow-hidden me-4">
                                                <h5 className="font-size-14 mb-1 text-truncate">Bought Litecoin</h5>
                                                <p className="text-truncate text-muted mb-2">Using Visa credit ****5256</p>
                                            </div>
                                            <div className="flex-shrink-0 text-end">
                                                <h5 className="mb-1 font-size-14 text-success">+0.65425 LTC</h5>
                                                <p className="text-muted mb-0">+2,000 USD</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li className="checkout-item crypto-activity">
                                    <div className="crypto-date">
                                        <h6 className="text-uppercase font-size-11 mb-0 text-muted">Nov</h6>
                                        <h5 className="font-size-15 mb-0 mt-1">20</h5>
                                    </div>
                                    <div className="avatar checkout-icon p-1">
                                        <div className="avatar-title rounded-circle bg-primary bg-gradient">
                                            <i className="mdi mdi-bitcoin text-white font-size-20"></i>
                                        </div>
                                    </div>
                                    <div className="feed-item-list">
                                        <div className="d-flex">
                                            <div className="flex-grow-1 overflow-hidden me-4">
                                                <h5 className="font-size-14 mb-1 text-truncate">Sent Bitcoin</h5>
                                                <p className="text-truncate text-muted mb-2">1d4fca87fa49e05d6fa2e431</p>
                                            </div>
                                            <div className="flex-shrink-0 text-end">
                                                <h5 className="mb-1 font-size-14 text-success">+5.5265 BTC</h5>
                                                <p className="text-muted mb-0">+3,562 USC</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li className="checkout-item crypto-activity">
                                    <div className="crypto-date">
                                        <h6 className="text-uppercase font-size-11 mb-0 text-muted">Nov</h6>
                                        <h5 className="font-size-15 mb-0 mt-1">13</h5>
                                    </div>
                                    <div className="avatar checkout-icon p-1">
                                        <div className="avatar-title rounded-circle bg-danger bg-gradient">
                                            <i className="mdi mdi-ethereum text-white font-size-20"></i>
                                        </div>
                                    </div>
                                    <div className="feed-item-list">
                                        <div className="d-flex">
                                            <div className="flex-grow-1 overflow-hidden me-4">
                                                <h5 className="font-size-14 mb-1 text-truncate">Sent Ethereum</h5>
                                                <p className="text-truncate text-muted mb-2">7ad0099e21d4fcae05d205e53</p>
                                            </div>
                                            <div className="flex-shrink-0 text-end">
                                                <h5 className="mb-1 font-size-14 text-danger">-1.5654 ETH</h5>
                                                <p className="text-muted mb-0">- 1,562 USD</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li className="checkout-item crypto-activity">
                                    <div className="crypto-date">
                                        <h6 className="text-uppercase font-size-11 mb-0 text-muted">Nov</h6>
                                        <h5 className="font-size-15 mb-0 mt-1">24</h5>
                                    </div>
                                    <div className="avatar checkout-icon p-1">
                                        <div className="avatar-title rounded-circle bg-primary bg-gradient">
                                            <i className="mdi mdi-litecoin text-white font-size-20"></i>
                                        </div>
                                    </div>
                                    <div className="feed-item-list">
                                        <div className="d-flex">
                                            <div className="flex-grow-1 overflow-hidden me-4">
                                                <h5 className="font-size-14 mb-1 text-truncate">Sent Litecoin</h5>
                                                <p className="text-truncate text-muted mb-2">Using Visa credit ****6965</p>
                                            </div>
                                            <div className="flex-shrink-0 text-end">
                                                <h5 className="mb-1 font-size-14 text-success">2.5566 LTC</h5>
                                                <p className="text-muted mb-0">+4,652 USD</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li className="checkout-item crypto-activity">
                                    <div className="crypto-date">
                                        <h6 className="text-uppercase font-size-11 mb-0 text-muted">Nov</h6>
                                        <h5 className="font-size-15 mb-0 mt-1">30</h5>
                                    </div>
                                    <div className="avatar checkout-icon p-1">
                                        <div className="avatar-title rounded-circle bg-primary bg-gradient">
                                            <i className="mdi mdi-bitcoin text-white font-size-20"></i>
                                        </div>
                                    </div>
                                    <div className="feed-item-list">
                                        <div className="d-flex">
                                            <div className="flex-grow-1 overflow-hidden me-4">
                                                <h5 className="font-size-14 mb-1 text-truncate">Bought Bitcoin</h5>
                                                <p className="text-truncate text-muted mb-0">99e21d4fca87fa205e53f38bf0</p>
                                            </div>
                                            <div className="flex-shrink-0 text-end">
                                                <h5 className="mb-1 font-size-14 text-success">+0.5 BTC</h5>
                                                <p className="text-muted mb-0">$178.53</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ol>

                        </SimpleBar>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default RecentActivity;