import React from 'react';
import { Link } from 'react-router-dom';
import ReactApexChart from "react-apexcharts";

const UsageStorage = () => {
    const series = [76.20];
    var options = {
        chart: {
            height: 270,
            type: 'radialBar',
            offsetY: -20
        },
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 135,
                dataLabels: {
                    name: {
                        fontSize: '16px',
                        color: undefined,
                        offsetY: 120
                    },
                    value: {
                        offsetY: 76,
                        fontSize: '20px',
                        color: undefined,
                        formatter: function (val) {
                            return val + "%";
                        }
                    }
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                shadeIntensity: 0.15,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 65, 91]
            },
        },
        stroke: {
            dashArray: 4
        },
        labels: ['64 GB used'],
        colors: ["#3b76e1"],
    };
    return (
        <React.Fragment>
            <div className="col-xl-3">
                <div className="card">
                    <div className="card-body">
                        <div className="">
                            <h5 className="font-size-16">Usage Storage</h5>
                            <div id="stroked_radialbar" dir="ltr">
                                <ReactApexChart
                                    options={options}
                                    series={series}
                                    type="radialBar"
                                    height="270"
                                    className="apex-charts"
                                />
                            </div>
                            <div>
                                <h5 className="font-size-16 mb-3">Recent Files</h5>
                                <div className="card border shadow-none mb-2">
                                    <Link to="#" className="text-body">
                                        <div className="p-2">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar align-self-center me-3">
                                                    <div className="avatar-title rounded bg-soft-success text-success font-size-24">
                                                        <i className="mdi mdi-image"></i>
                                                    </div>
                                                </div>

                                                <div className="overflow-hidden me-auto">
                                                    <h5 className="font-size-15 text-truncate mb-1">Images</h5>
                                                    <p className="text-muted text-truncate mb-0">176 Files</p>
                                                </div>

                                                <div className="ms-2">
                                                    <p className="text-muted">6 GB</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="card border shadow-none mb-2">
                                    <Link to="#" className="text-body">
                                        <div className="p-2">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar align-self-center me-3">
                                                    <div className="avatar-title rounded bg-soft-danger text-danger font-size-24">
                                                        <i className="mdi mdi-play-circle-outline"></i>
                                                    </div>
                                                </div>

                                                <div className="overflow-hidden me-auto">
                                                    <h5 className="font-size-15 text-truncate mb-1">Video</h5>
                                                    <p className="text-muted text-truncate mb-0">45 Files</p>
                                                </div>

                                                <div className="ms-2">
                                                    <p className="text-muted">4.1 GB</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="card border shadow-none mb-2">
                                    <Link to="#" className="text-body">
                                        <div className="p-2">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar align-self-center me-3">
                                                    <div className="avatar-title rounded bg-soft-info text-info font-size-24">
                                                        <i className="mdi mdi-music"></i>
                                                    </div>
                                                </div>

                                                <div className="overflow-hidden me-auto">
                                                    <h5 className="font-size-15 text-truncate mb-1">Music</h5>
                                                    <p className="text-muted text-truncate mb-0">21 Files</p>
                                                </div>

                                                <div className="ms-2">
                                                    <p className="text-muted">3.2 GB</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="card border shadow-none mb-2">
                                    <Link to="#" className="text-body">
                                        <div className="p-2">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar align-self-center me-3">
                                                    <div className="avatar-title rounded bg-soft-primary text-primary font-size-24">
                                                        <i className="mdi mdi-file-document"></i>
                                                    </div>
                                                </div>

                                                <div className="overflow-hidden me-auto">
                                                    <h5 className="font-size-15 text-truncate mb-1">Document</h5>
                                                    <p className="text-muted text-truncate mb-0">21 Files</p>
                                                </div>

                                                <div className="ms-2">
                                                    <p className="text-muted">2 GB</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="card border shadow-none">
                                    <Link to="#" className="text-body">
                                        <div className="p-2">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar align-self-center me-3">
                                                    <div className="avatar-title rounded bg-soft-warning text-warning font-size-24">
                                                        <i className="mdi mdi-folder"></i>
                                                    </div>
                                                </div>

                                                <div className="overflow-hidden me-auto">
                                                    <h5 className="font-size-15 text-truncate mb-1">Others</h5>
                                                    <p className="text-muted text-truncate mb-0">20 Files</p>
                                                </div>

                                                <div className="ms-2">
                                                    <p className="text-muted">1.4 GB</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4 pt-3">
                            <div className="alert alert-success alert-dismissible fade show px-3 mb-0" role="alert">
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                <div className="mb-3">
                                    <i className="bx bxs-folder-open h1 text-success"></i>
                                </div>

                                <div>
                                    <h5 className="text-success">Upgrade Features</h5>
                                    <p>Cum sociis natoque penatibus et</p>
                                    <div className="text-center">
                                        <button type="button" className="btn btn-link text-decoration-none text-success">Upgrade <i className="mdi mdi-arrow-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
};

export default UsageStorage;