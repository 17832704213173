import React,{useState} from 'react';
import { Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { OverviewChart } from './DashboardSassChart';

const AnalyticsOverview = (props) => {
    const [overview, setOverview] = useState(false);

    return (
        <React.Fragment>
            <Col xl={8}>
                <Card>
                    <CardBody className="pb-0">
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h5 className="card-title mb-4">Analytics Overview</h5>
                            </div>
                            <div className="flex-shrink-0">
                                <Dropdown isOpen={overview} toggle={() => setOverview(!overview)}>
                                    <DropdownToggle className="text-reset" to="#" tag="a" type="button">
                                        <span className="fw-semibold">Sort By:</span> <span className="text-muted">Yearly<i className="mdi mdi-chevron-down ms-1"></i></span>
                                    </DropdownToggle>

                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Yearly</DropdownItem>
                                        <DropdownItem>Monthly</DropdownItem>
                                        <DropdownItem>Weekly</DropdownItem>
                                        <DropdownItem>Today</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>


                        <div>
                            <Row>
                                <Col lg={3} sm={6}>
                                    <div className="p-3 bg-soft-light border border-light rounded mb-lg-0 mb-3">
                                        <div className="d-flex align-items-start">
                                            <div className="flex-grow-1">
                                                <p className="text-muted text-truncate mb-1">Orders</p>
                                                <h4 className="text-truncate mb-0">5,643</h4>
                                            </div>
                                            <div className="flex-shrink-0 align-self-end">
                                                <div className="text-muted"><i data-eva="diagonal-arrow-right-up" data-eva-width="18" data-eva-height="18" className="align-text-bottom fill-success"></i> 1.02%</div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={3} sm={6}>
                                    <div className="p-3 bg-soft-light border border-light rounded mb-lg-0 mb-3">
                                        <div className="d-flex align-items-start">
                                            <div className="flex-grow-1">
                                                <p className="text-muted text-truncate mb-1">Sales</p>
                                                <h4 className="text-truncate mb-0">16,273</h4>
                                            </div>
                                            <div className="flex-shrink-0 align-self-end">
                                                <div className="text-muted"><i data-eva="diagonal-arrow-left-down" data-eva-width="18" data-eva-height="18" className="align-text-bottom fill-danger"></i> 0.2%</div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={3} sm={6}>
                                    <div className="p-3 bg-soft-light border border-light rounded mb-lg-0 mb-3">
                                        <div className="d-flex align-items-start">
                                            <div className="flex-grow-1">
                                                <p className="text-muted text-truncate mb-1">Order Value</p>
                                                <h4 className="text-truncate mb-0">12.03 %</h4>
                                            </div>
                                            <div className="flex-shrink-0 align-self-end">
                                                <div className="text-muted"><i data-eva="diagonal-arrow-left-down" data-eva-width="18" data-eva-height="18" className="align-text-bottom fill-danger"></i> 0.1%</div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={3} sm={6}>
                                    <div className="p-3 bg-soft-light border border-light rounded mb-lg-0 mb-3">
                                        <div className="d-flex align-items-start">
                                            <div className="flex-grow-1">
                                                <p className="text-muted text-truncate mb-1">Customers</p>
                                                <h4 className="text-truncate mb-0">21,456</h4>
                                            </div>
                                            <div className="flex-shrink-0 align-self-end">
                                                <div className="text-muted"><i data-eva="diagonal-arrow-right-up" data-eva-width="18" data-eva-height="18" className="align-text-bottom fill-success"></i> 1.15%</div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className="mx-n2">
                            <div id="chart-area">
                                <OverviewChart />
                            </div>
                        </div>
                    </CardBody>

                </Card>

            </Col>
        </React.Fragment>
    );
};

export default AnalyticsOverview;