import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';

//Simple bar
import SimpleBar from "simplebar-react";

const WalletBalance = () => {
    return (
        <React.Fragment>
            BUILD ERROR
        </React.Fragment>
    );
};

export default WalletBalance;
