const yup = require("yup");

export const passwordValidationSchema = () => {
  return (
    yup.object().shape({
      password: yup
        .string()
        .required("Campo obrigatório")
        .min(6, "A senha deve conter no mínimo 6 caracteres")
      ,
      confirmPassword: yup
        .string()
        .required("Campo obrigatório")
        .oneOf([yup.ref('password'), null], 'As senhas digitadas não coincidem')
      ,
    })
  );
}
