import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, UncontrolledButtonDropdown } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";


const UiDropdowns = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Dropdowns",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);
    return (
        <React.Fragment>
            <MetaTags>
                <title>Dropdowns | Painel Gomus</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Single button dropdowns</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Any single <code
                                        className="highlighter-rouge">.btn</code> can be turned into a dropdown
                                        toggle with some markup changes. Here's how you can put them to work
                                        with either <code className="highlighter-rouge">&lt;button&gt;</code>
                                        elements:</p>

                                    <Row>
                                        <Col sm={6}>
                                            <UncontrolledDropdown>
                                                <DropdownToggle tag="button" className="btn btn-secondary" id="dropdownMenuButton">
                                                    Dropdown button <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </Col>

                                        <Col sm={6}>
                                            <UncontrolledDropdown className="dropdown mt-4 mt-sm-0">
                                                <DropdownToggle tag="a" className="btn btn-secondary">
                                                    Dropdown link <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Variant</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">The best part is you can do this with any button variant, too:</p>

                                    <div className="d-flex gap-2 flex-wrap">

                                        <div className="btn-group">
                                            <UncontrolledDropdown className="btn-group">
                                                <DropdownToggle tag="button" className="btn btn-primary">
                                                    Primary <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem>Separated link</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>

                                        <div className="btn-group">
                                            <UncontrolledDropdown className="btn-group">
                                                <DropdownToggle tag="button" className="btn btn-secondary">
                                                    Secondary <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem>Separated link</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>

                                        <div className="btn-group">
                                            <UncontrolledDropdown className="btn-group">
                                                <DropdownToggle tag="button" className="btn btn-success">
                                                    Success <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem>Separated link</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                        <div className="btn-group">
                                            <UncontrolledDropdown className="btn-group">
                                                <DropdownToggle tag="button" className="btn btn-info">
                                                    Info <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem>Separated link</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                        <div className="btn-group">
                                            <UncontrolledDropdown className="btn-group">
                                                <DropdownToggle tag="button" className="btn btn-warning">
                                                    Warning <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem>Separated link</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                        <div className="btn-group">
                                            <UncontrolledDropdown className="btn-group">
                                                <DropdownToggle tag="button" className="btn btn-danger">
                                                    Danger <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem>Separated link</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>

                                </CardBody>
                            </Card>

                        </Col>
                    </Row>


                    <Row>
                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Split button dropdowns</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">The best part is you can do this with any button variant, too:</p>

                                    <div className="d-flex gap-2 flex-wrap">
                                        <UncontrolledButtonDropdown className="btn-group">
                                            <button type="button" className="btn btn-primary">Primary</button>
                                            <DropdownToggle tag="a" className="btn btn-primary dropdown-toggle-split">
                                                <i className="mdi mdi-chevron-down"></i>
                                            </DropdownToggle>
                                            <DropdownMenu >
                                                <DropdownItem>Action</DropdownItem>
                                                <DropdownItem>Another action</DropdownItem>
                                                <DropdownItem>Something else here</DropdownItem>
                                                <DropdownItem divider />
                                                <DropdownItem>Separated link</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>

                                        <UncontrolledButtonDropdown className="btn-group">
                                            <button type="button" className="btn btn-secondary">Secondary</button>
                                            <DropdownToggle tag="button" className="btn btn-secondary dropdown-toggle-split">
                                                <i className="mdi mdi-chevron-down"></i>
                                            </DropdownToggle>
                                            <DropdownMenu >
                                                <DropdownItem>Action</DropdownItem>
                                                <DropdownItem>Another action</DropdownItem>
                                                <DropdownItem>Something else here</DropdownItem>
                                                <DropdownItem divider />
                                                <DropdownItem>Separated link</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>

                                        <UncontrolledButtonDropdown className="btn-group">
                                            <button type="button" className="btn btn-success">Success</button>
                                            <DropdownToggle tag="button" className="btn btn-success dropdown-toggle-split">
                                                <i className="mdi mdi-chevron-down"></i>
                                            </DropdownToggle>
                                            <DropdownMenu >
                                                <DropdownItem>Action</DropdownItem>
                                                <DropdownItem>Another action</DropdownItem>
                                                <DropdownItem>Something else here</DropdownItem>
                                                <DropdownItem divider />
                                                <DropdownItem>Separated link</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>

                                        <UncontrolledButtonDropdown className="btn-group">
                                            <button type="button" className="btn btn-info">Info</button>
                                            <DropdownToggle tag="button" className="btn btn-info dropdown-toggle-split">
                                                <i className="mdi mdi-chevron-down"></i>
                                            </DropdownToggle>
                                            <DropdownMenu >
                                                <DropdownItem>Action</DropdownItem>
                                                <DropdownItem>Another action</DropdownItem>
                                                <DropdownItem>Something else here</DropdownItem>
                                                <DropdownItem divider />
                                                <DropdownItem>Separated link</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>

                                        <UncontrolledButtonDropdown className="btn-group">
                                            <button type="button" className="btn btn-warning">Warning</button>
                                            <DropdownToggle tag="button" className="btn btn-warning dropdown-toggle-split">
                                                <i className="mdi mdi-chevron-down"></i>
                                            </DropdownToggle>
                                            <DropdownMenu >
                                                <DropdownItem>Action</DropdownItem>
                                                <DropdownItem>Another action</DropdownItem>
                                                <DropdownItem>Something else here</DropdownItem>
                                                <DropdownItem divider />
                                                <DropdownItem>Separated link</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>

                                        <UncontrolledButtonDropdown className="btn-group">
                                            <button type="button" className="btn btn-danger">Danger</button>
                                            <DropdownToggle tag="button" className="btn btn-danger dropdown-toggle-split">
                                                <i className="mdi mdi-chevron-down"></i>
                                            </DropdownToggle>
                                            <DropdownMenu >
                                                <DropdownItem>Action</DropdownItem>
                                                <DropdownItem>Another action</DropdownItem>
                                                <DropdownItem>Something else here</DropdownItem>
                                                <DropdownItem divider />
                                                <DropdownItem>Separated link</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Sizing</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc mb-3">Button dropdowns work with buttons of
                                        all sizes, including default and split dropdown buttons.</p>

                                    <div className="d-flex gap-2 flex-wrap align-items-center">


                                        <div className="btn-group">

                                            <UncontrolledDropdown className="btn-group">
                                                <DropdownToggle tag="button" className="btn btn-primary btn-lg">
                                                    Large button <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu >
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem>Separated link</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                        <div className="btn-group">

                                            <UncontrolledButtonDropdown className="btn-group">
                                                <button type="button" className="btn btn-secondary btn-lg">Large button</button>
                                                <DropdownToggle tag="button" className="btn btn-secondary btn-lg  dropdown-toggle-split">
                                                    <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu >
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem>Separated link</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>

                                        <div className="btn-group">

                                            <UncontrolledDropdown className="btn-group ">
                                                <DropdownToggle tag="button" className="btn btn-info btn-sm">
                                                    Small button <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem>Separated link</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>

                                        </div>
                                        <div className="btn-group">

                                            <UncontrolledButtonDropdown className="btn-group btn-sm">
                                                <button type="button" className="btn btn-secondary btn-sm">Small button</button>
                                                <DropdownToggle tag="button" className="btn btn-secondary btn-sm dropdown-toggle-split">
                                                    <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu >
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem>Separated link</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>

                                        </div>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Row>
                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Dropup variation</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Trigger dropdown menus above elements
                                        by adding <code className="highlighter-rouge">.dropup</code> to the parent
                                        element.</p>

                                    <div className="d-flex gap-2 flex-wrap">

                                        <div className="btn-group dropup">
                                            <UncontrolledDropdown direction="up" className="btn-group dropup">
                                                <DropdownToggle tag="button" className="btn btn-secondary">
                                                    Dropup <i className="mdi mdi-chevron-up"></i>
                                                </DropdownToggle>
                                                <DropdownMenu >
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem>Separated link</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>

                                        <div className="btn-group dropup">
                                            <UncontrolledButtonDropdown direction="up" className="btn-group dropup">
                                                <button type="button" className="btn btn-secondary">Split dropup</button>
                                                <DropdownToggle tag="button" className="btn btn-secondary dropdown-toggle-split">
                                                    <i className="mdi mdi-chevron-up"></i>
                                                </DropdownToggle>
                                                <DropdownMenu >
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem>Separated link</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Menu alignment</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Add <code className="highlighter-rouge">.dropdown-menu-end</code>
                                        to a <code className="highlighter-rouge">.dropdown-menu</code> to right
                                        align the dropdown menu.</p>

                                    <div className="btn-group">
                                        <UncontrolledDropdown className="btn-group">
                                            <DropdownToggle tag="button" className="btn btn-secondary">
                                                Menu is right-aligned <i className="mdi mdi-chevron-down"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end">
                                                <DropdownItem>Action</DropdownItem>
                                                <DropdownItem>Another action</DropdownItem>
                                                <DropdownItem>Something else here</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Row>
                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Dropright variation</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Trigger dropdown menus at the right of the elements by adding <code>.dropend</code> to the parent element.
                                    </p>

                                    <div className="d-flex gap-2 flex-wrap">

                                        <div className="btn-group dropend">
                                            <UncontrolledDropdown direction="end" className="btn-group dropend">
                                                <DropdownToggle tag="button" className="btn btn-info waves-light">
                                                    Dropright <i className="mdi mdi-chevron-right"></i>
                                                </DropdownToggle>
                                                <DropdownMenu >
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>

                                        <div className="btn-group dropend">
                                            <UncontrolledButtonDropdown className="btn-group" direction="end">
                                                <button type="button" className="btn btn-info waves-light">Split dropend</button>
                                                <DropdownToggle tag="button" className="btn btn-info dropdown-toggle-split waves-light">
                                                    <i className="mdi mdi-chevron-right"></i>
                                                </DropdownToggle>
                                                <DropdownMenu >
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Dropleft variation</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Trigger dropdown menus at the right of the elements by adding <code>.dropstart</code> to the parent element.
                                    </p>

                                    <div className="d-flex gap-2 flex-wrap">


                                        <div className="btn-group dropstart">
                                            <UncontrolledDropdown direction="start" className="btn-group dropstart">
                                                <DropdownToggle tag="button" className="btn btn-info waves-light" aria-expanded="false">
                                                    <i className="mdi mdi-chevron-left"></i> Dropleft
                                                </DropdownToggle>
                                                <DropdownMenu >
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>

                                        <div className="btn-group">
                                            <div className="btn-group dropstart">
                                                <UncontrolledButtonDropdown className="btn-group" direction="start">
                                                    <DropdownToggle tag="button" className="btn btn-info dropdown-toggle-split waves-light">
                                                        <i className="mdi mdi-chevron-left"></i>
                                                    </DropdownToggle>
                                                    <button type="button" className="btn btn-info waves-light">Split dropstart</button>
                                                    <DropdownMenu>
                                                        <DropdownItem>Action</DropdownItem>
                                                        <DropdownItem>Another action</DropdownItem>
                                                        <DropdownItem>Something else here</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>

                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UiDropdowns;
