import React, { useState } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';

//Simple bar
import SimpleBar from "simplebar-react";

//import images
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import img1 from "../../assets/images/small/img-1.jpg";
import img2 from "../../assets/images/small/img-2.jpg";
import img5 from "../../assets/images/small/img-5.jpg";

const Activities = () => {
    const [activities, setActivities] = useState(false);

    return (
        <React.Fragment>
            <Col xl={4}>
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h5 className="card-title mb-4">Activities</h5>
                            </div>
                            <div className="flex-shrink-0">
                                <Dropdown isOpen={activities} toggle={() => setActivities(!activities)}>
                                    <DropdownToggle className="text-reset" to="#" tag="a" type="button">
                                        <span className="text-muted">Monthly<i className="mdi mdi-chevron-down ms-1"></i></span>
                                    </DropdownToggle>

                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Yearly</DropdownItem>
                                        <DropdownItem>Monthly</DropdownItem>
                                        <DropdownItem>Weekly</DropdownItem>
                                        <DropdownItem>Today</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>

                        <SimpleBar className="list-unstyled mb-0" style={{ maxHeight: "400px" }}>
                            <li className="py-2">
                                <div className="d-flex align-items-start">
                                    <div className="me-4">
                                        <div className="avatar-md h-auto p-1 py-2 bg-light rounded">
                                            <div className="text-center">
                                                <h5 className="mb-0">12</h5>
                                                <div>Sep</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <h5 className="font-size-15">Darlene Thibodeau</h5>
                                        <p className="text-muted mb-2">Everyone realizes why a new common velit ut language would be desirable... <Link to="#">Read more</Link></p>

                                    </div>
                                </div>
                            </li>

                            <li className="py-2">
                                <div className="d-flex align-items-start">
                                    <div className="me-4">
                                        <div className="avatar-md h-auto p-1 py-2 bg-light rounded">
                                            <div className="text-center">
                                                <h5 className="mb-0">11</h5>
                                                <div>Sep</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <h5 className="font-size-15">Create New project Bundling Product</h5>

                                        <div className="avatar-group mb-2">
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar4} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar5} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <div className="avatar-sm">
                                                        <span className="avatar-title rounded-circle bg-success text-white font-size-16">
                                                            A
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="py-2">
                                <div className="d-flex align-items-start">
                                    <div className="me-4">
                                        <div className="avatar-md h-auto p-1 py-2 bg-light rounded">
                                            <div className="text-center">
                                                <h5 className="mb-0">10</h5>
                                                <div>Sep</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <h5 className="font-size-15">Aenean dictum placerat vestibulum</h5>
                                        <p className="text-muted mb-2">Zack Wetass, finibus, venenatis lacinia vel elementum
                                            Commented <span className="text-primary"> Developer Moreno</span></p>
                                    </div>
                                </div>
                            </li>

                            <li className="py-2">
                                <div className="d-flex align-items-start">
                                    <div className="me-4">
                                        <div className="avatar-md h-auto p-1 py-2 bg-light rounded">
                                            <div className="text-center">
                                                <h5 className="mb-0">9</h5>
                                                <div>Sep</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <h5 className="font-size-15">Adding a new event with attachments</h5>
                                        <div className="d-flex flex-wrap align-items-start event-img mt-3 gap-2">
                                            <img src={img1} alt="" className="img-fluid rounded" width="80" />
                                            <img src={img2} alt="" className="img-fluid rounded" width="80" />
                                            <img src={img5} alt="" className="img-fluid rounded" width="80" />
                                        </div>

                                    </div>
                                </div>
                            </li>
                            <li className="pt-2">
                                <Link to="#" className="btn btn-link w-100 shadow-none"><i className="mdi mdi-loading mdi-spin me-2"></i> Load More</Link>
                            </li>
                        </SimpleBar>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default Activities;